<template>
  <div class="image-switch--wrap">
    <img
      :src="image"
      class="img-responsive"
      :class="{
        'height-aware-image': heightAware,
      }"
    >

    <slot />
  </div>
</template>

<script>
export default {
  name: 'image-switch',

  props: {
    default: {
      type: String,
      default: null,
    },
    primary: {
      type: String,
      default: null,
    },
    secondary: {
      type: String,
      default: null,
    },
    heightAware: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    image () {
      return this.primary || this.secondary || this.default
    },
  },
}
</script>
