const StorageName = 'MaterialShopCart'

/**
 * Persists the given state in the localStorage.
 *
 * @param {object} state State to store.
 * @returns {void}
 */
function writeToStorage (state) {
  localStorage.setItem(
    StorageName,
    JSON.stringify({
      entries: state.entries,
      downloads: state.downloads,
    })
  )
}

function readFromStorage () {
  const raw = localStorage.getItem(StorageName)
  if (!raw) {
    return { entries: [], downloads: [] }
  }
  return JSON.parse(raw)
}

export default {
  namespaced: true,
  state: {
    entries: [],
    downloads: [],
    isVisible: false,
    checkoutVisible: false,
    prospectLogo: '',
  },

  getters: {
    itemCount: state => state.entries.reduce((count, { amount }) => count + amount, 0),
    byType: state => type => state.entries.filter(e => e.type === type),
    byArticleNumber: state => articleNumber => state.entries.find(entry => entry.item.articleNumber === articleNumber),
    countArticle: (state, getters) => articleNumber => getters.byArticleNumber(articleNumber)?.amount || 0,
    totalPrice: state => state.entries.reduce((total, e) => total + e.item.price * e.amount, 0),
  },

  mutations: {
    /**
     * Uses the items stored in the localStorage for the cart.
     *
     * @param {object} state Current state
     * @returns {void}
     */
    init (state) {
      Object.assign(state, readFromStorage())
    },

    /**
     * Adds a new entry to the cart.
     *
     * @param {object} state Current state
     * @param {object} newEntry Entry to add
     * @returns {void}
     */
    add (state, newEntry) {
      const current = state.entries.find(entry => entry.item.articleNumber === newEntry.item.articleNumber)

      if (!current) {
        state.entries = [...state.entries, newEntry]
      } else if (!newEntry.unique) {
        current.amount += newEntry.amount
      }

      if (current && newEntry.unique) {
        throw new Error('cannot add unique item')
      }

      writeToStorage(state)
    },

    /**
     * Removes an entry from the cart.
     *
     * @param {object} state Current state
     * @param {object} entryIndex Index of the entry to remove
     * @returns {void}
     */
    remove (state, entryIndex) {
      state.entries.splice(entryIndex, 1)
      writeToStorage(state)
    },

    /**
     * Removes all entries (not downloads) from the cart.
     *
     * @param state
     */
    clear (state) {
      state.entries = []
      writeToStorage(state)
    },

    /**
     * Removes all downloads.
     *
     * @param state
     * @returns {void}
     */
    clearDownloads (state) {
      state.downloads = []
      writeToStorage(state)
    },

    /**
     * Adds a new download to the cart.
     *
     * @param {object} state Current state
     * @param {object} newDownload Download to add
     * @returns {void}
     */
    addDownload (state, newDownload) {
      if (!state.downloads.find(download => download._id === newDownload._id)) {
        state.downloads = [...state.downloads, newDownload]
        writeToStorage(state)
      } else {
        this._vm.$swal({
          icon: 'error',
          title: this.i18n.t('MaterialShop.Notifications.downloadAlreadyAdded'),
        })
      }
    },

    /**
     * Removes a download from the cart.
     *
     * @param {object} state Current state
     * @param {object} download download to be remove
     * @returns {void}
     */
    removeDownload (state, download) {
      const index = state.downloads.findIndex(d => d._id === download._id || d === download)
      state.downloads.splice(index, 1)
      writeToStorage(state)
    },

    /**
     * Toggles the visibility of the cart/sidebar.
     *
     * @param {object} state Current state
     * @returns {void}
     */
    toggleVisibility (state) {
      state.isVisible = !state.isVisible
    },

    /**
     * Sets the visible state of the MaterialShop checkout.
     *
     * @param {object}  state
     * @param {boolean} isVisible
     */
    setCheckoutVisible (state, isVisible = true) {
      state.checkoutVisible = isVisible
    },

    /**
     * Sets the logo to use for prospects.
     *
     * @param {object} state Current state
     * @param {string} logo Logo to use
     * @returns {void}
     */
    setProspectLogo (state, logo) {
      state.prospectLogo = logo
    },
  },
}
