<template>
  <default-page-layout
    :heading="$t('etrack.glider')"
    :loading="isLoading"
    class="e-track-glider-choice--wrap"
  >
    <div class="row d-flex mb-10">
      <div class="col-sm-6 d-flex flex-column align-center">
        <div class="mb-10">
          <img :src="gliderImage" class="img-responsive">
        </div>
        <div>
          <img :src="hookImage" class="img-responsive">
        </div>
      </div>
      <div class="col-sm-6 d-flex flex-column align-center justify-center">
        <e-track-card
          :title="`${$t('etrack.glider')}, ${$t('etrack.hooks')}`"
          :loading="isLoading"
        >
          <ul>
            <li
              v-for="(color, i) in colors"
              :key="`${i}_${color}`"
              class="has--choice-indicator left-indicator"
              :class="{
                'is--chosen': color === articleConfig.RingColorSelected
              }"
              @mouseenter="tempPreview = color"
              @mouseleave="tempPreview = null"
              @click="setColor(color)"
            >
              {{ color }}
            </li>
          </ul>
        </e-track-card>
      </div>
    </div>

    <template #footer>
      <e-track-btn
        to="ETrackConfectionForm"
        :text="$t('etrack.prev')"
        :loading="isLoading"
      />

      <e-track-btn
        to="ETrackDecorWidth"
        :text="$t('etrack.next')"
        :loading="isLoading"
        class="ml-auto"
      />
    </template>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'

const gliderImageBasePath = '/images/Elektroschiene/Gliders'

export default {
  name: 'glider-choice',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
  },

  data () {
    return {
      isLoading: false,
      tempPreview: null,
    }
  },

  computed: {
    articleConfig () {
      return this.$store.state.etrack.articleConfig
    },

    /**
     * The api stores only a combined color-info for the current choice
     * ("[ID] ([Name])") so we have to extract the ID to get related preview-
     * images.
     *
     * @returns {string}
     */
    chosenColorId () {
      const selectedColor = this.articleConfig?.RingColorSelected || ''
      return selectedColor.split(' (')[0]
    },

    /**
     * Available colors to choose from.
     *
     * @returns {array}
     */
    colors () {
      return this.articleConfig?.RingColorList || []
    },

    /**
     * Current configuration based on the choices of the user.
     *
     * @returns {object}
     */
    configuration () {
      return this.$store.state.etrack.configuration
    },

    gliderImage () {
      const article = this.configuration.confectionForm.id === 'W' ? '40085' : '40063'
      const color = this.getColorId(this.tempPreview || this.chosenColorId)
      return `${gliderImageBasePath}/${article}-${color}.jpg`
    },

    hookImage () {
      const article = this.configuration.confectionForm.id === 'W' ? '40080' : '40060'
      const color = this.getColorId(this.tempPreview || this.chosenColorId)
      return `${gliderImageBasePath}/${article}-${color}.jpg`
    }
  },

  mounted () {
    this.storeImage()
  },

  methods: {
    /**
     * The api stores only combined color ("[ID] ([Name])") so we have to
     * extract the ID for further handling.
     *
     * @param {string} color
     * @returns {string}
     */
    getColorId (color) {
      return color.split(' (')[0]
    },

    async setColor (color) {
      this.isLoading = true
      await this.$store.dispatch('etrack/postArticleConfig', { Key: 'RingColorSelected', Value: color })
      this.storeImage()
      this.isLoading = false
    },

    /**
     * The images aren't available within the article-configuration. So we have
     * to store those to be able to show those within the summary-step.
     *
     * @returns {undefined}
     */
    storeImage () {
      this.$store.commit('etrack/setConfiguration', {
        ...this.configuration,
        gliderImage: this.gliderImage,
        hookImage: this.hookImage,
      })
    }
  },
}
</script>

<style lang="scss">
  .e-track-glider-choice--wrap {
    img {
      max-height: 180px;
    }

    ul {
      list-style: none;

      li {
        padding: 10px 0;
      }
    }
  }
</style>
