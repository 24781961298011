<template>
  <div
    v-if="loading"
    role="progressbar"
    class="progress-linear--wrap"
  >
    <div class="progress is--short" />
    <div class="progress is--long" />
  </div>
</template>

<script>
export default {
  name: 'progress-linear',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .progress-linear--wrap {
    height: 4px;

    .progress {
      background-color: #3f51b5;
      bottom: 0;
      height: inherit;
      left: 0;
      position: absolute;
      right: auto;
      top: 0;
      width: auto;
      will-change: left, right;
      animation-duration: 2.2s;
      animation-iteration-count: infinite;

      &.is--short {
        animation-name: indeterminate-short;
      }

      &.is--long {
        animation-name: indeterminate;
      }
    }

    @keyframes indeterminate {
      0% {
        left: -90%;
        right: 100%;
      }

      60% {
        left: -90%;
        right: 100%;
      }

      100% {
        left: 100%;
        right: -35%;
      }
    }

    @keyframes indeterminate-short {
      0% {
        left: -200%;
        right: 100%;
      }

      60% {
        left: 107%;
        right: -8%;
      }

      100% {
        left: 107%;
        right: -8%;
      }
    }
  }
</style>
