<template>
  <div
    ref="badge"
    class="price-hint-badge--wrap"
    :class="{ 'is--expanded': expanded }"
    @click="onClickBadge"
  >
    <div v-if="expanded">
      {{ $t('priceHintBadge.priceInfo') }} <br>
      {{ $t('priceHintBadge.surchargeInfo') }}
    </div>

    <div v-else>
      {{ $t('priceHintBadge.title') }}
    </div>
  </div>
</template>

<script>
const storageKey = 'hide-pricehintbadge'
export const clearPriceHintStorage = () => localStorage.removeItem(storageKey)

export default {
  name: 'price-hint-badge',

  data () {
    return {
      expanded: true,
    }
  },

  computed: {
    user () {
      return this.$store.state.session.user
    },
  },

  mounted () {
    if (localStorage.getItem(storageKey) === this.user._id) {
      this.expanded = false
    }
  },

  methods: {
    onClickBadge () {
      this.expanded = !this.expanded

      if (this.user && !this.expanded) {
        localStorage.setItem(storageKey, this.user._id)
      }
    },
  },
}
</script>

<style lang="scss">
  .price-hint-badge--wrap {
    position: fixed;
    right: 0;
    top: 55px;
    z-index: 1;
    background-color: #e21e1e;
    color: #fff;
    border-radius: 6px 0 0 6px;
    padding: 12px;
    font-size: 0.75rem;
    min-height: 80px;
    max-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;

    > div {
      transform: rotate(90deg);
    }

    &.is--expanded {
      max-width: 100%;

      > div {
        transform: rotate(0deg);
      }
    }
  }
</style>
