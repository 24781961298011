<template>
  <div class="card panel" :class="[type ? `panel-${type}` : '', flex ? 'flex view' : '']" @click="$emit('click')">
    <header v-if="title || $slots['header']" class="panel-heading card-header">
      <slot name="header">
        <div v-if="title" class="panel-title">
          <div>{{ title }}</div>
          <div v-if="subtitle" class="small">{{ subtitle }}</div>
        </div>
      </slot>
    </header>

    <section v-if="$slots['top']" class="panel-body is-narrow">
      <slot name="top" />
      <hr>
    </section>

    <section class="main panel-body card-content" :class="align ? 'align-' + align : ''">
      <div v-if="imageUrl" class="main-image">
        <img class="img-responsive" :src="require('@/assets/images/' + imageUrl)" :alt="title || imageUrl">
      </div>
      <slot />
    </section>

    <section v-if="$slots['bottom']" class="panel-body is-narrow">
      <hr>
      <slot name="bottom" />
    </section>

    <footer v-if="$slots['footer']" class="panel-footer card-footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: null,
      validate: align => align in ['top', 'center', 'stretch', 'bottom']
    },
    imageUrl: {
      type: String,
      default: null,
    }
  },
}
</script>

<style lang="scss">
.card {
  > .panel-heading, > .panel-body, > .panel-footer {
    overflow: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

section.main {
  &.align-top {
    display: flex;
    align-items: flex-start;
  }

  &.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    .main-image {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &.align-stretch {
    display: flex;
    align-items: stretch;
  }

  &.align-bottom {
    display: flex;
    align-items: flex-end;
  }
}
</style>
