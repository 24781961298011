<template>
  <div
    v-tooltip.left="tooltip"
    class="e-track-btn--wrap"
    :class="{ small, filled, 'icon--left': iconLeft }"
    tabindex="0"
  >
    <router-link
      :to="{ name: to }"
      tag="button"
      class="btn etrack-btn"
      :disabled="disabled || loading"
    >
      <progress-circular v-if="loading" />

      <span v-if="icon && iconLeft" class="material-icons">
        {{ icon }}
      </span>

      {{ text }}

      <span v-if="icon && !iconLeft" class="material-icons">
        {{ icon }}
      </span>
    </router-link>

    <choice-indicator :is-chosen="isChosen" small />
  </div>
</template>

<script>
import ChoiceIndicator from './ChoiceIndicator'
import ProgressCircular from './ProgressCircular'

export default {
  name: 'e-track-btn',

  components: {
    ChoiceIndicator,
    ProgressCircular,
  },

  props: {
    // button can't get used
    disabled: {
      type: Boolean,
      default: false,
    },

    // adds a dark background, uses light text
    filled: {
      type: Boolean,
      default: false,
    },

    // material-icon to show
    icon: {
      type: String,
      default: null,
    },

    // icon gets show left of the text
    iconLeft: {
      type: Boolean,
      default: false,
    },

    // adds a choice-indicator
    isChosen: {
      type: Boolean,
      default: false,
    },

    // show a spinning icon instead of the button-text, disables the button
    loading: {
      type: Boolean,
      default: false,
    },

    // inner text of the button
    text: {
      type: String,
      default: '',
    },

    // add a tooltip with the given content
    tooltip: {
      type: String,
      default: null,
    },

    // reduce font-size, padding
    small: {
      type: Boolean,
      default: false,
    },

    // name of a route to navigate to
    to: {
      type: String,
      default: null,
    },
  }
}
</script>

<style lang="scss">
  .e-track-btn--wrap {
    display: inline-block;
    position: relative;

    .etrack-btn {
      background-color: #fff;
      color: $darkHeadlineBgColor;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      transition: all 0.28s cubic-bezier(.4,0,.2,1);
      outline: none;

      .material-icons {
        margin-left: 8px;
      }

      &:hover {
        background-color: $darkHeadlineBgColor;
        color: #fff;
      }

      &[disabled] {
        opacity: 0.5;
      }
    }

    &.filled {
      .etrack-btn {
        background-color: $darkHeadlineBgColor;
        color: #fff;

        &:hover {
          background-color: lighten($color: $darkHeadlineBgColor, $amount: 20);
        }
      }
    }

    &.small {
      .etrack-btn {
        font-size: 0.85rem;
        padding: 4px 12px;
      }
    }

    &.icon--left {
      .etrack-btn {
        .material-icons {
          margin: 0 8px 0 0;
        }
      }
    }

    .choice-indicator--wrap {
      position: absolute;
      right: -11px;
      top: -11px;
    }
  }
</style>
