const API_URL = '/news'

const fetchOptions = {
  credentials: 'include',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
}

export default {
  namespaced: true,

  state: {
    news: [],
  },

  mutations: {
    set (state, news = []) {
      state.news = news
    },

    remove (state, entryId) {
      state.news = state.news.filter(entry => entry._id !== entryId)
    }
  },

  actions: {
    /**
     * Loads all available/published news, stores those.
     *
     * @param {object} context
     * @returns {void}
     */
    async load ({ commit }) {
      const res = await fetch(`${API_URL}`)
      res.ok && commit('set', await res.json())
    },

    /**
     * Publishes/updates a news-entry. After that, the entry-store gets
     * refreshed by reloading the items since the server does a filtering.
     *
     * @param {object} context
     * @param {object} entry
     * @returns {Promise}
     */
    async publish ({ dispatch }, entry) {
      const res = await fetch(`${API_URL}/publisher/${entry.id ? 'update' : 'create'}`, {
        ...fetchOptions,
        method: `${entry.id ? 'PATCH' : 'POST'}`,
        body: JSON.stringify(entry),
      })

      return new Promise((resolve, reject) => {
        if (res.ok) {
          dispatch('load')
          resolve()
        } else {
          reject(res.error)
        }
      })
    },

    /**
     * Removes the news-entry with the given id.
     *
     * @param {object} context
     * @param {string} entryId
     * @returns {Promise}
     */
    async remove ({ commit }, entryId) {
      const res = await fetch(`${API_URL}/publisher/remove`, {
        ...fetchOptions,
        method: 'DELETE',
        body: JSON.stringify({ entryId }),
      })

      return new Promise((resolve, reject) => {
        if (res.ok) {
          commit('remove', entryId)
          resolve()
        } else {
          reject(res.error)
        }
      })
    },
  },
}
