import router from '@/router/index.js'
import VueRouter from 'vue-router'

/**
 * @typedef HttpClient
 * @type {{
 *   base: string,
 *   jsonHeaders: {Accept: string, 'Content-Type': string}|*,
 *   send(string, options): *,
 *   get(string, options): *,
 *   post(string, object|array, options): *,
 *   put(string, object|array, options): *,
 *   patch(string, object|array, options): *,
 *   delete(string, options): *,
 * }}
 */

/**
 * @typedef HttpOptions
 * @type {*}
 */

/**
 * Creates a new HttpClient.
 *
 * @param {*} options
 * @param {function} interceptor - Function to transform response
 * @return {HttpClient}
 */
export function createClient (options = {}, interceptor) {
  return {
    base: '',
    jsonHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },

    /**
     * Basic method to send data to the server and get a response.
     *
     * @param {string} url
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response> & {asJSON(): Promise<*>})}
     */
    send (url, options = {}) {
      const promise = fetch(this.base + url, options)
      .then(interceptor ? interceptor : res => res)
      .then(res =>
        res.status >= 400
          ? Promise.reject(res)
          : Promise.resolve(res)
      )
      return Object.assign(promise, {
        asJSON () {
          const convert = result => {
            if (result.headers.get('Content-Type')?.includes('application/json') ?? false) {
              return result.json()
            } else {
              return new Promise(resolve => resolve(result))
            }
          }

          return promise
            .then(res => convert(res))
            .catch(res =>
              convert(res)
                .then(data => Promise.reject(data)))
        }
      })
    },

    /**
     * Requests to GET a resource from the server.
     *
     * @param {string} url
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    get (url, options = {}) {
      return this.send(url, options)
    },

    /**
     * Sends a POST request to the server.
     *
     * @param {string} url
     * @param {*} body
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    post (url, body, options = {}) {
      return this.send(url, { method: 'POST', body, ...options })
    },

    /**
     * Sends a PUT request to the server.
     *
     * @param {string} url
     * @param {*} body
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    put (url, body, options = {}) {
      return this.send(url, { method: 'PUT', body, ...options })
    },

    /**
     * Sends a PATCH request to the server.
     *
     * @param {string} url
     * @param {*} body
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    patch (url, body, options = {}) {
      return this.send(url, { method: 'PATCH', body, ...options })
    },

    /**
     * Sends a DELETE request to the server.
     *
     * @param {string} url
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    delete (url, options = {}) {
      return this.send(url, { method: 'DELETE', ...options })
    },

    /**
     * Shortcut for GET Requests, that want to get JSON data.
     *
     * @param {string} url
     * @param {HttpOptions} options by default JsonHeaders are defined
     * @return {Promise<*>}
     */
    getJSON (url, options = {}) {
      return this.get(url, {
        ...options,
        headers: { ...this.jsonHeaders, ...(options.headers || {}) }
      }).asJSON()
    },

    /**
     * Shortcut to POST JSON Data to the server and receive a JSON response.
     *
     * @param {string} url
     * @param {*} data
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    postJSON (url, data, options = {}) {
      return this.post(url, JSON.stringify(data), {
        ...options,
        headers: { ...this.jsonHeaders, ...(options.headers || {}) },
      }).asJSON()
    },

    /**
     * Shortcut to PUT JSON Data to the server and receive a JSON response.
     *
     * @param {string} url
     * @param {*} data
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    putJSON (url, data, options = {}) {
      return this.put(url, JSON.stringify(data), {
        ...options,
        headers: { ...this.jsonHeaders, ...(options.headers || {}) },
      }).asJSON()
    },

    /**
     * Shortcut to PATCH JSON Data on the server and receive a JSON response.
     *
     * @param {string} url
     * @param {*} data
     * @param {HttpOptions} options
     * @return {Promise<Response>|(Promise<Response>&{asJSON, (): Promise<*>})}
     */
    patchJSON (url, data, options = {}) {
      return this.patch(url, JSON.stringify(data), {
        ...options,
        headers: { ...this.jsonHeaders, ...(options.headers || {}) },
      })
    },
  }
}

/** @type {HttpClient} */
const defaultClient = createClient({}, res => {
  return res
})

export default /** @type {HttpClient} */ defaultClient
