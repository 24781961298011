<template>
  <div class="input-group i-input" :class="outerClass">
    <slot name="start" />
    <span v-if="iconLeft" class="input-group-addon i-input-icon i-input-icon-left"><icon :icon="iconLeft" /></span>
    <span v-if="textLeft" class="input-group-addon i-input-text i-input-text-left">{{ textLeft }}</span>
    <slot>
      <input
        v-if="value !== null"
        :id="inputId"
        v-model="val"
        :type="type"
        class="form-control"
        :class="{ disabled }"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :step="step"
        :readonly="readonly"
        :disabled="disabled"
        @keypress.enter="sendFromKeys"
        @keyup.up="sendFromKeys"
        @keyup.down="sendFromKeys"
        @blur="sendFromBlur"
      >
    </slot>
    <span v-if="textRight" class="input-group-addon i-input-icon i-input-icon-right">{{ textRight }}</span>
    <span v-if="iconRight" class="input-group-addon i-input-text i-input-text-right"><icon :icon="iconRight" /></span>
    <slot name="end" />
  </div>
</template>

<script>
import _ from 'underscore'
export default {
  name: 'input-group',
  props: {
    inputId: {
      type: String,
      default: null,
    },
    value: {
      default: null,
      validator: value => value == null || _.isString(value) || _.isNumber(value)
    },
    type: {
      type: String,
      default: 'text',
      validator: t => ['text', 'number'].includes(t)
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: null,
      validator: size => ['lg', 'md', 'sm'].includes(size),
    },
    placeholder: {
      type: String,
      default: null,
    },
    textLeft: {
      type: String,
      default: null,
    },
    textRight: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sendOn: {
      type: String,
      default: 'change',
      validator: t => ['change', 'keys', 'blur'].includes(t),
    }
  },
  data () {
    return {
      val: this.value,
    }
  },
  computed: {
    isNumber () {
      return this.type === 'number'
    },
    outerClass () {
      return [
        this.size ? 'input-group-' + this.size : '',
      ].join(' ')
    },
    output () {
      return this.isNumber ? +this.val : this.val
    }
  },
  watch: {
    value (value) {
      const newVal = this.isNumber ? +value : value
      if (this.output !== newVal) {
        this.val = newVal
      }
    },
    output () {
      if (this.sendOn === 'change') {
        this.send()
      }
    }
  },
  methods: {
    send () {
      if (!(this.readonly || this.disabled)) this.$emit('input', this.output)
    },
    sendOnChange () {
      if (this.sendOn === 'change') this.send()
    },
    sendFromKeys () {
      if (this.sendOn === 'keys') this.send()
    },
    sendFromBlur () {
      if (this.sendOn === 'blur' || this.sendOn === 'keys') this.send()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
