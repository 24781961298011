<template>
  <transition name="fade" appear>
    <div
      v-if="visible"
      class="e-track-dialog--wrap"
      :class="{
        'is--light': light,
      }"
    >
      <div
        class="dialog-backdrop"
        @click="!enforceConfirmation && $emit('close')"
      />

      <div class="e-track-dialog">
        <div class="dialog-header d-flex align-center">
          {{ title }}

          <span
            v-if="!enforceConfirmation"
            class="material-icons ml-auto"
            @click="$emit('close')"
          >
            close
          </span>
        </div>

        <div class="dialog-content">
          <slot />
        </div>

        <div v-if="$slots.footer" class="dialog-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'e-track-dialog',

  props: {
    enforceConfirmation: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: null,
    },

    visible: {
      type: Boolean,
      default: false,
    },

    light: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .e-track-dialog--wrap {
    position: fixed;
    z-index: 15;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog-backdrop {
      position: absolute;
      z-index: 4;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.878);
    }

    .e-track-dialog {
      z-index: 5;
      position: relative;
      width: 800px;
      max-width: 80%;
      max-height: 80%;
      border: 3px solid;
      background-color: #fff;
      box-shadow: 0 7px 9px -4px rgba(0, 0, 0, 0.2), 0 14px 21px 2px rgba(0, 0, 0, 0.14), 0 5px 26px 4px rgba(0, 0, 0, 0.12);
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .dialog-header, .dialog-content, .dialog-footer {
        padding: 12px 16px;
      }

      .dialog-header {
        background-color: $darkHeadlineBgColor;
        color: #fff;

        .material-icons {
          cursor: pointer;
        }
      }

      .dialog-content {
        border-bottom: 1px solid rgba(0, 0, 0, 0.059);
        overflow-y: auto;
      }

      .dialog-footer {
        display: flex;
        background-color: #fafafa;
      }
    }

    &.is--light {
      .e-track-dialog {
        .dialog-header,
        .dialog-footer {
          background-color: #fff;
          color: $darkHeadlineBgColor;
        }

        .dialog-content {
          border: none;
        }
      }
    }
  }
</style>
