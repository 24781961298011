<template>
  <div
    class="e-track-input--wrap d-flex"
    :class="{
      'has--label': label,
      'text': text,
    }"
  >
    <span v-if="label" class="input-label">
      {{ label }}
    </span>

    <div class="input-wrap">
      <div
        v-if="customSpinner"
        class="custom-spinner"
      >
        <div
          class="spinner-icon"
          @click="spinUp"
        >
          <img src="/images/Elektroschiene/arr_up.png">
        </div>
        <div
          class="spinner-icon"
          @click="spinDown"
        >
          <img src="/images/Elektroschiene/arr_down.png">
        </div>
      </div>

      <input
        ref="input"
        :value="value"
        :type="type"
        :min="min"
        :max="max"
        :step="step"
        :class="{ small }"
        :pattern="type === 'number' ? '\\d*' : null"
        :inputmode="inputmode"
        @focus="e => e.target.select()"
        @blur="({ target: { value } }) => $emit('blur', value)"
        @input="({ target: { value } }) => $emit('input', value)"
      >
    </div>

    <span v-if="affix" class="input-affix">
      {{ affix }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'e-track-input',

  props: {
    affix: {
      type: String,
      default: null,
    },

    customSpinner: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: null,
    },

    min: {
      type: [Number, String],
      default: 0,
    },

    max: {
      type: [Number, String],
      default: null,
    },

    small: {
      type: Boolean,
      default: false,
    },

    text: {
      type: Boolean,
      default: false,
    },

    step: {
      type: [Number, String],
      default: null,
    },

    type: {
      type: String,
      default: 'number',
    },

    inputmode: {
      type: String,
      default: null,
    },

    value: {
      type: [Number, String],
      default: '',
    },
  },

  methods: {
    spinUp () {
      this.$refs.input.stepUp()
      this.$refs.input.dispatchEvent(new Event('input'))
    },

    spinDown () {
      this.$refs.input.stepDown()
      this.$refs.input.dispatchEvent(new Event('input'))
    },
  },
}
</script>

<style lang="scss">
  .e-track-input--wrap {
    min-width: 0;

    input {
      border: 2px solid #2c2c2c;
      color: #2c2c2c;
      font-size: 1.25rem;
      padding: 8px 16px;
      max-width: 100%;
      outline: none;

      &.small {
        padding: 4px 8px;
        font-size: 1rem;
      }
    }

    .input-wrap {
      position: relative;
      min-width: 0;
      max-width: 100%;

      .custom-spinner {
        position: absolute;
        right: 0;
        top: 2px;
        bottom: 2px;
        z-index: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .spinner-icon {
          cursor: pointer;
          user-select: none;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #ddd;
          }

          img {
            max-width: 15px;
            margin: 4px;
          }
        }

        & + input {
          padding-right: 23px;

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            opacity: 0;
            pointer-events: none;
          }

          appearance:textfield;
          -moz-appearance:textfield;
        }
      }
    }

    .input-affix {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      background-color: #fff;
      border: 2px solid;
      border-left: 0;
    }

    &.has--label {
      position: relative;
      padding: 25px 0 0 0;

      .input-label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        font-size: 16px;
        font-weight: 700;
        font-family: $fontBold;
        text-transform: uppercase;
      }
    }

    &.text {
      input, .input-affix {
        border: none;
        padding: 4px 10px;
      }

      .input-wrap {
        input {
          &:hover,
          &:focus {
            background-color: #f1f1f1;
          }
        }

        .custom-spinner {
          top: 0;
          bottom: 0;
        }
      }
    }
  }
</style>
