<template>
  <default-page-layout
    :heading="$t('etrack.decorWidth')"
    :loading="isLoading"
    class="e-track-decor-width--wrap"
  >
    <div class="row d-flex flex-column align-center">
      <div class="preview-image--wrap position-relative">
        <img
          v-if="imageToUse"
          :src="imageToUse"
          class="img-responsive preview-image"
        >

        <div class="width-input--wrap">
          <div class="row d-flex align-center">
            <div class="col-sm-4">
              <div
                v-if="showOverhangInfoLeft"
                class="overhang-hint"
              >
                {{ $t('etrack.decorWidthOverhang') }}
              </div>
            </div>
            <div class="col-sm-4 d-flex flex-column align-center position-relative">
              <e-track-input
                :key="chosenWidth"
                :value="chosenWidth"
                type="number"
                :min="bounds.min"
                :max="bounds.max"
                step="0.1"
                custom-spinner
                inputmode="decimal"
                :affix="measureUnit"
                @input="width => enteredWidth = width"
              />
              <div class="bounds-hint text-center">
                {{ $t('etrack.maxLength') }} {{ bounds.max }} {{ measureUnit }} <br>
                {{ $t('etrack.notShortenable') }}
              </div>
            </div>
            <div class="col-sm-4 text-right">
              <div
                v-if="showOverhangInfoRight"
                class="overhang-hint"
              >
                {{ $t('etrack.decorWidthOverhang') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <e-track-btn
        to="ETrackGliderChoice"
        :text="$t('etrack.prev')"
      />

      <e-track-btn
        :disabled="!hasValidWidth"
        :tooltip="!hasValidWidth ? $t('etrack.choiceNeeded') : null"
        :loading="isLoading"
        :text="$t('etrack.next')"
        class="ml-auto"
        @click.native="setWidth(enteredWidth)"
      />
    </template>

    <e-track-dialog
      :title="$t('etrack.rodSizeHintTitle')"
      :visible="showHintDialog"
      enforce-confirmation
    >
      {{ $t('etrack.rodSizeHint') }} <br>
      {{ $t('etrack.rodSizeHintAdvice') }} <br>

      <template #footer>
        <e-track-btn
          :text="$t('etrack.confirm')"
          small
          filled
          class="ml-auto"
          icon="check"
          @click.native="showHintDialog = false"
        />
      </template>
    </e-track-dialog>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackDialog from '../components/ETrackDialog'
import ETrackInput from '../components/ETrackInput'

export default {
  name: 'e-track-decor-width',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    ETrackDialog,
    ETrackInput,
  },

  data () {
    return {
      isLoading: false,
      enteredWidth: null,
      showHintDialog: false,
    }
  },

  computed: {
    articleConfig () {
      return this.$store.state.etrack.articleConfig
    },

    bounds () {
      const { MinRodLength: min, MaxRodLength: max } = this.articleConfig
      return { min, max }
    },

    chosenCurtain () {
      return this.config.curtain
    },

    chosenPosition () {
      return this.config.enginePosition
    },

    chosenWidth () {
      return this.config.decorWidth
    },

    config () {
      return this.$store.state.etrack.configuration
    },

    hasValidWidth () {
      return this.chosenWidth >= this.bounds.min &&
        this.chosenWidth <= this.bounds.max
    },

    /**
     * The preview is based on previous configuration-steps, so we have to pick
     * a fitting image.
     *
     * @returns {string?}
     */
    imageToUse () {
      const driveCurtainPosition = this.config.driveCurtainPosition
      const enginePosition = driveCurtainPosition.enginePosition || 'mo'
      const curtainType = driveCurtainPosition.curtainType

      return curtainType && enginePosition && this.config.endPieces.id && this.config.confectionForm.id
        ? `/images/Elektroschiene/DecorWidth/${this.config.confectionForm.id}_${enginePosition}_${this.config.endPieces.id}_${curtainType}.jpg`
        : null
    },

    /**
     * If an article with an engine was chosen we have additional config-steps.
     * Based on that we pick the next step to go to after this one.
     *
     * @returns {boolean}
     */
    nextStep () {
      return this.$store.getters['etrack/typeManualActive']
        ? 'ETrackSummary'
        : 'ETrackEngine'
    },

    measureUnit () {
      return this.$store.state.session.catalog.measureUnit
    },

    /**
     * If there isn't a wall on the left side we have to show an info about the
     * overhang at that side.
     *
     * @returns {boolean}
     */
    showOverhangInfoLeft () {
      return ['frei', 'war'].includes(this.config.endPieces.id)
    },

    /**
     * If there isn't a wall on the right side we have to show an info about the
     * overhang at that side.
     *
     * @returns {boolean}
     */
    showOverhangInfoRight () {
      return ['frei', 'wal'].includes(this.config.endPieces.id)
    },
  },

  mounted () {
    const { RodLength } = this.$store.state.etrack.articleConfig
    this.setDecorWidth(RodLength)
    this.showHintDialog = this.config.endPieces.id === 'ww'
  },

  methods: {
    /**
     * Internally, we always want to treat lengths as numbers - even if the API
     * uses comma-separated strings.
     *
     * @param {string|number} length
     * @returns {undefined}
     */
    setDecorWidth (length) {
      const decorWidth = parseFloat(length.toString().replace(',', '.'))

      this.$store.commit('etrack/setConfigEntry', {
        key: 'decorWidth',
        value: decorWidth,
      })

      this.enteredWidth = decorWidth
    },

    /**
     * Stores the given width as the chosen one. The server checks the value
     * based on the allowed bounds and returns specific error-codes if those
     * get exceeded so we're able to show a notification and set a allowed
     * width.
     *
     * @param {object} width
     * @returns {Promise}
     */
    async setWidth (width) {
      this.isLoading = true
      this.setDecorWidth(width)

      const res = await this.$store.dispatch('etrack/postArticleConfig', {
        Key: 'RodLength',
        Value: width.toString().replace('.', ',')
      })

      this.isLoading = false

      if (['RodToLong', 'RodToShort'].includes(res.ReturnCode)) {
        this.$notify({
          content: this.$t(`Error.${res.ReturnCode}`),
          title: this.$t('etrack.error'),
          type: 'error',
          placement: 'bottom-right',
          customClass: 'alert--above-content',
        })

        this.setDecorWidth(res.RodLength)
        return
      }

      this.$router.push({ name: this.nextStep })
    }
  },
}
</script>

<style lang="scss">
  @import '../styles/variables.scss';

  .e-track-decor-width--wrap {
    .preview-image--wrap {
      // no responsive font-size since the texts need specific positions
      .bounds-hint,
      .overhang-hint,
      .e-track-input--wrap input,
      .e-track-input--wrap .input-affix {
        font-size: 2vmin;
      }

      .preview-image {
        max-height: 60vh;
        max-width: 90vw;
      }

      .width-input--wrap {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 12.85%;

        .e-track-input--wrap {
          display: inline-flex;
          max-width: 200px;
        }

        .bounds-hint {
          position: absolute;
          bottom: -145%;
        }
      }
    }
  }
</style>
