<template>
  <div class="color-choice-table--wrap">
    <table class="table">
      <thead>
        <tr>
          <th />
          <th
            v-for="profile in profiles"
            :key="profile.Geometry"
          >
            <img
              :src="getColumnHeaderImage(profile)"
              class="img-responsive"
            >
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="{ ColorNumber, Name } in colors"
          :key="ColorNumber"
        >
          <td class="text-right">
            {{ Name }}
          </td>
          <td
            v-for="profile in profiles"
            :key="`${ColorNumber}_${profile.Geometry}`"
          >
            <div
              v-if="getColorImage(profile, ColorNumber)"
              class="color-box has--choice-indicator"
              :class="{
                'is--chosen': isChosenCell(profile, ColorNumber)
              }"
              :style="{
                'background-image': `url(${getColorImage(profile, ColorNumber)})`
              }"
              @click="$emit('input', { profile, color: ColorNumber })"
              @mouseenter="onEnterProfileColor(profile, ColorNumber)"
              @mouseleave="onLeaveProfileColor()"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const profileImageBasePath = '/images/Elektroschiene/Profile'

export default {
  name: 'color-choice-table',

  props: {
    chosenColor: {
      type: Object,
      default: null,
    },

    chosenProfile: {
      type: Object,
      default: null,
    },

    profiles: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      hoveredProfileColumn: null,
    }
  },

  computed: {
    /**
     * All unique available colors of the given profiles to choose from.
     *
     * @returns {array}
     */
    colors () {
      return this.profiles.reduce((colors, profile) => {
        profile.Colors.forEach(color => {
          colors.find(({ ColorNumber }) => color.ColorNumber === ColorNumber) === undefined && colors.push(color)
        })

        return colors
      }, [])
    }
  },

  methods: {
    /**
     * Build the path of the color-image for the given color-id if available.
     *
     * @param {object} profile
     * @param {string} color
     * @returns {string}
     */
    getColorImage (profile, color) {
      const profileHasColor = profile.Colors.find(({ ColorNumber }) => ColorNumber === color) !== undefined

      return profileHasColor
        ? `${profileImageBasePath}/oberflaechen/${color}.jpg`
        : null
    },

    /**
     * Each column-header is related to a profile with an unique image with a
     * possible 'active'-alternative (active = some color gets hovered or the
     * profile is currently chosen).
     *
     * @param {object} profile
     * @returns {string}
     */
    getColumnHeaderImage (profile) {
      const isActiveColumn = profile.Geometry === this.hoveredProfileColumn ||
        this.chosenProfile?.Geometry === profile.Geometry

      return `${profileImageBasePath}/columns/${profile.Geometry}${isActiveColumn ? '-aktiv' : ''}.svg`
    },

    /**
     * Checks if the given profile/color-cell mathes the current choice.
     *
     * @param {object} profile
     * @param {string} color
     * @returns {boolean}
     */
    isChosenCell (profile, color) {
      if (!this.chosenProfile || !this.chosenColor) {
        return false
      }

      return this.chosenProfile.Geometry === profile.Geometry && this.chosenColor.ColorNumber === color
    },

    /**
     * onEnterProfileColor
     *
     * @param {object} profile
     * @param {string} color
     * @returnd {undefined}
     */
    onEnterProfileColor (profile, color) {
      this.hoveredProfileColumn = profile.Geometry
      this.$emit('show-preview', { profile, color })
    },

    /**
     * onLeaveProfileColor
     *
     * @returnd {undefined}
     */
    onLeaveProfileColor () {
      this.hoveredProfileColumn = null
      this.$emit('hide-preview')
    },
  }
}
</script>

<style lang="scss">
  @import '../styles/variables.scss';

  .color-choice-table--wrap {
    .color-box {
      height: 36px;
      max-height: 5vh;
      cursor: pointer;
      background-size: cover;

      &.has--choice-indicator {
        &::after {
          right: -7px;
        }
      }
    }

    table.table {
      thead {
        th {
          img {
            width: 55px;
            height: 48px;
            margin: 0 auto 10px auto;
            object-fit: contain;
            object-position: center bottom;
          }
        }
      }

      thead,
      tbody {
        tr {
          th, td {
            border: none;
          }
        }
      }
    }
  }

  @media (min-width: $customBreakpointMd) {
    .color-choice-table--wrap {
      .color-box {
        height: 50px;
      }

      table.table {
        thead {
          th {
            img {
              width: 70px;
              height: 58px;
            }
          }
        }
      }
    }
  }
</style>
