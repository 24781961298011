<template>
  <default-page-layout :heading="$t('etrack.finish')" class="e-track-finish--wrap">
    <e-track-card class="flex-auto">
      <ul>
        <li>
          <div class="finish-description small">
            {{ $t('etrack.infoSave') }}
          </div>
          <div class="finish-actions">
            <e-track-btn
              small
              filled
              icon-left
              icon="save"
              :text="$t('etrack.createBookmark')"
              @click.native="bookmarkDialogVisible = true"
            />
          </div>
        </li>

        <li>
          <div class="finish-description small">
            {{ $t('etrack.infoOrder') }}
            <div class="form-group mb-0 mt-2">
              <label for="extraWish" class="d-flex align-center mb-0">
                <input
                  id="extraWish"
                  v-model="showExtraWish"
                  type="checkbox"
                  class="mr-1"
                >
                {{ $t('etrack.extraWishEnable') }}
              </label>
            </div>
          </div>
          <textarea
            v-if="showExtraWish"
            v-model="extraWish"
          />
          <div class="finish-actions">
            <e-track-btn
              small
              filled
              icon-left
              icon="add_shopping_cart"
              :text="$t('etrack.addToCart')"
              @click.native="addToCart()"
            />
          </div>
        </li>

        <li>
          <div class="finish-description small">
            {{ $t('etrack.infoPrint') }}
          </div>
          <div class="finish-actions">
            <e-track-btn
              small
              filled
              icon-left
              icon="download"
              :text="$t('etrack.saveAsPdf')"
              @click.native="createSummaryPdf"
            />
          </div>
        </li>

        <li>
          <div class="finish-description small">
            {{ $t('etrack.infoPrintCurtain') }}
          </div>
          <div class="finish-actions">
            <e-track-btn
              small
              filled
              icon-left
              icon="download"
              :text="$t('etrack.saveAsPdf')"
              @click.native="createCurtainCalculationPdf"
            />
          </div>
        </li>
      </ul>
    </e-track-card>

    <template #footer>
      <e-track-btn
        to="ETrackSummary"
        :text="$t('etrack.backToConfig')"
      />

      <e-track-btn
        :text="$t('etrack.restart')"
        class="ml-auto"
        @click.native="restart"
      />
    </template>

    <e-track-dialog
      :title="$t('etrack.createBookmark')"
      :visible="bookmarkDialogVisible"
      class="bookmark-modal"
      @close="bookmarkDialogVisible = false"
    >
      <form id="bookmarkForm" @submit.prevent="getConfigToPersist">
        {{ $t('etrack.pleasePickName') }}
        <e-track-input v-model="configName" type="text" />
      </form>

      <template #footer>
        <e-track-btn small :text="$t('etrack.close')" @click.native="bookmarkDialogVisible = false" />
        <e-track-btn
          small
          :text="$t('etrack.createBookmark')"
          class="filled ml-auto"
          @click.native="getConfigToPersist"
        />
      </template>
    </e-track-dialog>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ETrackDialog from '../components/ETrackDialog'
import ETrackInput from '../components/ETrackInput'

import curtainCalculation from '../services/curtainCalculation.js'

import http from '@/utilities/http'

export default {
  name: 'e-track-finish',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ETrackDialog,
    ETrackInput,
  },

  data () {
    return {
      configName: '',
      bookmarkDialogVisible: false,
      showExtraWish: false,
      extraWish: '',
    }
  },

  computed: {
    articleConfig () {
      return this.$store.state.etrack.articleConfig
    },

    config () {
      return this.$store.state.etrack.configuration
    },

    measureUnit () {
      return this.$store.state.session.catalog.measureUnit
    },
  },

  methods: {
    addToCart () {
      this.$store.dispatch('cart/setCart', this.showExtraWish ? this.extraWish : '')
        .then(() => {
          this.Hint.add({
            type: 'success',
            message: this.$t('setConfig.addToCart.success.message'),
          })

          this.extraWish = ''
          this.showExtraWish = false
        })
        .catch(() => {
          this.Hint.add({
            type: 'error',
            title: this.$t('setConfig.addToCart.error.title'),
            message: this.$t('setConfig.addToCart.error.message')
          })
        })
    },

    /**
     * Resets the state before redirecting the user to the start of the
     * configuration.
     *
     * @returns {void}
     */
    restart () {
      this.$store.commit('etrack/reset')
      this.$router.push({ name: 'ETrackTypeChoice' })
    },

    /**
     * onSaveConfigError
     *
     * @returns {undefined}
     */
    onSaveConfigError () {
      this.$notify({
        content: this.$t('etrack.bookmarkNotCreated'),
        title: this.$t('etrack.error'),
        type: 'error',
        placement: 'bottom-right',
        customClass: 'alert--above-content',
      })

      this.configName = ''
      this.bookmarkDialogVisible = false
    },

    /**
     * To be able to persist an article-configuration, we must get a special
     * datastructure from the api.
     */
    getConfigToPersist () {
      http
        .getJSON('/api/article/config/store')
        .then(this.saveConfig)
        .catch(this.onSaveConfigError)
    },

    /**
     * Persists the given article-configuration, the state of the client so the
     * user is able to reload it.
     *
     * @param {object} config
     * @returns {Promise}
     */
    async saveConfig (config) {
      http
        .postJSON('/showcase/create', {
          articleNr: this.config.bracket.SetArticleNumber,
          color: this.config.color.ColorNumber,
          config,
          etrackState: this.config,
          title: this.configName,
        })
        .then(() => {
          this.$store.dispatch('configStorage/loadUserConfigs')

          this.$notify({
            content: this.$t('etrack.bookmarkCreated'),
            title: this.$t('etrack.success'),
            type: 'success',
            placement: 'bottom-right',
            customClass: 'alert--above-content',
          })

          this.configName = ''
          this.bookmarkDialogVisible = false
        })
        .catch(this.onSaveConfigError)
    },

    /**
     * Users should be able to download a pdf-file with sewing instructions.
     * So we have to reuse the logic of the previous step to create a dataset
     * for the node-backend which creates the file.
     *
     * @returns {undefined}
     */
    createCurtainCalculationPdf () {
      const { left, right } = curtainCalculation.getConfections()
      const { curly, pleat, wave } = curtainCalculation.calculations
      const confectionForm = this.config.confectionForm.id
      const calculationToUse = { Curly: curly, Pleat: pleat, W: wave }[confectionForm]

      const data = {
        confection: confectionForm,
        curtainAddition: this.config.curtainAddition,
        decorWidth: this.config.decorWidth,
        measureUnit: this.measureUnit,
        previewImage: curtainCalculation.getPreviewImage(),
      }

      if (left) {
        data.left = {
          ...left,
          calculation: calculationToUse(left.isFreestanding, left.isSplitted),
        }
      }

      if (right) {
        data.right = {
          ...right,
          calculation: calculationToUse(right.isFreestanding, right.isSplitted),
        }
      }

      http
        .put('/pdf/generateEtrackCurtain', JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async res => {
          const pdfId = await res.json()
          this.downloadPdf(pdfId)
        })
    },

    createSummaryPdf () {
      http
        .put('/pdf/generateEtrackSummary', JSON.stringify({
          config: this.config,
          gliderColor: this.articleConfig.RingColorSelected,
          price: this.articleConfig.TotalPrice,
          measureUnit: this.measureUnit,
          assemblySummary: this.$store.getters['etrack/assemblySummary'],
          batterySuppliesSummary: this.$store.getters['etrack/batterySuppliesSummary'],
          driveCurtainSummary: this.$store.getters['etrack/driveCurtainSummary'],
        }), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async res => {
          const pdfId = await res.json()
          this.downloadPdf(pdfId)
        })
    },

    /**
     * The API offers a route to download a previously generated PDF-file by
     * its id. On our side we have to create a temporary link for that.
     *
     * @param {string} pdfId
     * @returns {undefined}
     */
    downloadPdf (pdfId) {
      const filelink = document.createElement('a')

      filelink.href = `/pdf/${pdfId}`
      filelink.target = '_blank'
      document.body.appendChild(filelink)
      filelink.click()
      document.body.removeChild(filelink)
    },
  }
}
</script>

<style lang="scss">
  .e-track-finish--wrap {
    input[type=checkbox] {
      top: 0;
    }

    textarea {
      font-size: 1rem;
      flex: 1;
      margin: 0 30px;
    }

    .e-track-card {
      .card-content {
        padding-top: 0;
        padding-bottom: 0;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 24px 0;
            border-bottom: 1px solid $colDefaultDark;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            &:last-child {
              border: none;
            }

            .etrack-btn {
              min-width: 190px;
            }
          }
        }
      }
    }

    .bookmark-modal {
      .e-track-input--wrap {
        margin-top: 16px;

        input {
          max-width: none;
        }
      }
    }
  }
</style>
