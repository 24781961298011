import http from "@/utilities/http";
import i18n from "@/i18n";
import { announcementsDismissedCookie } from '@/router/index'
import { clearPriceHintStorage } from '@/components/PriceHintBadge'

export default {
  namespaced: true,
  state: {
    user: null,
    catalog: null,
    logout: () => {
      console.warn('Cannot log out, service not registered')
    },
  },
  getters: {
    measureUnit: state => state.catalog?.measureUnit,
    currency: state => state.catalog?.currency,
    roles: state => state.user?.roles || [],
    hasRole: (state, getters) => role => getters.roles.includes(role),
    hasRoles: (state, getters) => roles => roles.some(role => getters.hasRole(role)),
  },
  mutations: {
    setSession (state, value = {}) {
      state.user = value?.user || null
      state.catalog = value?.catalog || null

      if (state.user && state.catalog) {
        state.user = {
          ...state.user,
          catalogCurrency: state.catalog.currency,
          measureUnit: state.catalog.measureUnit,
        }
      }
    },

    /**
     * Setzt den aktuellen User.
     *
     * @param {*} state
     * @param {*} user
     */
    setUser (state, user) {
      i18n.locale = (user?.language || 'DE').toLowerCase()
      state.user = user || null
    },

    /**
     * Setzt die Einstellungen des Benutzers.
     *
     * @param {} state
     * @param {} settings
     */
    setSettings (state, settings) {
      state.user.settings = settings
    },

    setNextSavedCart (state, removeOrder) {
      state.user.prevSavedCartName = state.user.currentSavedCartName
      state.user.currentSavedCartName = null

      if (removeOrder) {
        // Set Date to null to fallback to a past date (1970)
        // which deletes the cookie
        document.cookie = `order=''; expires=${new Date(null)}`
      }
    },

    setCurrentSavedCartName (state, name) {
      state.user.currentSavedCartName = name
    },

    setSavedCart(state, { savedCarts, currentSavedCartName }) {
      state.user.savedCarts = savedCarts
      state.user.currentSavedCartName = currentSavedCartName
    },
  },
  actions: {
    login (context, user) {
      clearPriceHintStorage()
      document.cookie = `${announcementsDismissedCookie}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      context.commit('setUser', user)
    },

    logout ({ state, commit }) {
      const performLogout = () => {
        state.logout()
        commit('setUser', null)
      }

      return http.delete('api/session')
        .then(performLogout)
        .catch(err => {
          // session is already invalid
          err.status === 401 && performLogout()
        })
    },

    getRememberMe () {
      return (decodeURIComponent(document.cookie || '')
        .split('; ')
        .find(row => row.startsWith('rememberMe=')) || '')
        .split('=')[1]
    },

    setRememberMe (context, { value, expires }) {
      document.cookie = `rememberMe=${encodeURIComponent(value) || ''}; expires=${expires}`
    },

    getEntryUrl () {
      return (decodeURIComponent(document.cookie || '')
        .split('; ')
        .find(row => row.startsWith('entryUrl=')) || '')
        .split('=')[1]
    },

    setEntryUrl (context, { value, expires }) {
      document.cookie = `entryUrl=${encodeURIComponent(value) || ''}; expires=${expires}`
    },

    nextSavedCart({ commit }, removeOrder) {
      commit('setNextSavedCart', removeOrder)
    },

    updateCurrentSavedCartName ({ commit }, name) {
      commit('setCurrentSavedCartName', name)
    },

    updateSavedCart({ commit }, data) {
      commit('setSavedCart', data)
    },
  }
}
