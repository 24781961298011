export default {
  namespaced: true,
  state: {
    count: 0,
  },
  getters: {
  },
  mutations: {
    /**
         * Setzt die Anzahl der neuen Registrierungen.
         *
         * @param {*} state
         * @param {Number} count
         */
    setCount (state, count) {
      state.count = count
    },
  },
  actions: {
  }

}
