function isAtRoute (current, route) {
  if (!current) {
    return false
  }
  if (typeof route === 'string') {
    return current.regexp?.test(route) || false
  }
  if (route instanceof RegExp) {
    return route.test(current.originalPath)
  }
  if (route instanceof Array) {
    return route.some(r => isAtRoute(current, r))
  }
  throw new Error(`Invalid route for comparison provided: '${typeof route}' expected, string, string[] or RegExp`)
}

export default {
  namespaced: true,
  state: {
    Catalog: null,
    route: {
      current: null,
      previous: null,
    },
    title: null,
  },
  getters: {
    isAtRoute: state => route => isAtRoute(state.route.current || null, route)
  },
  mutations: {
    /**
     * Setzt die aktuelle Route, damit sie auch in Vue Komponenten abgefragt werden kann.
     *
     * @param {*} state
     * @param {ngRoute|null} route
     */
    setRoute (state, route) {
      state.route = {
        previous: state.route.current,
        current: route,
      }
      state.title = route.title || null
    },
    /**
     * Setzt die Catalog-Logik.
     *
     * @param {*} state
     * @param {*} catalog
     */
    setCatalog (state, catalog) {
      state.Catalog = catalog
    },
  },
  actions: {

  }
}
