import Hint from '@/components/Hint'
import i18n from '@/i18n.js'

export default {
  install (Vue, options) {
    const component = Vue.extend(Hint)
    const instance = new component({
      i18n: i18n,
    })

    instance.$mount()
    document.body.appendChild(instance.$el)

    Vue.prototype.Hint = {
      add: instance.add,
      remove: instance.remove,
      getCurrentHints: instance.getCurrentHints,
      requestError (data) {
        const code = data?.code ?? data?.status ?? ''
        const name = data?.name ?? data?.statusText ?? instance.$t('general.unknownError')
        const message = data?.err ?? ''

        instance.Hint.add({
          type: 'error',
          title: `${name} ${code}`,
          message: message,
        })
      },
    }
  }
}
