<template>
  <default-page-layout
    :heading="$t('etrack.assembly')"
    :loading="isLoading"
    class="e-track-assembly--wrap"
    :class="{
      'wall-distance-dialog-active': showWallDistanceElements,
    }"
  >
    <image-switch
      :default="`${assemblyImageBasePath}/prev_default.jpg`"
      :primary="tempPreview ? `/images/${tempPreview.AdditionalPicture}` : null"
      :secondary="chosenBracket ? `/images/${chosenBracket.AdditionalPicture}` : null"
      height-aware
      class="position-relative mr-10"
    />

    <template #aside>
      <div class="row d-flex flex-wrap">
        <div
          v-for="(assembly, i) in assemblyItems"
          :key="i"
          class="col-xs-6 assembly-col d-flex flex-column pl-5 pr-5"
        >
          <e-track-card :loading="isLoading">
            <template #title>
              <div class="mb-4">
                {{ assembly.IsCeiling ? $t('etrack.assemblyTypes.ceiling') : $t('etrack.assemblyTypes.wall') }}
              </div>
              <img
                :src="`${assemblyImageBasePath}/menue_${assembly.IsCeiling ? 'decke' : 'wand'}.jpg`"
                class="img-responsive mb-1"
              >

              <div
                class="arrow-divider mb-10"
                :class="{
                  'is--active': assemblyIsActive(assembly)
                }"
              />
            </template>

            <div
              v-for="option in assembly.Options"
              :key="`${option.BracketArticleNumber}_${option.SetArticleNumber}`"
              class="option-box has--choice-indicator pb-2 pt-2"
              :class="{
                'is--chosen': optionIsChosen(option),
                'left-indicator': i % 2 === 0,
                'd-flex align-center': !assembly.IsCeiling,
              }"
              @mouseenter="tempPreview = option"
              @mouseleave="tempPreview = null"
              @click="onChooseBracket(option, assembly)"
            >
              <template v-if="assembly.IsCeiling">
                <ceiling-bracket-image
                  :bracket="option.BracketArticleNumber"
                  :geometry="configuration.profil.Geometry"
                  class="flex-auto"
                >
                  <template v-if="showWallDistanceElements">
                    <e-track-input
                      :key="inputKey"
                      :value="articleConfig.WallDistance.toFixed(1)"
                      :affix="measureUnit"
                      step="0.1"
                      :min="wallDistanceBounds.min"
                      :max="wallDistanceBounds.max"
                      inputmode="decimal"
                      text
                      custom-spinner
                      class="mx-auto"
                      @input="val => enteredWallDistance = val"
                    />
                  </template>
                </ceiling-bracket-image>

                <div class="d-flex">
                  <e-track-btn
                    v-if="showWallDistanceElements"
                    :text="$t('general.ok')"
                    class="ml-auto mt-2"
                    @click.native="setWallDistance(enteredWallDistance)"
                  />
                </div>
              </template>
              <template v-else>
                <div class="option-label d-flex align-center">
                  <i
                    v-if="option.AdditionalText1"
                    v-tooltip="option.AdditionalText1"
                    class="material-icons mr-2"
                  >
                    info
                  </i>
                  {{ option.Length }}
                </div>
                <img
                  :src="`/images/${option.Picture}`"
                  class="img-responsive wall-bracket-image"
                >
              </template>
            </div>
          </e-track-card>
        </div>
      </div>
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackProfil"
        :text="$t('etrack.prev')"
      />

      <e-track-btn
        to="ETrackEndPieces"
        :disabled="!nextStepAllowed"
        :loading="isLoading"
        :tooltip="nextStepAllowed ? null : $t('etrack.choiceNeeded')"
        :text="$t('etrack.next')"
        class="ml-auto"
      />
    </template>
  </default-page-layout>
</template>

<script>
import CeilingBracketImage from '../components/CeilingBracketImage.vue'
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ETrackInput from '../components/ETrackInput'
import ImageSwitch from '../components/ImageSwitch.vue'

export const assemblyImageBasePath = '/images/Elektroschiene/Montage'

export default {
  name: 'e-track-assembly',

  components: {
    CeilingBracketImage,
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ETrackInput,
    ImageSwitch,
  },

  data () {
    return {
      assemblyImageBasePath,
      enteredWallDistance: null,
      isLoading: false,
      tempPreview: null,
      wallDistanceConfirmed: false,
      inputKey: Date.now(),
    }
  },

  computed: {
    articleConfig () {
      return this.$store.state.etrack.articleConfig
    },

    /**
     * We have to pick the items to choose from based on the previously chosen
     * profile and color.
     *
     * @returns {array}
     */
    assemblyItems () {
      const { color, profil } = this.configuration

      return color && profil
        ? profil.Colors.find(({ ColorNumber }) => ColorNumber === color.ColorNumber)?.Mounts || []
        : []
    },

    /**
     * Currently chosen assembly-type.
     *
     * @returns {object?}
     */
    chosenAssembly () {
      return this.configuration.assembly
    },

    /**
     * Currently chosen bracket (option of an assembly-variant).
     *
     * @returns {object?}
     */
    chosenBracket () {
      return this.configuration.bracket
    },

    /**
     * Current configuration/choices of the user.
     *
     * @returns {object?}
     */
    configuration () {
      return this.$store.state.etrack.configuration
    },

    measureUnit () {
      return this.$store.state.session.catalog.measureUnit
    },

    /**
     * Users must select a bracket to continue to the next step. If it's a
     * ceiling-bracket they must confirm a dialog additionally.
     *
     * @returns {boolean}
     */
    nextStepAllowed () {
      if (this.chosenBracket === null || this.chosenAssembly === null) {
        return false
      }

      return this.chosenAssembly.IsCeiling
        ? this.wallDistanceConfirmed
        : true
    },

    /**
     * If the user has chosen a ceiling-assembly there are bounds for the wall-
     * distance defined by the api. But there may be cases where the minimum is
     * not defined - then we have to use a fixed value defined by the specs
     * from an excel-sheet..
     *
     * @returns {object}
     */
    wallDistanceBounds () {
      const { MinWallDistance: min, MaxWallDistance: max } = this.articleConfig || {}
      return { min: min || 10, max: max || 30 }
    },

    /**
     * When a ceiling-assembly is chosen we have to show an input so the user
     * is able to enter a wall-distance (otherwise the walldistance is given by
     * the bracket).
     *
     * @returns {boolean}
     */
    showWallDistanceElements () {
      return this.articleConfig && // api-call completed -> we've got an article-config (with mix-/max-values)
        this.configuration.assembly && // assembly was set within the client
        this.configuration.assembly.IsCeiling // currently chosen assembly is a ceiling-variant
    },
  },

  watch: {
    articleConfig () {
      this.articleConfig && (this.enteredWallDistance = this.articleConfig.WallDistance)
    },
  },

  methods: {
    /**
     * Checks if the given assembly is currently chosen. Those entries don't
     * have an ID, so we have to use the ceiling-information.
     *
     * @param {object} assembly
     * @returns {boolean}
     */
    assemblyIsChosen (assembly) {
      return this.chosenAssembly && this.chosenAssembly.IsCeiling === assembly.IsCeiling
    },

    /**
     * Checks if the given option of an assembly is currently chosen.
     *
     * @param {object} option
     * @returns {boolean}
     */
    optionIsChosen (option) {
      return this.chosenBracket && this.chosenBracket.BracketArticleNumber === option.BracketArticleNumber
    },

    /**
     * When the user chooses a bracket, we're finally able to get an article-
     * configuration from the API.
     *
     * Since the following steps depend on this one we have to reset the
     * current configuration (but keep the choices of the previously ones).
     *
     * @param {object} bracket Chosen bracket
     * @param {object} assembly Assembly related to the bracket
     * @returns {Promise}
     */
    async onChooseBracket (bracket, assembly) {
      this.wallDistanceConfirmed = false

      if (this.chosenBracket && this.chosenBracket.BracketArticleNumber === bracket.BracketArticleNumber) {
        return
      }

      const { color, profil, type, typeHintConfirmed, wallDistance } = { ...this.configuration }

      this.wallDistanceConfirmed = false
      this.$store.commit('etrack/reset')
      this.$store.commit('etrack/setConfiguration', {
        assembly,
        bracket,
        color,
        profil,
        type,
        typeHintConfirmed,
        wallDistance,
      })

      this.isLoading = true
      await this.$store.dispatch('etrack/loadArticleConfig')
      this.isLoading = false
    },

    async setWallDistance (value) {
      const { min, max } = this.wallDistanceBounds
      let distance = parseFloat(value);

      (isNaN(distance) || distance < min) && (distance = min)
      max !== null && distance > max && (distance = max)

      this.isLoading = true
      this.$store.commit('etrack/setConfigEntry', { key: 'wallDistance', value: distance })

      await this.$store.dispatch('etrack/postArticleConfig', {
        Key: 'WallDistance',
        Value: distance.toString().replace('.', ',')
      })

      // Force input-update even if the internal/api value hasn't changed (e.g.
      // when the entered number is below the minimum). Otherwise the view
      // wouldn't match the data.
      this.inputKey = Date.now()
      this.isLoading = false
      this.wallDistanceConfirmed = true
    },

    /**
     * Finds the assembly related to the given bracket (a bracket is a child of
     * an assembly-dataset).
     *
     * @param {object} bracket
     * @returns {object}
     */
    assemblyByBracket (bracket) {
      return this.assemblyItems.find(({ Options }) =>
        Options.find(({ BracketArticleNumber }) => BracketArticleNumber === bracket.BracketArticleNumber)
      )
    },

    /**
     * Checks if the given assembly is active or an option of that gets hovered
     * so we're able to highlight elemens based on that status.
     *
     * @param {object} assembly
     * @returns {boolean}
     */
    assemblyIsActive (assembly) {
      const isChosen = this.chosenAssembly && this.chosenAssembly.IsCeiling === assembly.IsCeiling
      const isHovered = this.tempPreview && this.assemblyByBracket(this.tempPreview).IsCeiling === assembly.IsCeiling

      return isChosen || isHovered
    },
  },
}
</script>

<style lang="scss">
  @import '../styles/variables.scss';

  .e-track-assembly--wrap {
    .arrow-divider {
      position: relative;
      height: 4px;
      background-color: #b3b3b3;

      &::after {
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-style: solid;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-top: 17px solid #b3b3b3;
        border-bottom: 0;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &.is--active {
        background-color: #000;

        &::after {
          border-top-color: #000;
        }
      }
    }

    .assembly-col {
      &:nth-child(1) {
        border-right: 3px solid #ccc;;
      }
    }

    .option-box {
      font-size: 1.25rem;

      .option-label {
        flex: 1 0 100px;
        margin: 0 10px 0 0;
        justify-content: flex-end;
      }

      img {
        min-width: 0;

        &.wall-bracket-image {
          max-height: 7vh;
        }
      }
    }

    .e-track-card--wrap {
      flex: 1;

      .card-content {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  @media (max-width: ($customBreakpointMd - 1px)) {
    .e-track-assembly--wrap {
      &.wall-distance-dialog-active {
        .custom-content-grid {
          .col-content {
            justify-content: flex-start;
          }
        }
      }
    }
  }
</style>
