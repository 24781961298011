import _ from 'underscore'

export default {
  namespaced: true,
  state: {
    list: {
      sets: [],
      parts: [],
    },
    isActive: false,
  },
  getters: {
    findSet: state => query => _.findWhere(state.list.sets, query),
    findPart: state => query => _.findWhere(state.list.parts, query),
  },
  mutations: {
    /**
     * Enthält die favorisierten Artikel des Benutzers.
     *
     * @param {*} state
     * @param {*[]} list
     */
    set (state, list) {
      state.list.sets.splice(0, state.list.sets.length, ...list.sets)
      state.list.parts.splice(0, state.list.parts.length, ...list.parts)
    },

    /**
     * @param state
     * @param favorite
     */
    add (state, favorite) {
      const index = _.findIndex(state.list.sets, { _id: favorite._id })
      if (index === -1) {
        state.list.sets.push(favorite)
      } else {
        Object.assign(state.list.sets[index], favorite)
      }
    },

    /**
     * @param state
     * @param favorite
     */
    addPart (state, favorite) {
      const index = _.findIndex(state.list.parts, { _id: favorite._id })
      if (index === -1) {
        state.list.parts.push(favorite)
      } else {
        Object.assign(state.list.parts[index], favorite)
      }
    },

    /**
     * Removes a favorite from the current list.
     *
     * @param {*} state
     * @param {*} favorite
     */
    removeSet (state, favorite) {
      const index = _.findIndex(state.list.sets, { _id: favorite._id })

      if (index !== -1) {
        state.list.sets.splice(index, 1)
      }
    },

    /**
     * Removes a favorite from the current list.
     *
     * @param {*} state
     * @param {*} favorite
     */
    removePart (state, favorite) {
      const index = _.findIndex(state.list.parts, { _id: favorite._id })

      if (index !== -1) {
        state.list.parts.splice(index, 1)
      }
    },

    /**
     * Gibt an ob die Favoritenliste geöffnet oder geschlossen ist.
     *
     * @param {*} state
     * @param {boolean} isActive
     */
    setIsActive (state, isActive) {
      state.isActive = isActive
    },
  },
  actions: {
  },
}
