<template>
  <e-track-dialog
    v-if="additionalParts.length > 0"
    :visible="showAdditionalPartChoice"
    :title="$t('etrack.additionalOptions')"
    enforce-confirmation
    light
    class="additional-parts-choice--wrap"
  >
    <div
      v-for="part in additionalParts"
      :key="part.id"
      class="row d-flex flex-wrap mt-5 mb-5"
    >
      <div class="col-md-12 col-lg-3 d-flex justify-center">
        <img
          :src="`/images/${part.Picture}`"
          class="img-responsive"
        >
      </div>
      <div class="col-md-8 col-lg-6 d-flex flex-column justify-center">
        <div>
          {{ part.ArticleName }}
        </div>
        <div class="text-subtitle">
          ({{ part.Colors[0].Value }}) <br>
          {{ part.Price.toFixed(2) }} {{ currency }} {{ $t('etrack.perUnit') }}
        </div>
      </div>
      <div class="col-md-4 col-lg-3 d-flex flex-column justify-center">
        <e-track-input
          v-model.number="part.count"
          text
          custom-spinner
          :label="$t('etrack.amount')"
          class="ml-auto"
        />
      </div>
    </div>

    <template #footer>
      <e-track-btn
        :text="$t('general.ok')"
        :loading="loading"
        class="ml-auto"
        @click.native="chooseAdditionalParts"
      />
    </template>
  </e-track-dialog>
</template>

<script>
import ETrackBtn from '../components/ETrackBtn.vue'
import ETrackDialog from '../components/ETrackDialog.vue'
import ETrackInput from '../components/ETrackInput.vue'

export default {
  name: 'additional-parts-choice',

  components: {
    ETrackBtn,
    ETrackDialog,
    ETrackInput,
  },

  props: {
    additionalParts: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showAdditionalPartChoice: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currency () {
      return this.$store.state.session.catalog.currency
    },
  },

  methods: {
    /**
     * Some engines may have additional parts to choose from. Those get shown
     * within a dialog where the user has the possibility to set the amounts.
     * When the dialog gets closed, we have to communicate the choices to our
     * api.
     *
     * @returns {Promise}
     */
    async chooseAdditionalParts () {
      this.$emit('choice')

      for (const { ArticleNumber, count, Colors } of this.additionalParts) {
        await this.$store.dispatch('etrack/putPart', {
          setArticleNo: ArticleNumber,
          color: Colors[0].Key,
          count,
        })
      }

      this.$emit('chosen')
    },
  },
}
</script>

<style lang="scss">
  .additional-parts-choice--wrap {
    img {
      width: 10vh;
    }

    .row:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    .e-track-input--wrap {
      max-width: 100%;
    }
  }
</style>
