<template>
  <div class="price-box--wrap">
    {{ price }}
  </div>
</template>

<script>
export default {
  name: 'price-box',

  computed: {
    currency () {
      return this.$store.state.session.catalog.currency
    },

    /**
     * Formats the price of the current article-configuration so it's readable
     * by the user.
     *
     * @returns {string}
     */
    price () {
      return parseFloat(this.$store.state.etrack.articleConfig.TotalPrice)
        .toFixed(2)
        .replace('.', ',')
        .concat(` ${this.currency}`)
    }
  },
}
</script>

<style lang="scss">
  .price-box--wrap {
    font-family: $fontBold;
    font-weight: 700;
  }
</style>
