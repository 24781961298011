<template>
  <div
    class="mik-qr--wrap"
    :class="classes"
  >
    <div class="qr-info--wrap">
      {{ $t('pageNavigation.mikQr.title') }}

      <info-icon
        :info-body="$t('pageNavigation.mikQr.popoverHint')"
        info-container="body"
        info-direction="top"
      />
    </div>

    <div class="qr-code--wrap">
      <img
        :src="require('/public/gfx/mik_qrcode.svg')"
        class="img-responsive"
      >
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/directives/InfoIcon'

export default {
  name: 'mik-qr',

  components: {
    InfoIcon,
  },

  props: {
    // uses a dark border, icon to fit on light backgrounds
    dark: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes () {
      return {
        'is--dark': this.dark,
      }
    },
  }
}
</script>

<style lang="scss">
  .mik-qr--wrap {
    max-width: 90%;

    .qr-info--wrap {
      margin: 20px 0;
      padding: 8px;
      background-color: $navbarBgColor;
      border-radius: 4px;
      border: 2px solid #fff;
      color: #fff;
      font-size: 16px;
      text-align: center;
      text-transform: none;
      z-index: 1;
      position: relative;

      .infoIcon.material-icons {
        position: absolute;
        top: -20px;
        right: -20px;
        z-index: 2;
        color: #fff;
        font-size: 32px;
      }
    }

    .qr-code--wrap {
      width: 130px;
      margin: 0 auto;
      border-radius: 4px;
      border: 2px solid #fff;

      img {
        border: 8px solid #fff;
      }
    }


    &.is--dark {
      .qr-info--wrap {
        .infoIcon.material-icons {
          color: $navbarBgColor;
        }
      }

      .qr-code--wrap {
        border-color: $navbarBgColor;
      }
    }
  }
</style>
