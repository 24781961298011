<template>
  <div class="progress-circular--wrap">
    <icon icon="sync" />
  </div>
</template>

<script>
export default {
  name: 'progress-circular',
}
</script>

<style lang="scss">
  .progress-circular--wrap {
    display: flex;
    align-items: center;

    .material-icons {
      animation: icon-loader 1s infinite;
    }

    @keyframes icon-loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
</style>
