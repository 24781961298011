<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'App',
  mounted() {
    if (this.isTouch) {
      $('body').addClass('isTouch')
    } else {
      $('body').removeClass('isTouch')
    }
  },
  computed: {
    isTouch() {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      )
    },
  },
  methods: {},
}
</script>

<style lang="scss">
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'swiper/css';
@import 'swiper/css/autoplay';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'material-icons/iconfont/material-icons.css';
</style>
