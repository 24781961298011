<template>
  <div class="confirm-overlay--wrap">
    <div class="confirm-overlay-content">
      <slot />
    </div>

    <div class="confirm-overlay-actions d-flex flex-column">
      <slot name="actions">
        <e-track-btn
          :text="$t('etrack.confirm')"
          class="ml-auto"
          :loading="loading"
          :class="{
            confirmed: value
          }"
          @click.native="$emit('input', true)"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import ETrackBtn from '../components/ETrackBtn'

export default {
  name: 'confirm-overlay',

  components: {
    ETrackBtn,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .confirm-overlay--wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;

    .confirm-overlay-content {
      margin: auto;
    }

    .confirm-overlay-actions {
      .e-track-btn--wrap {
        border-right: 5px solid #ccc;

        &.confirmed {
          border-right-color: #4caf50;
        }
      }
    }
  }
</style>
