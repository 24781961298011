import Vue from "vue";
import VueI18n from "vue-i18n";
import uivLocale from 'uiv/src/locale/lang/de-DE'
import moment from 'moment'
import http from '@/utilities/http'

Vue.use(VueI18n);

moment.locale('DE')

const languages = ["de"]

async function loadLocaleMessages() {
  const data = await Promise.all(languages.map((key) => {
    return http.getJSON(`/locales/${key}.json`)
  }))
  const list = data.reduce((acc, messages, index) => {
    return {
      ...acc,
      [languages[index]]: messages
    }
  }, {})

  list.de = { ...list.de, ...uivLocale }

  return list;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "de",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
  messages: {},
});

loadLocaleMessages().then(messages => {
  languages.map(language => language).forEach(language => {
    i18n.setLocaleMessage(language, messages[language])
  })

  i18n.locale = 'de'
  document.querySelector('html').setAttribute('lang', 'de')
})

export default i18n
