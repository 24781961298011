<template>
  <div class="ceiling-bracket-image--wrap">
    <div class="dimension-choice">
      <!--
        Special-case for ceiling-bracket-images: Those depend on the
        previously chosen geometry - but that information is not
        available within the REST-catalog. So we have to build a
        combined filename on our own.
      -->
      <img
        :src="`/images/Elektroschiene/Montage/${bracket}_${geometry}.svg`"
        class="img-responsive"
      >

      <div v-if="$slots.default" class="dimension-indicator--wrap">
        <img src="/images/Elektroschiene/Montage/bem_links.png">
        <div class="d-flex flex-auto">
          <slot />
        </div>
        <img src="/images/Elektroschiene/Montage/bem_rechts.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ceiling-bracket-image',

  props: {
    // article-nr of the bracket
    bracket: {
      type: String,
      default: null,
    },

    // geometry to use
    geometry: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
  @import '../styles/variables.scss';

  .ceiling-bracket-image--wrap {
    .dimension-indicator--wrap {
      display: flex;
      align-items: flex-end;
      margin: 0 4.75% 0 17%;

      img {
        max-width: 20px;
      }

      > div {
        min-width: 0;
      }

      .e-track-input--wrap {
        .input-wrap {
          .custom-spinner + input {
            padding-left: 0;
          }
        }

        .input-affix {
          padding: 0;
        }
      }
    }
  }

  @media (min-width: $customBreakpointMd) {
    .ceiling-bracket-image--wrap {
      .dimension-indicator--wrap {
        margin: 0 3.25% 0 16%;

        img {
          max-width: 30px;
        }
      }
    }
  }
</style>
