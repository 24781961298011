<template>
  <div
    v-if="loading"
    class="area-loader--wrap"
  />
</template>

<script>
export default {
  name: 'area-loader',

  props: {
    loading: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss">
  .area-loader--wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: progress;
    pointer-events: none;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0));
      animation: loading 2.2s infinite ease-in-out;
      height: 100%;
      transform: translateX(-100%);
    }

    @keyframes loading {
      100% {
        transform: translateX(100%)
      }
    }
  }
</style>
