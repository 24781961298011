<template>
  <div>
    <div ref="backdrop" class="saltModalBackdrop" style="display: none" @click="hide()"></div>

    <div class="saltModal" :class="modalClass + ' alert-' + type" tabindex="-1" role="dialog" ref="modal" style="display: none;">
      <div class="modal-header">
        <slot name="header">
          <button type="button" class="btn close" @click="hide()">
            <i class="material-icons" title="Schließen">clear</i>
          </button>
          <h4 class="modal-title">{{ title }}</h4>
        </slot>
      </div>

      <div class="modal-body" :class="bodyClass">
        <slot name="body">
          <p>{{ message }}</p>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
          <button type="button" class="btn btn-danger pull-left" @click="hide()">
            {{ $t('general.cancel') }}
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'salt-modal',
  props: {
    type: { type: String, default: 'info', },
    target: { type: String, default: 'self', },
    title: { type: String, default: null, },
    message: { type: String, default: '', },
    modalClass: { type: String, default: '' },
    bodyClass: {  type: String, default: '' },
    hideAfter: { type: Number, default: null, },
    hideOnEsc: { type: Boolean, default: true, },
  },
  data () {
    return {
      isModalVisible: false,
      isHideLocked: false,
    }
  },
  mounted () {
    if (this.target !== 'self') {
      const $target = document.querySelector(this.target)
      $target.appendChild(this.$el)
    }
  },
  computed: {
    /**
     * @return {JQuery} modal-element
     */
    $modal () {
      return $(this.$refs.modal)
    },
    /**
     * @return {JQuery} backdrop-element
     */
    $backdrop () {
      return $(this.$refs.backdrop)
    },
    /**
     * @return {number} height of the modal + surrounding space
     */
    modalHeight () {
      return this.$modal.height() + 250
    },
  },
  methods: {
    /**
     * Zeige das modale Fenster
     *
     * @param {function} onShown - Funktion nach erfolgreichen zeigen
     * @return {void}
     */
    show (onShown) {
      this.isModalVisible = true
      this.isHideLocked = true

      this.$modal.css({
        top: -this.modalHeight,
      }).show(() => {
        this.$backdrop.fadeIn()
        this.$modal.css({
          left: ($(window).width() - this.$modal.outerWidth()) / 2,
          top: this.$modal.outerWidth() * -1
        }).animate({
          top: ($(window).height() - this.$modal.outerHeight()) / 2
        }, {
          done: () => {
            this.isHideLocked = false
            $(document).on('keyup.hideModal', event => {
              if (this.hideOnEsc && event.key === 'Escape') {
                this.hide()
              }
            })

            if (this.hideAfter !== null) {
              setTimeout(() => {
                this.hide();
              }, this.hideAfter);
            }

            this.$emit('shown')

            if (typeof onShown === 'function') {
              onShown()
            }
          }
        })
      })
    },

    /**
     * Verstecke das modale Fenster
     *
     * @param {function} onHidden - Funktion nach erfolgreichen verstecken
     * @return {void}
     */
    hide (onHidden) {
      this.$backdrop.fadeOut()
      this.isHideLocked = true

      this.$modal.animate({
        top: -this.modalHeight,
      }, {
        done: () => {
          this.isHideLocked = false
          this.isModalVisible = false
          this.$emit('hidden')
          $(document).off('keyup.hideModal')

          if (typeof onHidden === 'function') {
            onHidden()
          }
        }
      })
    },
  },
}
</script>
