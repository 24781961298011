import http from '@/utilities/http'

export default {
  namespaced: true,
  state: {
    languageList: [],
  },
  getters: {
    languageLoaded (state) {
      return Object.keys(state.translation || {}).length > 0
    },
  },
  mutations: {
    update (state, { language, translation }) {
      state.language = language
      state.translation = translation
    },

    setLanguageList (state, languageList) {
      state.languageList = languageList
    }
  },
  actions: {
    async loadList (context) {
      const languageList = await http.getJSON('/language')
      context.commit('setLanguageList', languageList)
    },
  }
}
