<template>
  <default-page-layout
    :heading="$t('etrack.endPieces')"
    :loading="isLoading"
    class="e-track-end-pieces--wrap"
  >
    <image-switch
      :default="`${wallMountsImageBasePath}/prev.jpg`"
      :primary="tempPreview ? tempPreview.preview : null"
      :secondary="chosenOption ? chosenOption.preview : null"
      height-aware
    />

    <template #aside>
      <div class="position-relative">
        <div
          class="row d-flex flex-wrap"
          :class="{
            'cross-underlay': options.length <= 4
          }"
        >
          <div
            v-for="(option, i) in options"
            :key="option.id"
            class="col-sm-6 d-flex flex-auto"
          >
            <e-track-card
              :is-chosen="chosenOption && chosenOption.id === option.id"
              :loading="isLoading"
              :left-indicator="i % 2 === 0"
              class="flex-auto"
              @mouseenter.native="tempPreview = option"
              @mouseleave.native="tempPreview = null"
              @click="setOption(option)"
            >
              <template #title>
                <div :class="`text-${option.align}`">
                  {{ option.title }}
                </div>
              </template>
              <img :src="option.image" class="img-responsive preview-image">
            </e-track-card>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackAssembly"
        :text="$t('etrack.prev')"
      />

      <e-track-btn
        to="ETrackCurtainEngine"
        :disabled="chosenOption === null"
        :tooltip="chosenOption === null ? $t('etrack.choiceNeeded') : null"
        :loading="isLoading"
        :text="$t('etrack.next')"
        class="ml-auto"
      />
    </template>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ImageSwitch from '../components/ImageSwitch.vue'

const wallMountsImageBasePath = '/images/Elektroschiene/WallMounts'

export default {
  name: 'e-track-end-pieces',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ImageSwitch,
  },

  data () {
    return {
      isLoading: false,
      tempPreview: null,
      wallMountsImageBasePath,
    }
  },

  computed: {
    options () {
      const mounts = this.$store.state.etrack.articleConfig?.PossibleWallMounts || []
      const alignById = { wal: 'left', war: 'right', frei: 'center', ww: 'center' }

      return mounts.map(id => ({
        id,
        title: this.$t(`etrack.wallMounts.${id}`),
        image: `${wallMountsImageBasePath}/${id}.jpg`,
        preview: `${wallMountsImageBasePath}/prev_${id}.jpg`,
        align: alignById[id],
      }))
    },

    chosenOption () {
      return this.$store.state.etrack.configuration.endPieces
    },
  },

  methods: {
    /**
     * Stores the given option as the chosen one and communicates the change to
     * the API.
     *
     * @param {object} option
     * @returns {Promise}
     */
    async setOption (option) {
      this.isLoading = true
      const res = await this.$store.dispatch('etrack/postArticleConfig', { Key: 'WallStop', Value: option.id })

      if (res.ReturnCode === 'Ok') {
        this.$store.commit('etrack/setConfigEntry', { key: 'endPieces', value: { ...option } })
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
  .e-track-end-pieces--wrap {
    .e-track-card--wrap {
      .card-title {
        margin: 0;
      }

      .card-content {
        padding: 0;

        .preview-image {
          margin: 0 auto;
        }
      }
    }
  }
</style>
