<template>
  <i class="infoIcon material-icons" :class="infoClass">info</i>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'info-icon',
  data () {
    return {
      template: `
<div class="infoIcon popover" role="tooltip">
  <div class="arrow"></div>
  <h3 class="popover-title"></h3>
  <div class="popover-content"></div>
</div>`
    }
  },
  props: {
    infoTitle: {
      type: String,
    },
    infoBody: {
      type: String,
    },
    infoClass: {
      type: String,
    },
    infoDirection: {
      type: String,
      default: 'top',
    },
    infoContainer: {
      default: false,
    },
  },
  mounted () {
    $(this.$el).popover({
      trigger: 'hover',
      title: this.infoTitle,
      content: this.infoBody,
      placement: this.infoDirection,
      template: this.template,
      container: this.infoContainer,
    })
  },
}
</script>

<style lang="scss">
</style>
