<template>
  <component :is="tag" :class="cls" @click="$emit('click', $event)"><slot>{{ text }}</slot></component>
</template>

<script>

const IconSets = Object.freeze({
  mdi: {
    tag: 'i',
    cls () { return 'material-icons' },
    text () { return this.icon },
  },
  ico: {
    tag: 'span',
    cls () { return 'ico ico_' + this.icon },
  },
  glyph: {
    tag: 'i',
    cls () { return 'glyphicon glyphicon-' + this.icon }
  },
  svg: {
    tag: 'span',
    cls () { return 'svgIcon' }
  }
})

export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    set: {
      type: String,
      default: 'mdi',
      validate: set => set in IconSets
    }
  },
  computed: {
    type () {
      return IconSets[this.set]
    },
    tag () {
      return this.type.tag
    },
    cls () {
      return this.type.cls.apply(this)
    },
    text () {
      return this.type.text?.apply(this)
    }
  }
}
</script>
