<template>
  <div
    class="e-track-card--wrap d-flex flex-column"
    @click="$emit('click', $event)"
  >
    <div
      class="e-track-card flex-auto"
      :class="{
        'is--clickable': $listeners.click,
        'is--chosen': isChosen,
        'has--choice-indicator': $listeners.click || isChosen,
        'left-indicator': leftIndicator,
      }"
    >
      <div class="card-title">
        <slot name="prependTitle" />
        <slot name="title">
          {{ title }}
        </slot>
        <slot name="appendTitle" />
      </div>

      <div
        v-if="$slots.default"
        class="card-content"
      >
        <slot />
      </div>

      <div v-if="$slots.footer" class="card-footer">
        <slot name="footer" />
      </div>
    </div>

    <area-loader :loading="loading" />
  </div>
</template>

<script>
import AreaLoader from './AreaLoader'

export default {
  name: 'e-track-card',

  components: {
    AreaLoader,
  },

  props: {
    // highlights the card
    isChosen: {
      type: Boolean,
      default: false,
    },

    // disables interaction, shows a loading-indicator
    loading: {
      type: Boolean,
      default: false,
    },

    // text to show within the header
    title: {
      type: String,
      default: null,
    },

    // moves the 'active'-indicator to the left side
    leftIndicator: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .e-track-card--wrap {
    position: relative;

    .e-track-card {
      flex-direction: column;
      transition: all 0.18s cubic-bezier(.4,0,.2,1);
      border-right: 2px solid transparent;
      display: flex;

      &.is--clickable {
        cursor: pointer;
      }

      .card-title {
        margin-bottom: 16px;
      }

      .card-content {
        flex: 1;
      }

      .card-content,
      .card-footer {
        padding: 8px;
      }
    }
  }

  @media (min-width: $screen-lg) {
    .e-track-card--wrap {
      .e-track-card {
        .card-content,
        .card-footer {
          padding: 12px;
        }
      }
    }
  }
</style>
