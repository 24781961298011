<template>
  <div
    v-if="visible"
    class="summary-dialog--wrap"
  >
    <div
      class="material-icons close-summary"
      @click="$emit('close')"
    >
      close
    </div>

    <div class="summary-dialog--content d-flex flex-column align-center justify-center flex-auto">
      <template v-if="value || image">
        <h3 class="mb-10">{{ value }}</h3>
        <img :src="image" class="modal-image img-responsive">
      </template>

      <template v-if="values.length > 0">
        <div class="d-flex align-center justify-center flex-auto">
          <div
            v-for="({ value: entry, image: preview }, i) in values"
            :key="i"
            class="pl-4 pr-4"
          >
            <h3 class="mb-10">{{ entry }}</h3>
            <img :src="preview" class="modal-image img-responsive">
          </div>
        </div>
      </template>
    </div>

    <div class="summary-dialog--footer d-flex">
      <e-track-btn
        :text="$t('etrack.change')"
        :to="route"
        class="ml-auto"
      />

      <e-track-btn
        :text="$t('etrack.close')"
        @click.native="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import ETrackBtn from '../../components/ETrackBtn'

export default {
  name: 'e-track-summary-dialog',

  components: {
    ETrackBtn,
  },

  props: {
    image: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    values: {
      type: Array,
      default: () => ([]),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .summary-dialog--wrap {
    position: absolute;
    top: 5%;
    bottom: 5%;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;

    .close-summary {
      position: absolute;
      right: 30px;
      top: 30px;
      font-size: 2rem;
      cursor: pointer;
    }

    .summary-dialog--content {
      border-bottom: 3px solid #ccc;
      padding: 30px;

      img {
        max-height: 35vh;
      }
    }

    .summary-dialog--footer {
      border-top: 3px solid #ccc;
      margin-top: 3px;
      padding: 15px 30px;
    }
  }
</style>
