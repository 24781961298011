<template>
  <default-page-layout
    :heading="`${$t('etrack.curtainCalculation.title')}: ${configuration.confectionForm.title}`"
    class="e-track-curtain-calculation-index--wrap"
  >
    <div class="preview-image--wrap position-relative">
      <div
        v-if="configuration.confectionForm.id === 'W'"
        class="addition-choice--wrap d-flex align-center justify-center"
      >
        <label class="additionChoice">
          {{ $t('etrack.curtainCalculation.additionHint') }}
          <select
            v-model="chosenAddition"
            class="form-control"
          >
            <option
              v-for="(option, i) in additionOptions"
              :key="i"
              :value="option"
              :selected="chosenAddition && option.addition === chosenAddition.addition"
            >
              {{ $t('etrack.curtainCalculation.additionOptionPrefix') }}{{ option.addition.toString().replace('.', ',') }}
            </option>
          </select>
        </label>
      </div>

      <img :src="curtainPreviewImage" class="img-responsive">

      <div
        class="calculation-values"
        :class="{
          'only-left': calculations.left && !calculations.right,
          'only-right': calculations.right && !calculations.left,
        }"
      >
        <div v-if="calculations.left" class="package left">
          <div
            class="addition"
            :class="{
              'invisible': !calculations.left.widening
            }"
          >
            <div class="top">
              +<span class="visible-lg-inline">&nbsp;</span>{{ calculations.left.widening }}<span class="visible-lg-inline">&nbsp;</span>{{ measureUnit }}
            </div>
            <div class="bottom">
              {{ $t('etrack.curtainCalculation.calculationHints.plus') }} <br>
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.retoure`) }}
              <div class="appendix">
                {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.retoureHint`) }}
              </div>
            </div>
          </div>
          <div class="curtain">
            <div class="top">
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.sewingWidthPrefix`) }} {{ calculations.left.sewingWidth }} {{ measureUnit }}
              <span v-if="calculations.left.gliderAmount">
                ({{ calculations.left.gliderAmount }} {{ $t('etrack.glider') }})
              </span>
            </div>
            <div class="bottom">
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.leftSide`) }} <br>
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.sideHintA`) }}
              <div class="appendix">
                {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.sideHintB`) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="calculations.right" class="package right">
          <div class="curtain">
            <div class="top">
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.sewingWidthPrefix`) }} {{ calculations.right.sewingWidth }} {{ measureUnit }}
              <span v-if="calculations.right.gliderAmount">
                ({{ calculations.right.gliderAmount }} {{ $t('etrack.glider') }})
              </span>
            </div>
            <div class="bottom">
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.rightSide`) }} <br>
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.sideHintA`) }}
              <div class="appendix">
                {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.sideHintB`) }}
              </div>
            </div>
          </div>
          <div
            class="addition"
            :class="{
              'invisible': !calculations.right.widening
            }"
          >
            <div class="top">
              +<span class="visible-lg-inline">&nbsp;</span>{{ calculations.right.widening }}<span class="visible-lg-inline">&nbsp;</span>{{ measureUnit }}
            </div>
            <div class="bottom">
              {{ $t('etrack.curtainCalculation.calculationHints.plus') }} <br>
              {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.retoure`) }}
              <div class="appendix">
                {{ $t(`etrack.curtainCalculation.calculationHints.${configuration.confectionForm.id}.retoureHint`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #aside>
      <template v-if="configuration.endPieces.id !== 'ww'">
        <h3 class="mt-0 mb-6">
          {{ $t('etrack.lateralOverhang') }}
        </h3>

        <img
          :src="require(`../../assets/retoure_${configuration.confectionForm.id}.jpg`)"
          class="overhang-image img-responsive mb-6"
        >
      </template>

      <ribbon-height-hint />
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackSummary"
        :text="$t('etrack.prev')"
      />

      <div class="mx-auto">
        <price-box />
      </div>

      <e-track-btn
        to="ETrackFinish"
        :text="$t('etrack.next')"
      />
    </template>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../../components/DefaultPageLayout'
import ETrackBtn from '../../components/ETrackBtn'
import PriceBox from '../../components/PriceBox'
import RibbonHeightHint from './RibbonHeightHint'

import curtainCalculation from '../../services/curtainCalculation.js'

const additionOptions = [
  { addition: 1.5, overhang: 4 },
  { addition: 1.75, overhang: 6 },
  { addition: 2, overhang: 6 },
  { addition: 2.25, overhang: 6 },
  { addition: 2.75, overhang: 8 },
  { addition: 3, overhang: 8 },
]

export default {
  name: 'curtain-calculation-index',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    PriceBox,
    RibbonHeightHint,
  },

  data () {
    return {
      additionOptions,
    }
  },

  computed: {
    articleConfig () {
      return this.$store.state.etrack.articleConfig
    },

    chosenAddition: {
      get () {
        return this.configuration.curtainAddition
      },
      set (value) {
        this.$store.commit('etrack/setConfigEntry', { key: 'curtainAddition', value })
      },
    },

    confections () {
      return curtainCalculation.getConfections()
    },

    configuration () {
      return this.$store.state.etrack.configuration
    },

    curtainPreviewImage () {
      return curtainCalculation.getPreviewImage()
    },

    calculations () {
      const { curly, pleat, wave } = curtainCalculation.calculations
      const calculationToUse = { Curly: curly, Pleat: pleat, W: wave }[this.configuration.confectionForm.id]

      return Object.keys(this.confections).reduce((result, side) => {
        const confection = this.confections[side]
        confection && (result[side] = calculationToUse(confection.isFreestanding, confection.isSplitted))
        return result
      }, {})
    },

    measureUnit () {
      return this.$store.state.session.catalog.measureUnit
    },
  },

  beforeMount () {
    if (this.chosenAddition === null) {
      this.chosenAddition = additionOptions.find(({ addition }) => addition === 2)
    }
  },

  mounted () {
    // users mustn't be able to skip the curtain-calculation
    this.$store.commit('etrack/setCurtainCalculationSeen')
  },
}
</script>

<style lang="scss">
  .e-track-curtain-calculation-index--wrap {
    .e-track-card--wrap {
      margin: 16px 0;

      .card-content {
        font-size: 0.75rem;
      }
    }

    .preview-image--wrap {
      max-width: 110vh;
      margin: 0 auto;

      .addition-choice--wrap {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .additionChoice {
          width: 130px;
          text-align: center;

          select {
            margin-top: 4px;
            border: 3px solid;
            padding: 8px 16px;
            font-size: 1rem;
          }
        }
      }
    }

    .overhang-image {
      max-height: 45vh;
      margin-right: auto;
    }

    .calculation-values {
      position: absolute;
      left: 9.75%;
      right: 9.75%;
      top: 81.85%;
      font-size: 1rem;
      text-align: center;
      display: flex;

      &.only-left {
        right: 17.9%;

        .package {
          .addition {
            flex: 0 0 11.25%;
            max-width: 11.25%;
          }
        }
      }

      &.only-right {
        left: 17.8%;
        right: 9.8%;

        .package {
          .addition {
            flex: 0 0 11.25%;
            max-width: 11.25%;
          }
        }
      }

      .package {
        display: flex;
        flex: 1;

        .curtain {
          flex: 1 0 auto;
        }

        .addition {
          flex: 0 0 20%;
          max-width: 20%;

          &.invisible {
            opacity: 0;
          }
        }

        .appendix {
          font-size: 0.75rem;
          white-space: nowrap;
          margin-top: 10px;
        }

        .top {
          margin-bottom: 20px;
        }

        .bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .e-track--index {
    .e-track-content--wrap {
      .default-page-layout--wrap {
        &.e-track-curtain-calculation-index--wrap {
          .main-content {
            .custom-content-grid {
              .col-content {
                flex: 0 0 75%;
                max-width: 75%;
              }
              .col-options {
                flex: 0 0 25%;
                max-width: 25%;
              }
            }
          }
        }
      }
    }
  }
</style>
