<template>
  <div class="form-group" :class="outerClass">
    <slot name="label" />
    <label v-if="label" :class="[horizontal ? 'col-sm-3' : '', 'control-label']" :for="forElement">{{ label }}</label>
    <div v-if="horizontal" class="col-sm-9"><slot /></div>
    <slot v-else />
    <span v-if="outMessage" class="help-block">{{ outMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'input-field',
  props: {
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
      validate: t => ['success', 'warning', 'error', 'danger', 'info', 'default'].includes(t)
    },
    size: {
      type: String,
      default: null,
      validate: t => ['sm', 'md', 'lg'].includes(t)
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    warning: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    forElement: {
      type: String,
      default: null,
    },
  },
  computed: {
    outMessage () {
      return this.error || this.warning || this.info || this.message
    },
    outerClass () {
      let { type, size, horizontal } = this
      if (this.error) {
        type = 'error'
      } else if (this.warning) {
        type = 'warning'
      } else if (this.info) {
        type = 'info'
      } else if (type === 'danger') {
        type = 'error'
      }

      return [
        horizontal ? 'is-horizontal' : '',
        size ? 'form-group-' + size : '',
        type ? 'has-' + type + ' has-feedback' : '',
      ].join(' ').trim()
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  > .form-group, > .input-group:not(:last-child){
    margin-bottom: 0.5em;
  }
}
</style>
