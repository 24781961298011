<template>
  <default-page-layout
    class="e-track-type--wrap"
    :heading="$t('etrack.typeChoice')"
    :loading="isLoading"
  >
    <image-switch
      :default="`${typeImageBasePath}/unchosen.jpg`"
      :primary="tempPreview ? tempPreview.preview : null"
      :secondary="chosenType ? chosenType.preview : null"
      height-aware
      class="position-relative"
    >
      <confirm-overlay
        v-if="manualHintDialogVisible"
        :loading="isLoading"
        :value="typeHintConfirmed"
        class="text-center"
        @input="confirmManualHint"
      >
        <h3>
          {{ $t('etrack.types.manualHintHead') }}
        </h3>

        {{ $t('etrack.types.manualHint') }}
      </confirm-overlay>
    </image-switch>

    <template #aside>
      <div
        v-for="(type, i) in types"
        :key="i"
        class="type--wrap mx-auto"
      >
        <e-track-card
          :is-chosen="chosenType && type.id === chosenType.id"
          :loading="isLoading"
          @mouseenter.native="tempPreview = type"
          @mouseleave.native="tempPreview = null"
          @click="onTypeChoice(type)"
        >
          <template #title>
            <div class="d-flex align-center justify-center">
              {{ type.title }}
            </div>
          </template>

          <img :src="type.image" class="img-responsive preview-image">
        </e-track-card>
        <div v-if="i < types.length - 1" class="content-divider mt-6 mb-6" />
      </div>
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackIntro"
        :loading="isLoading"
        :text="$t('etrack.prev')"
      />

      <e-track-btn
        to="ETrackProfil"
        :disabled="!nextStepAllowed"
        :loading="isLoading"
        :tooltip="nextStepAllowed ? null : $t('etrack.choiceNeeded')"
        :text="$t('etrack.next')"
        class="ml-auto"
      />
    </template>
  </default-page-layout>
</template>

<script>
import ConfirmOverlay from '../components/ConfirmOverlay.vue'
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ImageSwitch from '../components/ImageSwitch.vue'

import http from '@/utilities/http'

const typeImageBasePath = '/images/Elektroschiene/TypeChoice'

export default {
  name: 'e-track-type',

  components: {
    ConfirmOverlay,
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ImageSwitch,
  },

  data () {
    return {
      isLoading: false,
      manualHintDialogVisible: false,
      tempPreview: null,
      typeImageBasePath,
    }
  },

  computed: {
    chosenType () {
      return this.$store.state.etrack.configuration.type
    },

    nextStepAllowed () {
      return this.chosenType && this.typeHintConfirmed
    },

    types () {
      return [
        {
          id: 'DriveTrainGeometries',
          title: this.$t('etrack.types.engine'),
          previewTitle: this.$t('etrack.types.engineTitle'),
          image: `${typeImageBasePath}/electric.svg`,
          preview: `${typeImageBasePath}/electric.jpg`,
        },
        {
          id: 'ManualGeometries',
          title: this.$t('etrack.types.manual'),
          previewTitle: this.$t('etrack.types.manualTitle'),
          image: `${typeImageBasePath}/manual.svg`,
          preview: `${typeImageBasePath}/manual.jpg`,
        },
      ]
    },

    typeHintConfirmed () {
      return this.$store.state.etrack.configuration.typeHintConfirmed
    },
  },

  mounted () {
    this.getArticleVariants()
  },

  methods: {
    /**
     * When the user confirms the hint about the manual type, we want to
     * remember the confirmation.
     *
     * @returns {undefined}
     */
    confirmManualHint () {
      this.$store.commit('etrack/setConfigEntry', { key: 'typeHintConfirmed', value: true })
    },

    /**
     * Loads available article-variants.
     *
     * @returns {undefined}
     */
    getArticleVariants () {
      this.isLoading = true

      http.getJSON('/api/electricset')
        .then(({ DriveTrainGeometries, ManualGeometries, ReturnCode }) => {
          if (ReturnCode === 'Ok') {
            this.$store.commit('etrack/setArticleVariants', { DriveTrainGeometries, ManualGeometries })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    isTypeManual (type) {
      return this.$store.getters['etrack/isTypeManual'](type)
    },

    /**
     * Sets the desired type.
     *
     * @param {object} type
     * @returns {undefined}
     */
    onTypeChoice (type) {
      // when there's already a chosen type (e.g. when switching back to this
      // step), we have to perform a reset since we have to get a new article
      // which makes previous choices invalid.
      if (this.chosenType && this.chosenType.id !== type.id) {
        this.$store.commit('etrack/reset')
      }

      this.$store.commit('etrack/setConfigEntry', { key: 'type', value: type })
      this.manualHintDialogVisible = false

      // when the user chooses a variant without an engine, we have to show a
      // hint which must get confirmed before switching to the next step is
      // allowed.
      if (this.isTypeManual(this.chosenType)) {
        this.$store.commit('etrack/setConfigEntry', { key: 'typeHintConfirmed', value: false })
        this.manualHintDialogVisible = true
        return
      }

      this.$store.commit('etrack/setConfigEntry', { key: 'typeHintConfirmed', value: true })
    },
  }
}
</script>

<style lang="scss">
  @import '../styles/variables.scss';

  .e-track-type--wrap {
    .type--wrap {
      width: 250px;

      .preview-image {
        margin: 15px auto;
        height: 120px;
        max-height: 10vh;
      }
    }

    .inline-overlay {
      > div {
        max-width: 650px;
      }
    }
  }

  @media (min-width: $customBreakpointMd) {
    .e-track-type--wrap {
      .type--wrap {
        .preview-image {
          height: 160px;
        }
      }
    }
  }
</style>
