<template>
  <div class="choice-indicator--wrap" :class="{ small }">
    <transition name="scale-in">
      <div
        v-if="isChosen"
        v-tooltip="$t('etrack.chosen')"
        class="choice-indicator d-flex align-center justify-center"
      >
        <span class="material-icons">
          check
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'choice-indicator',

  props: {
    isChosen: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .choice-indicator--wrap {
    .choice-indicator {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #4caf50;
      color: #fff;
    }

    &.small {
      .choice-indicator {
        width: 23px;
        height: 23px;

        .material-icons {
          font-size: 0.75rem;
        }
      }
    }
  }
</style>
