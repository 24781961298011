export default {
  default: {
    checked: false,
    id: '1',
    items: [
      {
        Key: '50',
        checked: false,
        Value: 'Weiß',
      }, {
        Key: '51',
        checked: false,
        Value: 'Anthrazit',
      }, {
        Key: '60',
        checked: false,
        Value: 'Messing',
      }, {
        Key: '61',
        checked: false,
        Value: 'Alt-Messing',
      }, {
        Key: '70',
        Keys: ['70', '71'],
        checked: false,
        Value: 'Chrom/Nickel',
      }, {
        Key: '72',
        checked: false,
        Value: 'Nickel matt',
      }, {
        Key: '79',
        checked: false,
        Value: 'Aluminium',
      }, {
        Key: '80',
        checked: false,
        Value: 'Bronziert',
      }, {
        Key: '56',
        checked: false,
        Value: 'Schwarz',
      }, {
        Key: '77',
        checked: false,
        Value: 'Edelstahl',
      }, {
        Key: '62',
        checked: false,
        Value: 'Messing matt',
      }, {
        Key: '81',
        checked: false,
        Value: 'Rost',
      },
    ],
  },

  metal: {
    title: 'Metall',
    checked: false,
    id: '1',
    items: [
      {
        Key: '69',
        checked: false,
        Value: 'Gold',
      }, {
        Key: '74',
        checked: false,
        Value: 'Eisen',
      }, {
        Key: '78',
        checked: false,
        Value: 'Aluminium poliert',
      }, {
        Key: '68',
        checked: false,
        Value: 'Silber',
      },
    ],
  },

  wood: {
    title: 'Holz',
    checked: false,
    id: '2',
    items: [
      {
        Key: '20',
        Keys: ['20', '23', '40', '43'],
        checked: false,
        Value: 'Buche',
      }, {
        Key: '46',
        Keys: ['46', '29'],
        checked: false,
        Value: 'Ahorn Natur',
      }, {
        Key: '22',
        checked: false,
        Value: 'Eiche rustikal',
      }, {
        Key: '21',
        Keys: ['21', '41'],
        checked: false,
        Value: 'Nussbaum',
      }, {
        Key: '25',
        Keys: ['25', '45'],
        checked: false,
        Value: 'Kirschbaum',
      }, {
        Key: '28',
        checked: false,
        Value: 'Wenge',
      }, {
        Key: '31',
        checked: false,
        Value: 'Kiefer',
      }, {
        Key: '35',
        checked: false,
        Value: 'Weiß lasiert',
      },
    ],
  },

  specials: {
    title: 'Besonderheiten',
    checked: false,
    id: '3',
    items: [
      {
        Key: '92',
        Keys: ['91', '92', '93', '98'],
        checked: false,
        Value: 'Kristallglas',
      }, {
        Key: '67',
        checked: false,
        Value: 'Messing patinert',
      }, {
        Key: '87',
        checked: false,
        Value: 'Blattgold',
      }, {
        Key: '86',
        checked: false,
        Value: 'Blattsilber',
      }, {
        Key: '88',
        checked: false,
        Value: 'Charmant vergoldet',
      }, {
        Key: '64',
        checked: false,
        Value: 'Kupfer',
      }
    ],
  },
}