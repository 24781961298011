import _ from 'underscore';

_.mixin({
  pickLike: function(obj, pattern) {
    var matchingKeys;
    // Da der IE 8 kein filter kennt, muss das hier durch
    // das filter von underscore laufen.
    matchingKeys = _.filter(_.keys(obj), function(key) {
      return pattern.test(key);
    });
    return _.pick(obj, matchingKeys);
  },
  diffObject: function(newValue, oldValue) {
    return _.reduce(newValue, (function(re, value, key) {
      if (!_.isEqual(value, oldValue[key])) {
        re[key] = value;
      }
      return re;
    }), {});
  }
});
