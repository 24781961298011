<template>
  <div
    v-if="showOverlay"
    class="configuration-forbidden-overlay--wrap"
  >
    <div class="configuration-forbidden-info">
      <h2>
        {{ etrack ? $t('configForbiddenOverlay.infoEtrack') : $t('configForbiddenOverlay.infoStandardSet') }}
      </h2>

      <h3>
        {{ $t('configForbiddenOverlay.infoSubline') }}
      </h3>
    </div>

    <router-link :to="{ name: 'Warenkorb' }">
      {{ $t('configForbiddenOverlay.goToCart') }}

      <icon icon="navigate_next" />
    </router-link>
  </div>
</template>

<script>
/**
 * When the user has an etrack-item in the cart, the configuration of standard-
 * sets isn't allowed - and vice versa.
 */
export default {
  name: 'configuration-forbidden-overlay',

  props: {
    // we're in the etrack-configurator
    etrack: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      cart: []
    }
  },

  computed: {
    showOverlay () {
      if (this.cart.length === 0) {
        return false
      }

      // we're in an etrack-view and the cart has a standard-item -> no configuration allowed
      if (this.etrack) {
        return this.cart.find(({ cartSet, cartParts }) =>
          (cartSet && !cartSet.IsElectricSet) || cartParts.find(({ IsElectricSet }) => !IsElectricSet) !== undefined
        )
      }

      // we're in a standard-view and there are etrack-items within the cart -> no configuration allowed
      return this.cart.find(({ cartSet, cartParts }) =>
        cartSet?.IsElectricSet || cartParts.find(({ IsElectricSet }) => IsElectricSet) !== undefined
      )
    },
  },

  mounted () {
    this.$store.dispatch('cart/get').then(cart => (this.cart = cart))
  },
}
</script>

<style lang="scss">
  .configuration-forbidden-overlay--wrap {
    position: fixed;
    z-index: 12;
    left: 0;
    top: $navBarHeight;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .configuration-forbidden-info {
      background-color: $darkInputBorderColor;
      color: $colDefault;
      padding: 30px 60px;
      margin: 0 0 30px 0;

      h2 {
        margin: 0 0 16px 0;
        text-shadow: none;
      }

      h3 {
        margin: 0;
      }
    }

    a {
      background-color: $darkInputBorderColor;
      color: $colDefault;
      text-decoration: none;
      padding: 12px 24px;
      display: flex;
      align-items: center;
      @include transition();

      i {
        margin: 0 0 0 4px;
        @include transition();
      }

      &:hover {
        background-color: rgba($darkInputBorderColor, 0.88);
        color: $colDefault;

        i {
          transform: translateX(4px);
        }
      }
    }
  }
</style>
