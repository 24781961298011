import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import http from "@/utilities/http";

import etrackRoutes from '@/views/ETrack/router'
import materialShopRoutes from '@/views/MaterialShop/router'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/views/Userpanel.vue"),
    children: [
      {
        path: "",
        redirect: { name: "Start" },
      },

      {
        path: "login",
        name: "Login",
        meta: { unprotected: true },
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
      },

      {
        path: "Start",
        name: "Start",
        component: () =>
          import(/* webpackChunkName: "start" */ "@/views/Start.vue"),
      },

      {
        path: "3D",
        name: "ModelViewer",
        component: () =>
          import(/* webpackChunkName: "model-viewer" */ "@/views/ModelViewer.vue"),
      },

      {
        path: "aufmass-assistent",
        name: "AufmassAssistent",
        component: () =>
          import(/* webpackChunkName: "rohrbiegung" */ "@/views/Rohrbiegung.vue"),
      },

      {
        path: "produktinformationen",
        name: "ProduktInformationen",
        component: () =>
          import(/* webpackChunkName: "product-informationen" */ "@/views/ProduktInformationen.vue"),
      },

      {
        path: "Einzelteile",
        name: "Einzelteile",
        component: () =>
          import(/* webpackChunkName: "einzelteile" */ "@/views/Einzelteile.vue"),
      },

      {
        path: 'garnitur/:articleNr?/:primaryArticleColor?/:secondaryArticleColor?',
        name: 'Garnitur',
        component: () => import('@/views/Garnitur.vue')
      },

      {
        path: "impressum",
        name: "Impressum",
        meta: { unprotected: true },
        component: () =>
          import(/* webpackChunkName: "impressum" */ "@/views/Impressum.vue"),
      },

      {
        path: "datenschutz",
        name: "Datenschutz",
        meta: { unprotected: true },
        component: () =>
          import(/* webpackChunkName: "datenschutz" */ "@/views/Datenschutz.vue"),
      },

      {
        path: "agb",
        name: "Agb",
        meta: { unprotected: true },
        component: () =>
          import(/* webpackChunkName: "agb" */ "@/views/Agb.vue"),
      },

      {
        path: "konto-aktivierung/:id",
        name: "KontoAktivierung",
        meta: { unprotected: true },
        component: () =>
          import(/* webpackChunkName: "konto-aktivierung" */ "@/views/KontoAktivierung.vue"),
      },

      {
        path: "konto-aktivierung-loeschen/:id",
        name: "KontoAktivierungLoeschen",
        meta: { unprotected: true },
        component: () =>
          import(/* webpackChunkName: "konto-aktivierung-loeschen" */ "@/views/KontoAktivierungLoeschen.vue"),
      },

      {
        path: 'meinKonto',
        name: 'MeinKontoIndex',
        component: () => import('@/views/MeinKontoIndex.vue'),
        redirect: { name: 'MeinKonto' },
        children: [
          {
            path: '',
            name: 'MeinKonto',
            component: () => import('@/views/MeinKonto.vue'),
          },
          {
            path: 'einstellungen',
            name: 'Einstellungen',
            component: () => import('@/views/Einstellungen.vue'),
          },
          {
            path: 'bestellungen',
            name: 'Bestellungen',
            component: () => import('@/views/Bestellungen.vue'),
          },
          {
            path: 'adressbuch',
            name: 'Adressbuch',
            component: () => import('@/views/Adressbuch.vue'),
          },
          {
            path: 'passwortAendern',
            name: 'PasswortAendern',
            component: () => import('@/views/PasswortAendern.vue'),
          },
          {
            path: 'neuigkeiten',
            name: 'Neuigkeiten',
            component: () => import('@/views/NewsManagement/NewsManagement.vue'),
          },
          {
            path: 'kontakt',
            name: 'Kontakt',
            component: () => import('@/views/Kontakt.vue'),
          },
        ]
      },

      {
        path: 'kontakt',
        redirect: {
          name: 'Kontakt',
        },
      },

      {
        path: "produkte",
        name: "Produkte",
        component: () =>
          import(/* webpackChunkName: "showcase" */ "@/views/Showcase.vue"),
      },

      {
        path: "suche",
        name: "Suche",
        component: () =>
          import(/* webpackChunkName: "suche" */ "@/views/Suche.vue"),
      },

      {
        path: "switchLanguage/:language",
        name: "Sprachwechsler",
        meta: { unprotected: true },
        component: () =>
          import(/* webpackChunkName: "switch-language" */ "@/views/SwitchLanguage.vue"),
      },

      {
        path: "warenkorb",
        component: () => import('@/views/WarenkorbIndex.vue'),
        children: [
          {
            path: '',
            name: 'Warenkorb',
            component: () => import('@/views/Warenkorb.vue'),
          },
          {
            path: "bestelluebersicht",
            name: "Bestelluebersicht",
            component: () =>
              import(/* webpackChunkName: "bestelluebersicht" */ "@/views/Bestelluebersicht.vue"),
          },

          {
            path: "versanddaten",
            name: "Versanddaten",
            component: () =>
              import(/* webpackChunkName: "versanddaten" */ "@/views/Versanddaten.vue"),
          },
        ]
      },

      {
        path: "naehassistent",
        name: "Naehassistent",
        component: () =>
          import(/* webpackChunkName: "naehassistent" */ "@/views/NaehAssistent.vue"),
      },

      ...etrackRoutes,
      ...materialShopRoutes,
    ],
  },

  {
    path: "/adminpanel",
    name: "Adminpanel",
    component: () =>
      import(/* webpackChunkName: "adminpanel" */ "@/views/Adminpanel.vue"),
    children: [
      {
        path: "",
        redirect: { name: "ControlOverview" },
      },

      {
        path: "Control-Overview",
        name: "ControlOverview",
        component: () =>
          import(
            /* webpackChunkName: "control-overview" */ "@/views/adminpanel/ControlOverview.vue"
          ),
      },

      {
        path: "analyses",
        name: "Analyses",
        component: () =>
          import(/* webpackChunkName: "analyses" */ "@/views/adminpanel/Analyses.vue"),
      },

      {
        path: "Orders",
        name: "Orders",
        component: () =>
          import(/* webpackChunkName: "orders" */ "@/views/adminpanel/Orders.vue"),
      },

      {
        path: "CAS-Kunden",
        name: "Customers",
        component: () =>
          import(/* webpackChunkName: "customers" */ "@/views/adminpanel/Customers.vue"),
      },

      {
        path: "Registrations",
        name: "Registrations",
        component: () =>
          import(/* webpackChunkName: "registrations" */ "@/views/adminpanel/Registrations.vue"),
      },

      {
        path: "Registrations/:id",
        name: "RegistrationDetails",
        component: () =>
          import(/* webpackChunkName: "registration-details" */ "@/views/adminpanel/RegistrationDetails.vue"),
      },

      {
        path: "Users/Admins",
        name: "UserListAdmins",
        component: () =>
          import(/* webpackChunkName: "user-list" */ "@/views/adminpanel/UserList.vue"),
      },

      {
        path: "Users",
        name: "UserList",
        component: () =>
          import(/* webpackChunkName: "user-list" */ "@/views/adminpanel/UserList.vue"),
      },

      {
        path: "Users/New/:customerId?",
        name: "UserCreate",
        component: () =>
          import(/* webpackChunkName: "user-create" */ "@/views/adminpanel/UserCreate.vue"),
      },

      {
        path: "Users/New/customerId/:customerId?",
        name: "UserCreateCustomerId",
        component: () =>
          import(/* webpackChunkName: "user-create" */ "@/views/adminpanel/UserCreate.vue"),
      },

      {
        path: "Users/New/registrationId/:registrationId?",
        name: "UserCreateRegistrationId",
        component: () =>
          import(/* webpackChunkName: "user-create" */ "@/views/adminpanel/UserCreate.vue"),
      },

      {
        path: "Users/edit/:id",
        name: "UserEdit",
        component: () =>
          import(/* webpackChunkName: "user-edit" */ "@/views/adminpanel/UserEdit.vue"),
      },

      {
        path: "Groups",
        name: "GroupList",
        component: () =>
          import(/* webpackChunkName: "group-list" */ "@/views/adminpanel/GroupList.vue"),
      },

      {
        path: "Groups/edit/:id",
        name: "GroupEdit",
        props: { mode: 'edit' },
        component: () =>
          import(/* webpackChunkName: "group-edit" */ "@/views/adminpanel/GroupEdit.vue"),
      },

      {
        path: "Groups/create",
        name: "GroupCreate",
        props: { mode: 'create' },
        component: () =>
          import(/* webpackChunkName: "group-edit" */ "@/views/adminpanel/GroupEdit.vue"),
      },

      {
        path: "Announce-Maintenance",
        name: "AnnounceMaintenance",
        component: () =>
          import(/* webpackChunkName: "announce-maintenance" */ "@/views/adminpanel/AnnounceMaintenance.vue"),
      },

      {
        path: "Maintenance-Menu",
        name: "Maintenance",
        component: () =>
          import(/* webpackChunkName: "maintenance" */ "@/views/adminpanel/Maintenance.vue"),
      },

      {
        path: "Languages",
        name: "LanguageList",
        component: () =>
          import(/* webpackChunkName: "language-list" */ "@/views/adminpanel/LanguageList.vue"),
      },

      {
        path: "Languages/edit/:lang",
        name: "LanguageEdit",
        component: () =>
          import(/* webpackChunkName: "language-edit" */ "@/views/adminpanel/LanguageEdit.vue"),
      },

      {
        path: "Server-Settings",
        name: "ServerSettings",
        component: () =>
          import(/* webpackChunkName: "server-settings" */ "@/views/adminpanel/ServerSettings.vue"),
      },

      {
        path: "Mailing-Settings",
        name: "MailingSettings",
        component: () =>
          import(/* webpackChunkName: "mailing" */ "@/views/adminpanel/Mailing.vue"),
      },

      {
        path: "Catalog-Settings",
        name: "CatalogSettings",
        component: () =>
          import(/* webpackChunkName: "catalog-settings" */ "@/views/adminpanel/CatalogSettings.vue"),
      },

      {
        path: "Logs",
        name: "Logs",
        component: () =>
          import(/* webpackChunkName: "logs" */ "@/views/adminpanel/Logs.vue"),
      },

      {
        path: "Import",
        name: "Import",
        component: () =>
          import(/* webpackChunkName: "import" */ "@/views/adminpanel/Import.vue"),
      },

      {
        path: "Advanced",
        name: "Advanced",
        component: () =>
          import(/* webpackChunkName: "advanced" */ "@/views/adminpanel/Advanced.vue"),
      },

    ],
  },
];

const router = new VueRouter({
  routes,
});

let announcementId = null
let announcementConf = {}
export const announcementsDismissedCookie = 'announcementsDismissed'

router.beforeEach((to, from, next) => {
  const isAdminpanel = to.matched.find(match => match.name === 'Adminpanel') !== undefined

  http.getJSON('/user/session').then(session => {
    store.commit('session/setSession', session)

    if (session.maintenance) {
      announcementId = new Date(session.maintenance.showFrom).getTime().toString()
      announcementConf = {
        type: 'warning',
        title: session.maintenance.title,
        message: session.maintenance.description,
        isMaintenanceAnnouncement: true,
        uniqueId: announcementId,
        close: () => {
          document.cookie = `${announcementsDismissedCookie}=${
            encodeURIComponent(announcementId) || ''
          };`
        },
        timeout: 0,
      }
    } else {
      announcementId = null
    }

    if (to.name === 'Login') {
      store.dispatch('session/getEntryUrl').then(entryUrl => {
        if (entryUrl) {
          next({ path: entryUrl })
        } else {
          next({ name: 'Start' })
        }
      }).catch(() => {
        next({ name: 'Start' })
      })
    } else {
      !isAdminpanel && store.dispatch('news/load')
      next()
    }
  }).catch(error => {
    // Save entryUrl when we don't have access to redirect to this path
    // later after the login
    if (!to.meta.unprotected) {
      store.dispatch('session/setEntryUrl', {
        value: to.path,
      })
    }

    if (error.status === 503) {
      if (isAdminpanel) {
        next()
      } else {
        error.text().then(html => {
          document.getElementById('app').innerHTML = html
        })
      }
    } else if (to.meta.unprotected) {
      next()
    } else {
      store.dispatch('session/logout')
      store.commit('session/setSession', null)

      if (to.name !== 'Login') {
        next({ name: "Login" })
      }
    }
  })
})

router.afterEach((to, from) => {
  const annoDismissed = (decodeURIComponent(document.cookie || '')
    .split('; ')
    .find(row => row.startsWith(announcementsDismissedCookie)) || '')
    .split('=')[1]

  const currentHints = Vue.prototype.Hint.getCurrentHints()
  const alreadyVisible = currentHints
    .find(hint => hint.uniqueId === announcementId) !== undefined

  if (announcementId && announcementId !== annoDismissed && !alreadyVisible) {
    Vue.prototype.Hint.add(announcementConf)
  }

  if (!announcementId && currentHints.length > 0) {
    currentHints
      .filter(hint => hint.isMaintenanceAnnouncement)
      .forEach(Vue.prototype.Hint.remove)
  }
})

export default router;
