<template>
  <li class="sidenavigation-item">
    <component
      :is="tag"
      :id="id"
      ref="item"
      class="btn"
      :class="[
        isActive ? 'active' : '',
        customClass,
      ]"
      :href="href"
      :title="title"
      :target="isExtern ? '_blank' : null"
      @click="$emit('click', $event)"
    >
      <span class="entryIcon">
        <slot name="icon">
          <icon v-if="icon" :icon="icon" :set="iconSet" :style="iconStyle" />
        </slot>
        <span v-if="infoBadge" class="badge info-badge">{{ infoBadge }}</span>
      </span>
      <span class="entryWrap">
        <span class="entryText">
          <slot>{{ title }}</slot>
        </span>
      </span>
    </component>
    <span class="entryMobileActiveToggle" />
    <popover
      v-if="type === 'popover'"
      ref="popover"
      placement="bottom"
      :title="popoverTitle"
      :target="'#' + id"
      custom-class="sideNav"
      @show="onPopoverShow"
      @hide="onPopoverHide"
    >
      <template #popover>
        <slot name="popover" />
      </template>
    </popover>
  </li>
</template>

<script>
let itemId = 999
function createId () {
  itemId += 1
  return 'sni-' + itemId.toString(36)
}

export default {
  name: 'side-navigation-item',
  props: {
    id: {
      type: String,
      default: () => createId(),
    },
    customClass: {
      type: String,
      default: '',
    },
    iconStyle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'anchor',
      validate: v => v in ['anchor', 'extern', 'button', 'popover']
    },
    route: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: null,
    },
    action: {
      type: Function,
      default: null,
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null,
    },
    iconSet: {
      type: String,
      default: 'mdi'
    },
    popoverTitle: {
      type: String,
      default: null,
    },
    infoBadge: {
      type: [Number, String],
      default: null,
    },
  },
  data () {
    return {
      showsPopover: false,
    }
  },
  computed: {
    tag () {
      switch (this.type) {
        case 'anchor': return 'a'
        case 'extern': return 'a'
        case 'button': return 'button'
        case 'popover': return 'button'
        default: return 'span'
      }
    },
    isAnchor () {
      return this.type === 'anchor' || this.type === 'extern'
    },
    isExtern () {
      return this.type === 'extern'
    },
    isButton () {
      return this.type === 'button'
    },
    isPopover () {
      return this.type === 'popover'
    },
    isCurrentRoute () {
      return this.$router.currentRoute.matched.find(({ path }) =>
        path === (`/${this.route}`)
      ) !== undefined
    },
    isActive () {
      return (this.active !== null ? this.active : this.isCurrentRoute) || this.showsPopover
    },
    href () {
      if (this.isExtern) {
        return this.route
      }
      if (this.isAnchor) {
        return '#/' + this.route
      }
      return null
    }
  },
  methods: {
    onPopoverShow () {
      this.showsPopover = true
      this.$emit('popover-show')
    },
    onPopoverHide () {
      this.showsPopover = false
      this.$emit('popover-hide')
    },
  },
  beforeDestroy () {
    if (this.isPopover) {
      this.$refs.popover.hide()
      this.$refs.popover.$destroy()
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.sideNav.popover {
  width: $popoverWidth-xs;
  max-width: $popoverWidth-xs;
  border-radius: 0;
  background-color: lighten($navbarBgColor, 8%);

  .popover-content {

    ul {

      li {

        a {
          min-height: 0;
          padding: 1rem;
          border: none;
          background-color: transparent;
          color: $lightFontColor;
          font-size: $font_size_normal;
          text-transform: none;
          text-align: left;
          white-space: normal;

          .entryIcon {
            width: 24px;
            font-size: inherit;
          }

          .entryWrap {
            .entryText {
              padding: 0 0.5rem;
            }
          }

          &:hover {
            background-color: lighten($navbarBgColor, 16%);
          }
        }
      }
    }
  }

  &.bottom {
    > .arrow {
      &::after {
        border-bottom-color: $darkFontColor;
      }
    }
  }

  &.right {

    > .arrow {

      &::after {
        border-right-color: $darkFontColor;
      }
    }
  }
}

@media screen and (min-width: $screen-lg) {

  .sideNav {
    &.popover {
      width: $popoverWidth-md;
      max-width: $popoverWidth-md;
    }
  }
}
</style>
