import Vue from 'vue'
import Vuex from 'vuex'

import http from '@/utilities/http'
import i18n from '@/i18n.js'

import { assemblyImageBasePath } from '@/views/ETrack/views/Assembly.vue'

export const isTypeManual = (type) => {
  return type !== null && type.id === 'ManualGeometries'
}

Vue.use(Vuex)

const defaultState = () => ({
  articleConfig: null,
  articleVariants: [],
  configuration: {
    bracket: null,
    color: null,
    profil: null,
    assembly: null,
    endPieces: null,
    driveCurtainPosition: null,
    confectionForm: null,
    decorWidth: null,
    engine: null,
    transmitters: [],
    type: null,
    typeHintConfirmed: false,
    wallDistance: null,
    curtainAddition: null,
  },
  summarySeen: false,
  curtainCalculationSeen: false,
})

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    isTypeManual: _ => type => isTypeManual(type),
    typeManualActive: state => isTypeManual(state.configuration.type),

    /**
     * If it's a ceiling-assembly the user is able to specify a walldistance.
     * Otherwise we have to get that value from the chosen bracket.
     *
     * @param {object} state
     * @returns {number}
     */
    wallDistance: state => {
      const { assembly, bracket, wallDistance } = state.configuration
      return assembly.IsCeiling
        ? wallDistance
        : parseFloat(bracket.Length.replace(' cm', '').replace(',', '.'))
    },

    assemblySummary: (state, getters, rootState) => {
      const { assembly } = state.configuration
      const title = assembly.IsCeiling ? i18n.t('etrack.assemblyTypes.ceiling') : i18n.t('etrack.assemblyTypes.wall')
      const wallDistance = getters.wallDistance
        .toFixed(2)
        .replace('.', ',')
        .concat(` ${rootState.session.catalog.measureUnit}`)

      return {
        value: `${title} - ${i18n.t('etrack.wallDistance')} ${wallDistance}`,
        image: `${assemblyImageBasePath}/menue_${assembly.IsCeiling ? 'decke' : 'wand'}.jpg`,
      }
    },

    /**
     * For some engines there are battery-supplies to choose from. Those should
     * be in the summary and the pdf as seperate rows, too.
     *
     * @returns {array|null}
     */
    batterySuppliesSummary (state, getters) {
      const { engine } = state.configuration

      if (getters.typeManualActive || !engine) {
        return null
      }

      // available items of the chosen engine which are present in the partlist
      // have been added by the user; the amount is only in the partlist while
      // the display-name is in the engine-dataset.
      const chosenItems = engine.BatterySupplies.reduce((choice, item) => {
        const itemInPartList = state.articleConfig.AdditionalParts.find(({ ArticleNr }) =>
          item.ArticleNumber === ArticleNr
        )

        return !itemInPartList || parseInt(itemInPartList.Count) === 0
          ? choice
          : [
            ...choice,
            { image: `/images/${item.Picture}`, value: `${itemInPartList.Count}x ${item.ArticleName}` }
          ]
      }, [])

      return chosenItems.length > 0 ? chosenItems : null
    },

    driveCurtainSummary: state => {
      const { curtainType, enginePosition, image } = state.configuration.driveCurtainPosition
      const curtainTypeTitle = i18n.t(`etrack.curtainTypes.${curtainType}`)
      const enginePositionTitle = enginePosition
        ? i18n.t(`etrack.enginePositions.${enginePosition}`)
        : i18n.t('etrack.withoutEngine')

      return { image, title: `${curtainTypeTitle}, ${enginePositionTitle}` }
    },
  },

  mutations: {
    /**
     * Resets the current configuration to the default.
     *
     * @param {object} state current state
     * @returns {void}
     */
    reset (state) {
      Object.assign(state.configuration, defaultState().configuration)
      state.summarySeen = false
      state.curtainCalculationSeen = false
    },

    setArticleConfig (state, articleConfig = null) {
      state.articleConfig = articleConfig
    },

    setArticleVariants (state, articleVariants = []) {
      state.articleVariants = articleVariants
    },

    setCurtainCalculationSeen (state, seen = true) {
      state.curtainCalculationSeen = seen
    },

    setSummarySeen (state, seen = true) {
      state.summarySeen = seen
    },

    /**
     * Overwrites the current config-state with the given one.
     *
     * @param {object} state current state
     * @param {object} configuration configuration to use
     * @returns {void}
     */
    setConfiguration (state, configuration) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state.configuration, configuration)
    },

    /**
     * Sets an entry of the configuration.
     *
     * @param {object} state
     * @param {object} change
     * @param {string} change.key
     * @param {any} change.value
     * @returns {void}
     */
    setConfigEntry (state, { key, value }) {
      state.configuration[key] = value
    },
  },

  actions: {
    async loadArticleConfig ({ commit, state }) {
      const { bracket, color } = state.configuration

      if (bracket === null || color === null) {
        return
      }

      const params = new URLSearchParams({
        bracketArticleNo: bracket.BracketArticleNumber,
        color: color.ColorNumber,
        setArticleNo: bracket.SetArticleNumber,
      })

      const res = await http.postJSON(`/api/electricset?${params}`)

      if (res.ReturnCode === 'Ok') {
        commit('setArticleConfig', res)
      }

      return res
    },

    /**
     * Tells the api to update a key, value-pair of the current article-config
     * and stores the result (= updated article-config).
     *
     * @param {object} context
     * @param {object} change { Key, Value }
     * @returns {Promise}
     */
    async postArticleConfig ({ commit }, change) {
      try {
        const res = await http.postJSON('/api/article/config', [change])
        res.ReturnCode === 'Ok' && commit('setArticleConfig', res)
        return res
      } catch (error) {
        return error
      }
    },

    /**
     * Adds a part to the current article-configuration or requests an update
     * if it's already within the partlist.
     *
     * @param {object} context
     * @param {object} params
     * @returns {Promise}
     */
    async putPart ({ commit, state }, { setArticleNo, count, color }) {
      const params = new URLSearchParams({ setArticleNo, count, color })
      const currentParts = state.articleConfig.AdditionalParts || []
      const isUpdate = currentParts.find(({ ArticleNr }) => ArticleNr === setArticleNo) !== undefined
      const res = await http.postJSON(`/api/article/config/${isUpdate ? 'additionalPartCount' : 'additionalPart'}?${params}`)

      if (res.ReturnCode === 'Ok') {
        commit('setArticleConfig', res)
      }
    },

    /**
     * Removes the part-, color-combination from the current article-config.
     *
     * @param {object} context
     * @param {object} params
     * @returns {Promise}
     */
    async removePart ({ commit }, { setArticleNo, color }) {
      const params = new URLSearchParams({ setArticleNo, color })
      const res = await http.delete(`/api/article/config/additionalPart?${params}`)

      if (res.ok) {
        commit('setArticleConfig', await res.json())
      }
    },

    /**
     * Requests the api to restore the given article-configuration. If that's
     * successful, we additionally apply the given view-state so those match
     * again.
     *
     *
     * @param {object} context
     * @param {object} configs
     * @returns {Promise}
     */
    async restoreConfig ({ commit }, { config, etrackState }) {
      try {
        const restoredConfig = await http.postJSON('api/article/config/restore', config)

        if (restoredConfig && restoredConfig.ReturnCode === 'Ok') {
          commit('setArticleConfig', restoredConfig)
          commit('setConfiguration', etrackState)
          commit('setSummarySeen', etrackState)
          commit('setCurtainCalculationSeen', etrackState)
          return restoredConfig
        }
      } catch (error) {
        return error
      }
    },
  },
}
