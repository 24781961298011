import shopItems from '@/assets/json/items.json'
import store from '@/store'

function itemsByType (type) {
  return shopItems.filter(item => item.type === type)
}

export default [
  {
    path: 'material-shop',
    name: 'MaterialShop',
    component: () => import('@/views/MaterialShop.vue'),
    redirect: {
      name: 'leaflets',
    },
    children: [
      {
        path: 'prospektservice',
        name: 'leaflets',
        component: () => import('@/views/MaterialShop/Leaflets/Leaflets'),
        props: {
          leaflets: itemsByType('leaflet'),
        },
      },
      {
        path: 'farbmuster',
        name: 'colorSamples',
        component: () => import('@/views/MaterialShop/ColorSamples'),
        props: {
          colorSamples: itemsByType('colorsamples')[0],
        },
      },
      {
        path: 'merchandise',
        name: 'merchandise',
        component: () => import('@/views/MaterialShop/Merchandise'),
        props: {
          articles: itemsByType('merchandise'),
        },
      },
      {
        path: 'kataloge',
        name: 'catalogues',
        component: () => import('@/views/MaterialShop/Catalogues'),
        props: {
          catalogues: itemsByType('catalogue'),
        },
      },
      {
        path: 'bilder',
        name: 'imageChoice',
        component: () => import('@/views/MaterialShop/ImageChoice/ImageChoice'),
        beforeEnter: (to, from, next) =>
          store.getters['session/hasRole']('SHOP.IMGDB.FRONTEND') ? next() : next({ name: 'leaflets' })
      },
      {
        path: 'videos',
        name: 'videoChoice',
        component: () => import('@/views/MaterialShop/VideoChoice'),
        beforeEnter: (to, from, next) =>
          store.getters['session/hasRole']('SHOP.IMGDB.FRONTEND') ? next() : next({ name: 'leaflets' })
      },
      {
        path: 'cart',
        name: 'materialShopCart',
        component: () => import('@/views/MaterialShop/MaterialShopCart'),
      },
    ],
  },
]
