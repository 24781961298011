import Vue from 'vue'
import Vuex from 'vuex'

import http from '@/utilities/http'

Vue.use(Vuex)

export default {
  namespaced: true,

  state: {
    configs: [],
    configToApply: null,
  },

  mutations: {
    /**
     * setConfigs
     *
     * @param {object} state
     * @param {array} configs
     * @returns {void}
     */
    setConfigs (state, configs = []) {
      state.configs = configs
    },

    /**
     * setConfigToApply
     *
     * @param {object} state
     * @param {object} config
     * @returns {void}
     */
    setConfigToApply (state, config = null) {
      state.configToApply = config
    },
  },

  actions: {
    /**
     * Loads the configurations saved by the user.
     *
     * @param {object} context
     * @returns {Promise}
     */
    async loadUserConfigs ({ commit }) {
      const configs = await http.getJSON('showcase')
      Array.isArray(configs) && commit('setConfigs', configs)
    },

    /**
     * Removes the stored user-configuration with the given id.
     *
     * @param {object} context
     * @param {string} id
     * @returns {Promise}
     */
    async removeUserConfig ({ commit, state }, id) {
      const res = await http.delete('showcase', {
        body: JSON.stringify({ id }),
        headers: http.jsonHeaders,
      })

      if (res.ok) {
        commit('setConfigs', state.configs.filter(({ _id }) => _id !== id))
      }
    },
  }
}
