import _ from 'underscore'
import moment from 'moment'
import http from '@/utilities/http'

function postProcessCart(commit) {
  return async (res) => {
    const data = await res.json()

    commit('setCount', data.length)
    _.forEach(data, (item) => {
      item.addedAt = moment(item.added_at)
    })

    return data
  }
}

export default {
  namespaced: true,
  state: {
    count: 0,
  },
  getters: {
  },
  mutations: {
    /**
     * Setzt die Anzahl der im Warenkorb befindlichen Artikel.
     *
     * @param {*} state
     * @param {Number} count
     */
    setCount (state, count) {
      state.count = count
    },
  },
  actions: {
    setCart ({ commit }, extraWish) {
      return http.putJSON('/cart/set', { extraWish }).then(data => {
        commit('setCount', data.length)
      })
    },

    get({ commit }) {
      return http.get('cart').then(postProcessCart(commit))
    },

    post({ commit }, data) {
      return http.putJSON('/order/customer', data).then(res => {
        commit('setCount', 0)
        return res
      })
    },

    /**
     * Adds a part to the current cart.
     *
     * @param {object} context
     * @param {object} part
     * @returns {undefined}
     */
    postPart ({ commit }, part) {
      return http
        .post('cart/part', JSON.stringify(part), { headers: http.jsonHeaders })
        .then(postProcessCart(commit))
    },

    removeAll({ commit }) {
      return http.delete('cart').then(() => {
        commit('setCount', 0)
      })
    },

    removeSet({ commit }, id) {
      return http.delete(`cart/set/${id}`).then(postProcessCart(commit))
    },

    removePart({ commit }, id) {
      return http.delete(`cart/part/${id}`).then(postProcessCart(commit))
    },

    count({ commit }, { id, count }) {
      return http
        .post(
          `cart/set/count/${id}`,
          JSON.stringify({
            count: parseInt(count, 10),
          }),
          {
            headers: { ...http.jsonHeaders },
          }
        )
        .then(postProcessCart(commit))
    },

    removeSavedCart(context, id) {
      return http.delete(`cart/saved/${id}`)
    },

    loadSavedCart(context, id) {
      return http.putJSON(`cart/load/${id}`)
    },

    saveCart(context, name) {
      return http.postJSON('cart/savecurrent', { name })
    },
  },
}
