<template>
  <div class="config-loader--wrap">
    <e-track-btn
      v-if="configs.length > 0"
      :text="$t('etrack.loadConfig')"
      :loading="loading"
      @click.native="showConfigDialog = true"
    />

    <e-track-dialog
      :title="$t('etrack.loadConfig')"
      :visible="showConfigDialog"
      class="saved-etracks-dialog"
      @close="showConfigDialog = false"
    >
      <ul>
        <li v-for="({ _id, title }) in configs" :key="_id" class="d-flex align-center">
          {{ title }}

          <div class="ml-auto list-actions">
            <span v-tooltip="$t('etrack.delete')" class="icon-btn" @click="deleteConfig(_id)">
              <span class="material-icons">
                delete
              </span>
            </span>

            <span
              v-tooltip="$t('etrack.load')"
              class="icon-btn"
              @click="onSelectConfig(_id)"
            >
              <span class="material-icons">
                restart_alt
              </span>
            </span>
          </div>
        </li>
      </ul>
    </e-track-dialog>
  </div>
</template>

<script>
import ETrackBtn from '../components/ETrackBtn'
import ETrackDialog from '../components/ETrackDialog'

export default {
  name: 'config-loader',

  components: {
    ETrackBtn,
    ETrackDialog,
  },

  props: {
    configs: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      showConfigDialog: false,
    }
  },

  methods: {
    /**
     * Removes the configuration with the given id from the local storage.
     *
     * @param {number} configId
     * @returns {void}
     */
    async deleteConfig (configId) {
      await this.$store.dispatch('configStorage/removeUserConfig', configId)
      this.showConfigDialog = this.configs.length > 0
    },

    onSelectConfig (configId) {
      this.showConfigDialog = false
      this.$emit('config:selected', configId)
    }
  }
}
</script>

<style lang="scss">
  .saved-etracks-dialog {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 6px;
        border-bottom: 1px solid $colDefaultDark;

        &:last-child {
          border: none;
        }

        .icon-btn {
          margin-left: 4px;
          cursor: pointer;
          background-color: #efefef;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
</style>
