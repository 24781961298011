<template>
  <div class="summary-row--wrap">
    <div class="row summary-row d-flex">
      <div class="col-xs-3 d-flex align-center summary-title">
        {{ title }}
      </div>

      <template v-if="Array.isArray(value)">
        <div class="col-xs-7">
          <div
            v-for="({ value: childValue, image: childImage }, i) in value"
            :key="i"
            class="row summary-row row-no-gutters d-flex align-center"
          >
            <div class="col-xs-9 d-flex align-center">
              {{ childValue }}
            </div>
            <div class="col-xs-3 d-flex justify-center" @click="onImageClick">
              <div v-if="childImage" class="zoom-image--wrap">
                <img :src="childImage" class="img-responsive">
                <div class="icon--wrap">
                  <span class="material-icons">open_in_full</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="col-xs-5 d-flex align-center">
          {{ value }}
          <slot name="appendValue" />
        </div>

        <div class="col-xs-2 d-flex justify-center" @click="onImageClick">
          <div v-if="image" class="zoom-image--wrap">
            <img :src="image" class="img-responsive">
            <div class="icon--wrap">
              <span class="material-icons">open_in_full</span>
            </div>
          </div>
        </div>
      </template>

      <div class="col-xs-2 d-flex align-center">
        <e-track-btn :text="$t('etrack.change')" :to="routeName" small class="ml-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import ETrackBtn from '../../components/ETrackBtn'

export default {
  name: 'summary-row',

  components: {
    ETrackBtn,
  },

  props: {
    // title of the summary-item
    title: {
      type: String,
      default: '',
    },

    // value(s) of the summary-item
    value: {
      type: [Array, String],
      default: null,
    },

    // preview-image
    image: {
      type: String,
      default: null,
    },

    // route of the related config-step for changes
    routeName: {
      type: String,
      default: null,
      required: true,
    },
  },

  methods: {
    onImageClick () {
      const summaryData = { title: this.title, image: this.image, route: this.routeName }

      Array.isArray(this.value)
        ? summaryData.values = this.value
        : summaryData.value = this.value

      this.$emit('click:image', summaryData)
    }
  },
}
</script>

<style lang="scss">
  .summary-row--wrap {
    font-size: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }

    .summary-row {
      padding: 6px 0;

      .summary-title {
        font-family: $fontBold;
      }

      .zoom-image--wrap {
        min-width: 50px;
        position: relative;
        display: inline-block;
        cursor: pointer;

        img {
          max-height: 36px;
        }

        .icon--wrap {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .material-icons {
            font-size: 0.65rem;
            opacity: 0;
            background-color: #fff;
            border: 1px solid;
            border-radius: 50%;
            padding: 4px;
            transform: scale(0);
            transition: transform 0.18s cubic-bezier(.4,0,.2,1);
          }
        }
      }

      &:hover {
        .zoom-image--wrap {
          .material-icons {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }

  @media (min-width: $screen-lg) {
    .summary-row--wrap {
      .summary-row {
        .zoom-image--wrap {
          img {
            max-height: 50px;
          }
        }
      }
    }
  }
</style>
