import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

// Start - Dependencies
import 'expose-loader?exposes=$,jQuery!jquery';
import "bootstrap";

import '@/utilities/jquery.unveil.js'
import '@/utilities/underscore.picklike.js'
// End - Dependencies

// Start - Global Vue Components
import * as uiv from 'uiv'
import Hint from '@/plugins/Hint'
import Modal from '@/plugins/Modal'
import VueSweetalert2 from 'vue-sweetalert2'
import PortalVue from 'portal-vue'
import Card from '@/components/Card'
import Icon from '@/components/Icon'
import IField from '@/components/IField'
import IInput from '@/components/IInput'

Vue.use(uiv)
Vue.use(VueSweetalert2)
Vue.use(PortalVue)
Vue.use(Hint)
Vue.use(Modal)
Vue.component('card', Card)
Vue.component('icon', Icon)
Vue.component('i-field', IField)
Vue.component('i-input', IInput)
// End - Global Vue Components

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
