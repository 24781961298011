<template>
  <default-page-layout
    :heading="$t('etrack.confectionForm')"
    :loading="isLoading"
    class="e-track-confection-form--wrap"
  >
    <image-switch
      :default="`${confectionFormImageBasePath}/prev.jpg`"
      :primary="tempPreview ? tempPreview.preview : null"
      :secondary="chosenOption ? chosenOption.preview : null"
      height-aware
    >
      <div
        v-if="hint || recommendation"
        class="preview-overlay text-center"
      >
        <div v-if="hint">
          {{ hint }}
        </div>
        <div v-if="recommendation" class="mt-6">
          {{ recommendation }}
        </div>
      </div>
    </image-switch>

    <template #aside>
      <div class="position-relative">
        <div
          class="row d-flex flex-wrap"
          :class="{
            'cross-underlay': options.length <= 4
          }"
        >
          <div v-for="(option, i) in options" :key="option.id" class="col-md-6 d-flex justify-center">
            <e-track-card
              :title="option.title"
              :is-chosen="chosenOption && chosenOption.id === option.id"
              :left-indicator="i % 2 === 0"
              :loading="isLoading"
              @mouseenter.native="tempPreview = option"
              @mouseleave.native="tempPreview = null"
              @click="setOption(option)"
            >
              <img :src="option.image" class="img-responsive preview-image">
            </e-track-card>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackCurtainEngine"
        :text="$t('etrack.prev')"
      />

      <e-track-btn
        to="ETrackGliderChoice"
        :disabled="chosenOption === null"
        :tooltip="chosenOption === null ? $t('etrack.choiceNeeded') : null"
        :loading="isLoading"
        :text="$t('etrack.next')"
        class="ml-auto"
      />
    </template>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ImageSwitch from '../components/ImageSwitch.vue'
import etrackCurtains from '../../../../public/json/etrack-curtains.json'

const confectionFormImageBasePath = '/images/Elektroschiene/ConfectionForm'

export default {
  name: 'e-track-confection-form',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ImageSwitch,
  },

  data () {
    return {
      confectionFormImageBasePath,
      isLoading: false,
      tempPreview: null,
    }
  },

  computed: {
    chosenOption () {
      return this.$store.state.etrack.configuration.confectionForm
    },

    /**
     * The API isn't able to give us a list of the available options, but
     * expects specific IDs. So we have to define the confections forms to
     * choose from.
     *
     * @returns {array}
     */
    options () {
      const options = [
        {
          id: 'W',
          title: this.$t('etrack.confectionForms.W.title'),
          image: `${confectionFormImageBasePath}/ww.jpg`,
          preview: `${confectionFormImageBasePath}/prev_ww.jpg`,
        },
        {
          id: 'Pleat',
          title: this.$t('etrack.confectionForms.Pleat.title'),
          image: `${confectionFormImageBasePath}/f.jpg`,
          preview: `${confectionFormImageBasePath}/prev_f.jpg`,
          hint: this.$t('etrack.confectionForms.Pleat.hint'),
          recommendation: this.$t('etrack.confectionForms.Pleat.recommendation'),
        },
        {
          id: 'Curly',
          title: this.$t('etrack.confectionForms.Curly.title'),
          image: `${confectionFormImageBasePath}/k.jpg`,
          preview: `${confectionFormImageBasePath}/prev_k.jpg`,
          hint: this.$t('etrack.confectionForms.Curly.hint'),
          recommendation: this.$t('etrack.confectionForms.Curly.recommendation'),
        },
      ]

      return options.map(option => ({
        ...option,
        ...etrackCurtains.find(({ Id }) => Id === option.id),
      }))
    },

    hint () {
      return this.tempPreview?.hint || this.chosenOption?.hint || null
    },

    recommendation () {
      return this.tempPreview?.recommendation || this.chosenOption?.recommendation || null
    },
  },

  methods: {
    /**
     * Stores the given option as the chosen one.
     *
     * @param {object} option
     * @returns {Promise}
     */
    async setOption (option) {
      this.isLoading = true
      const res = await this.$store.dispatch('etrack/postArticleConfig', { Key: 'CurtainType', Value: option.id })

      if (res && res.ReturnCode === 'Ok') {
        this.$store.commit('etrack/setConfigEntry', { key: 'confectionForm', value: { ...option } })
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
  @import '../styles/variables.scss';

  .e-track-confection-form--wrap {
    .image-switch--wrap {
      position: relative;

      .preview-overlay {
        background-color: rgba(255, 255, 255, 0.88);
        position: absolute;
        bottom: 30px;
        right: 30px;
        padding: 15px;
        font-size: 1rem;
        max-width: 480px;
      }
    }

    .e-track-card--wrap {
      .card-content {
        padding: 0;

        .preview-image {
          max-height: 18vh;
        }
      }
    }
  }
</style>
