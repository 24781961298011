<template>
  <div
    class="ribbon-height-hint--wrap"
  >
    <div class="ribbon-height-hint">
      {{ $t('etrack.ribbonHeightHint') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ribbon-height-hint',
}
</script>

<style lang="scss">
  .ribbon-height-hint--wrap {
    .ribbon-height-hint {
      font-size: 1.15rem;
      padding: 16px;
      text-align: center;
      background-color: #000;
      color: #fff;
    }
  }
</style>
