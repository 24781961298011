<template>
  <div ref="layoutWrap" class="default-page-layout--wrap">
    <progress-linear :loading="loading" />

    <div class="header--wrap">
      <header>
        <div class="d-flex align-center">
          <h1>
            {{ heading }}
          </h1>

          <slot name="head-center" />
        </div>
      </header>

      <slot name="belowHeader" />
    </div>

    <main class="main-content container">
      <div v-if="$slots.aside" class="custom-content-grid">
        <div class="col-content">
          <slot />
        </div>
        <div class="col-options">
          <slot name="aside" />
        </div>
      </div>
      <div v-else class="my-auto d-flex flex-column">
        <slot />
      </div>
    </main>

    <footer
      v-if="$slots.footer"
      class="d-flex align-center"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
import ProgressLinear from './ProgressLinear'

export default {
  name: 'default-page-layout',

  components: {
    ProgressLinear,
  },

  props: {
    heading: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    splitted: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
  $contentIndentationMobile: 16px;
  $contentIndentationDesktop: 32px;

  .default-page-layout--wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding: $contentIndentationMobile;
    background-color: #fff;
    position: relative;

    h1 {
      text-transform: uppercase;
      color: #b6b6b6;
      margin: 0;
      letter-spacing: 5px;
    }

    .progress-linear--wrap {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }

    .main-content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1720px;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 0 auto;
      padding: 12px;
    }

    header, footer {
      position: relative;
      z-index: 1;
    }

    footer {
      padding: 4px 0;

      &::before,
      &::after {
        content: "";
        background-color: #ccc;
        height: 2px;
        position: absolute;
        width: 55%;
      }

      &::before {
        left: 0;
        top: 0;
      }

      &::after {
        right: 0;
        bottom: 0;
      }
    }
  }

  @media (min-width: $screen-lg) {
    .default-page-layout--wrap {
      padding: $contentIndentationDesktop;
    }
  }
</style>
