/*
 * Taken from https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
 */
function checkTouchDevice () {
  if (navigator.maxTouchPoints !== undefined && !(navigator.maxTouchPoints > 0)) {
    return false
  }

  if ('ontouchstart' in window || window.TouchEvent) {
    return true
  }

  if (window.DocumentTouch && document instanceof window.DocumentTouch) {
    return true
  }

  const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-']
  const queries = prefixes.map(prefix => `(${prefix}touch-enabled)`)

  return window.matchMedia(queries.join(',')).matches
}

export default checkTouchDevice()
