<template>
  <div ref="wrapper" class="dot-navigation--wrap">
    <div v-if="hasPrev" class="nav-affix left">
      <span class="material-icons">
        navigate_before
      </span>
    </div>

    <div
      ref="content"
      class="dot-navigation--content"
      :style="{
        transform: `translateX(${scrollOffset}px)`
      }"
    >
      <router-link
        v-for="({ label, route, isHidden }, i) in entries"
        :key="i"
        :to="{ name: route }"
        class="dot-navigation--entry"
        :class="{
          allowed: routeAllowed(route),
          'is--hidden': isHidden
        }"
        tag="div"
      >
        <span class="dot" />
        {{ label }}
      </router-link>

      <div
        class="nav-slider"
        :style="{
          left: `${sliderLeft}px`,
          width: `${sliderWidth}px`,
        }"
      />
    </div>

    <div v-if="hasNext" class="nav-affix right">
      <span class="material-icons">
        navigate_next
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dot-navigation',

  data () {
    return {
      sliderLeft: 0,
      sliderWidth: 0,
      scrollOffset: 0,
      widths: null,
    }
  },

  computed: {
    /**
     * Is the "manual"-mode currently chosen?
     *
     * @returns {object?}
     */
    isManual () {
      return this.$store.getters['etrack/typeManualActive']
    },

    entries () {
      return [
        { label: this.$t('etrack.typeChoice'), route: 'ETrackTypeChoice' },
        { label: this.$t('etrack.profileChoice'), route: 'ETrackProfil' },
        { label: this.$t('etrack.assembly'), route: 'ETrackAssembly' },
        { label: this.$t('etrack.endPieces'), route: 'ETrackEndPieces' },
        { label: this.$t('etrack.curtainTitle'), route: 'ETrackCurtainEngine' },
        { label: this.$t('etrack.confectionForm'), route: 'ETrackConfectionForm' },
        { label: this.$t('etrack.glider'), route: 'ETrackGliderChoice' },
        { label: this.$t('etrack.decorWidth'), route: 'ETrackDecorWidth' },
        { label: this.$t('etrack.engines'), route: 'ETrackEngine', isHidden: this.isManual },
        {
          label: this.$t('etrack.transmitters'),
          route: 'ETrackTransmitter',
          isHidden: this.isManual || !this.hasTransmittersToChoose
        },
        { label: this.$t('etrack.summary'), route: 'ETrackSummary' },
        { label: this.$t('etrack.curtainCalculation.title'), route: 'ETrackCurtainCalculation' },
        { label: this.$t('etrack.finish'), route: 'ETrackFinish' },
      ]
    },

    hasTransmittersToChoose () {
      return this.$store.state.etrack.configuration.engine !== null &&
        this.$store.state.etrack.configuration.engine.WirelessController.length > 0
    },

    hasNext () {
      const { content, wrapper } = this.widths
      // Check one scroll ahead to know the width of right-most item
      return content > Math.abs(this.scrollOffset) + wrapper
    },

    hasPrev () {
      return this.scrollOffset !== 0
    },
  },

  watch: {
    $route: function () {
      this.$nextTick(this.updateView)
    },

    hasTransmittersToChoose () {
      this.$nextTick(this.updateView)
    },

    isManual () {
      this.$nextTick(this.updateView)
    },
  },

  /**
   * created
   *
   * @returns {void}
   */
  created () {
    this.setWidths()
    window.addEventListener('resize', this.updateView)
  },

  /**
   * created
   *
   * @returns {void}
   */
  mounted () {
    this.$nextTick(this.updateView)
  },

  /**
   * destroyed
   *
   * @returns {void}
   */
  destroyed () {
    window.removeEventListener('resize', this.updateView)
  },

  methods: {
    /**
     * Checks if the route with the given name exists, is allowed.
     * A route is allowed if the requirement is met (= the previous step of the
     * configuration is completed).
     *
     * @param {string} name
     * @returns {boolean}
     */
    routeAllowed (name) {
      const { resolved } = this.$router.resolve({ name })

      if (!resolved) {
        return false
      }

      return typeof resolved.meta?.requirement === 'function'
        ? resolved.meta.requirement()
        : true
    },

    /**
     * Returns the currently active navigation-entry. If we can't find one, we
     * assume that we're at the end (since there are optional steps following
     * the last entry)
     *
     * @returns {HTMLElement} Currently active navigation-entry
     */
    getActiveEntry () {
      const activeEntry = this.$refs.content.querySelector('.router-link-active')
      if (activeEntry !== null) return activeEntry

      const entries = this.$refs.content.querySelectorAll('.dot-navigation--entry')
      return entries[entries.length - 1]
    },

    /**
     * Sets the width, position of the slider (line below the tab-bar which
     * highlights the active entry).
     *
     * @returns {void}
     */
    setSlider () {
      const listPosX = this.$refs.content.getBoundingClientRect().left
      const activeEntryRect = this.getActiveEntry().getBoundingClientRect()

      this.sliderLeft = activeEntryRect.x - listPosX
      this.sliderWidth = activeEntryRect.width
    },

    /**
     * Moves the tab-bar so the active entry gets centered (if possible
     * without moving the bar too far).
     *
     * @returns {void}
     */
    setBarX () {
      const { offsetLeft, clientWidth } = this.getActiveEntry()
      const offsetCentered = offsetLeft + clientWidth / 2 - this.widths.wrapper / 2
      const offset = Math.min(this.widths.content - this.widths.wrapper, Math.max(0, offsetCentered))

      this.scrollOffset = offset * -1
    },

    /**
     * Updates the internal widths to match the current view.
     *
     * @returns {void}
     */
    setWidths () {
      const { content, wrapper } = this.$refs

      this.widths = {
        content: content ? content.clientWidth : 0,
        wrapper: wrapper ? wrapper.clientWidth : 0,
      }
    },

    /**
     * Updates the positions of the bar, slider based on the current dimensions
     * and the active entry.
     *
     * @returns {void}
     */
    updateView () {
      this.setWidths()
      this.setBarX()
      this.setSlider()
    }
  },
}
</script>

<style lang="scss">
  .dot-navigation--wrap {
    position: relative;
    z-index: 10;
    display: flex;
    overflow: hidden;

    .dot-navigation--content {
      flex: 1 0 auto;
      display: flex;
      height: $menuItemWidth;
      background: #343434;
      border-top: 1px solid #202020;
      transition: .3s cubic-bezier(.25,.8,.5,1);

      .dot-navigation--entry {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        padding: 0 16px;
        font-size: 16px;
        color: #fff;
        position: relative;
        cursor: pointer;

        .dot {
          display: none;
        }

        &:not(.allowed) {
          color: hsla(0,0%,100%,.6);
          cursor: not-allowed;
        }

        &.is--hidden {
          display: none;
        }
      }

      .nav-slider {
        position: absolute;
        bottom: 0;
        z-index: 1;
        height: 6px;
        background-color: #fff;
        transition: .4s cubic-bezier(.25,.8,.5,1);
      }
    }

    .nav-affix {
      position: absolute;
      top: 1px;
      left: 0;
      bottom: 0;
      width: 50px;
      z-index: 2;
      background: #343434f0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(255, 255, 255, 0.03);

      &.right {
        left: auto;
        border-right: none;
        border-left: 1px solid rgba(255, 255, 255, 0.03);
        right: 0;
      }
    }
  }
</style>
