<template>
  <default-page-layout
    :heading="$t('etrack.engines')"
    :loading="isLoading"
    class="e-track-engine--wrap"
  >
    <div v-if="!isLoadingParts" class="d-flex">
      <e-track-card
        v-for="engine in engines"
        :key="engine.ArticleNumber"
        :is-chosen="chosenEngine && chosenEngine.ArticleNumber === engine.ArticleNumber"
        left-indicator
        :loading="isLoading"
        class="ml-4 mr-4"
        @click="setEngine(engine)"
      >
        <template #title>
          <div class="text-center">
            <div class="d-flex align-center justify-center">
              {{ engine.AdditionalText1 }}
              <span
                v-if="engine.Description"
                v-popover="{
                  content: engine.Description,
                  customClass: 'etrack-popover'
                }"
                class="material-icons info-icon ml-2"
                @click.stop=""
              >
                info
              </span>
            </div>
            <div v-if="engine.AdditionalText3" class="text-subtitle">
              {{ engine.AdditionalText3 }}
            </div>
          </div>
        </template>

        <img
          :src="`/images/${engine.Picture}`"
          class="img-responsive preview-image mx-auto"
        >

        <div
          v-for="({ count, name }, i) in chosenAdditionalPartsByEngine(engine)"
          :key="i"
          class="text-center"
        >
          + {{ count }}x {{ name }}
        </div>
      </e-track-card>
    </div>

    <additional-parts-choice
      :additional-parts="additionalParts"
      :show-additional-part-choice="showAdditionalPartChoice"
      :loading="isLoading"
      @choice="isLoading = true"
      @chosen="onAdditionalPartsChosen"
    />

    <template #aside>
      <img
        class="logo--somfy mx-auto"
        :src="require('../assets/logo_somfy.jpg')"
      >
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackDecorWidth"
        :text="$t('etrack.prev')"
        :loading="isLoading"
      />

      <div class="mx-auto">
        <price-box v-if="!isLoading" />
      </div>

      <e-track-btn
        :loading="isLoading"
        :disabled="chosenEngine === null"
        :tooltip="chosenEngine === null ? $t('etrack.pleasePickOption') : null"
        :to="nextStep"
        :text="$t('etrack.next')"
      />
    </template>

    <e-track-dialog
      :title="$t('etrack.hint')"
      :visible="hintToShow && hintToShow.length > 0"
      enforce-confirmation
    >
      {{ hintToShow }}

      <template #footer>
        <e-track-btn
          :text="$t('etrack.confirm')"
          small
          filled
          class="ml-auto"
          icon="check"
          @click.native="hintToShow = false"
        />
      </template>
    </e-track-dialog>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ETrackDialog from '../components/ETrackDialog'
import PriceBox from '../components/PriceBox'

import http from '@/utilities/http'
import AdditionalPartsChoice from './AdditionalPartsChoice.vue'

export default {
  name: 'e-track-engine',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ETrackDialog,
    PriceBox,
    AdditionalPartsChoice,
  },

  data () {
    return {
      additionalPartsToShow: [],
      engines: [],
      hintToShow: null,
      isLoading: false,
      isLoadingParts: false,
      showAdditionalPartChoice: false,
    }
  },

  computed: {
    /**
     * A chosen engine may have additional parts to choose from.
     * If a part already has been added (e.g. when navigating back to this
     * view) we must apply the count.
     *
     * @returns {array}
     */
    additionalParts () {
      if (!this.chosenEngine || this.chosenEngine.BatterySupplies.length === 0) {
        return []
      }

      return this.chosenEngine.BatterySupplies.map(part => {
        const count = this.currentParts.find(({ ArticleNr }) => ArticleNr === part.ArticleNumber)?.Count || 0
        return { ...part, count }
      })
    },

    chosenEngine () {
      return this.$store.state.etrack.configuration.engine
    },

    currentParts () {
      return this.$store.state.etrack.articleConfig.AdditionalParts
    },

    /**
     * Only some engines have the possibility to pick transmitters. If there
     * aren't any transmitters to choose from we skip that step.
     *
     * @returns {boolean}
     */
    nextStep () {
      return this.chosenEngine !== null && this.chosenEngine.WirelessController.length > 0
        ? 'ETrackTransmitter'
        : 'ETrackSummary'
    },
  },

  watch: {
    chosenEngine () {
      // an engine may have an important hint which should get shown within a
      // modal-dialog so the user has to confirm that information
      this.hintToShow = this.chosenEngine && this.chosenEngine.PopupText
        ? this.chosenEngine.PopupText
        : null

      this.additionalParts.length > 0 && (this.showAdditionalPartChoice = true)
    },
  },

  mounted () {
    this.getEngines()
    this.additionalParts.length > 0 && (this.showAdditionalPartChoice = true)
  },

  methods: {
    /**
     * The available engines aren't part of the article-configuration. So we
     * have to load those from an extra-endpoint.
     *
     * @returns {Promise}
     */
    async getEngines () {
      this.isLoading = true
      this.isLoadingParts = true
      const res = await http.getJSON('/api/electricparts')
      this.isLoading = false
      this.isLoadingParts = false

      this.engines = res.ReturnCode === 'Ok'
        ? res.Items
        : []
    },

    onAdditionalPartsChosen () {
      this.isLoading = false
      this.showAdditionalPartChoice = false
    },

    /**
     * Stores the given engine as the chosen one.
     *
     * @param {object} engine
     * @returns {void}
     */
    async setEngine (engine) {
      // engine wasn't changed -> nothing to do
      if (this.chosenEngine && engine && this.chosenEngine.ArticleNumber === engine.ArticleNumber) {
        // ..but when the engine has additional parts to choose from, we must
        // at least show the overlay again
        this.showAdditionalPartChoice = true
        return
      }

      this.isLoading = true

      // when there's a change we must reset the previously chosen additional
      // parts since those are related to an engine
      const deleteRes = await http.delete('/api/article/config/additionalPart/clear')

      if (!deleteRes.ok) {
        this.isLoading = false
        return
      }

      // a successful deletion gives us a new article-configuration with an
      // updates part-list.
      this.$store.commit('etrack/setArticleConfig', await deleteRes.json())

      if (engine !== null) {
        await this.$store.dispatch('etrack/putPart', {
          setArticleNo: engine.ArticleNumber,
          color: engine.Colors[0].Key,
          count: 1,
        })
      }

      this.$store.commit('etrack/setConfigEntry', { key: 'engine', value: engine ? { ...engine } : null })
      this.isLoading = false
    },

    /**
     * Users are able to select additional parts for their chosen engine. If
     * that's the case, we want to show a summary below the engine-image.
     *
     * @param {object} engine
     * @returns {array}
     */
    chosenAdditionalPartsByEngine (engine) {
      return engine.BatterySupplies
        .map(part => ({
          name: part.ArticleName,
          count: this.currentParts.find(({ ArticleNr }) => ArticleNr === part.ArticleNumber)?.Count || 0
        }))
        .filter(({ count }) => count > 0)
    },
  },
}
</script>

<style lang="scss">
  .e-track-engine--wrap {
    .main-content {
      .custom-content-grid {
        .col-content {
          flex: 0 0 70%;
          max-width: 70%;
        }
        .col-options {
          flex: 0 0 30%;
          max-width: 30%;
        }
      }
    }

    .logo--somfy {
      max-width: 110px;
    }

    .e-track-card--wrap {
      flex: 1;

      .card-title {
        position: relative;

        .info-icon {

        }
      }

      .preview-image {
        max-height: 30vh;
      }
    }
  }

  @media (min-width: $screen-lg) {
    .e-track-engine--wrap {
      .logo--somfy {
        max-width: 150px;
      }
    }
  }
</style>
