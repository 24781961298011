<template>
  <div class="e-track--index">
    <div class="nav--wrap">
      <side-navigation route="e-schiene" />
      <portal to="nav-title">{{ $t('pageNavigation.etrack') }}</portal>
    </div>

    <div class="e-track-content--wrap">
      <configuration-forbidden-overlay etrack />
      <nav-bar v-show="$route.name !== 'ETrackIntro'" />

      <transition :name="transitionName">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import ConfigurationForbiddenOverlay from '@/components/ConfigurationForbiddenOverlay.vue'
import NavBar from './components/NavBar'
import SideNavigation from '@/views/SideNavigation'

export default {
  name: 'e-track',

  components: {
    ConfigurationForbiddenOverlay,
    NavBar,
    SideNavigation,
  },

  data () {
    return {
      transitionName: 'slide-left',
    }
  },

  watch: {
    /**
     * We want to change the route-transitions based on the relation of the
     * current, new route: If the user moves to the next step the content
     * should slide left, otherwise right.
     *
     * @param {object} to Route to go to
     * @param {object} from Current route
     */
    '$route' (to, from) {
      const toOrder = to.meta.order || 0
      const fromOrder = from.meta.order || 0

      this.transitionName = toOrder > fromOrder ? 'slide-right' : 'slide-left'
    }
  },
}
</script>

<style lang="scss">
  @import '../../../public/stylesheets/scss/_variables';
  @import './styles/popovers.scss';

  .e-track--index {
    @import './styles/styles.scss';
    font-size: 1.35rem;

    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;

    .nav--wrap {
      #sideNavigation {
        .sideNavigationInnerWrap {
          padding-top: $navBarHeight;
        }
      }
    }

    .e-track-content--wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      margin: $navBarHeight 0 0 $menuItemWidth;
    }
  }

  .alert--above-content {
    z-index: 10;
  }
</style>
