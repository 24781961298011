<template>
  <default-page-layout class="e-track-profil--wrap" :heading="$t('etrack.profileChoice')">
    <div class="preview--wrap">
      <div class="headings--wrap">
        <h2>
          {{ title }}
        </h2>

        <h3 class="indented-subheading">
          {{ subtitle }}
        </h3>
      </div>

      <image-switch
        :default="`${profileImageBasePath}/profil-ohne-auswahl.jpg`"
        :primary="tempPreview ? `${profileImageBasePath}/${tempPreview.image}` : null"
        :secondary="chosenColor ? `${profileImageBasePath}/${chosenColor.Picture}` : null"
      />
    </div>

    <template #aside>
      <color-choice-table
        :chosen-color="chosenColor"
        :chosen-profile="chosenItem"
        :profiles="items"
        @input="({ profile, color }) => onColorChoice(profile, color)"
        @show-preview="({ profile, color }) => showPreview(profile, color)"
        @hide-preview="tempPreview = null"
      />
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackTypeChoice"
        :text="$t('etrack.prev')"
      />

      <e-track-btn
        to="ETrackAssembly"
        :disabled="!chosenItem"
        :tooltip="chosenItem ? null : $t('etrack.choiceNeeded')"
        :text="$t('etrack.next')"
        class="ml-auto"
      />
    </template>
  </default-page-layout>
</template>

<script>
import ColorChoiceTable from '../components/ColorChoiceTable.vue'
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ImageSwitch from '../components/ImageSwitch.vue'

export const profileImageBasePath = '/images/Elektroschiene/Profile'

export default {
  name: 'e-track-profil',

  components: {
    ColorChoiceTable,
    DefaultPageLayout,
    ETrackBtn,
    ImageSwitch,
  },

  data () {
    return {
      tempPreview: null,
      profileImageBasePath,
    }
  },

  computed: {
    chosenColor () {
      return this.$store.state.etrack.configuration.color
    },

    chosenItem () {
      return this.$store.state.etrack.configuration.profil
    },

    config () {
      return this.$store.state.etrack.configuration
    },

    /**
     * Available profiles to choose from based on the previously selected type.
     *
     * @returns {array}
     */
    items () {
      return this.config.type
        ? this.$store.state.etrack.articleVariants[this.config.type.id].map(geometry => geometry)
        : []
    },

    /**
     * Title to show above the preview. Hover-item > current choice > default.
     *
     * @returns {string}
     */
    title () {
      return this.tempPreview?.profileName || this.chosenItem?.Geometry || this.$t('etrack.profileDefaultTitle')
    },

    /**
     * Subtitle to show aboce the preview. Hover-item > current choice > default.
     *
     * @returns {string}
     */
    subtitle () {
      return this.tempPreview?.colorName || this.chosenColor?.Name || this.$t('etrack.profileDefaultSubtitle')
    },
  },

  mounted () {
    // when there's already a chosen item (e.g. when switching back to this
    // step), we have to set the previously chosen color again for a fitting
    // view.
    if (this.chosenItem && this.chosenColor) {
      this.onColorChoice(this.chosenItem, this.chosenColor.ColorNumber)
    }
  },

  methods: {
    /**
     * Sets the chosen color for the given item, updates the store which holds
     * the current configuration.
     * When the profile or its color get changed, the user must execute the
     * following steps again. So we perform a reset and set the previously
     * chosen type again.
     *
     * @param {object} chosenItem
     * @param {string} color
     * @returns {void}
     */
    onColorChoice (chosenItem, color) {
      const profileColor = chosenItem.Colors.find(({ ColorNumber }) => ColorNumber === color)
      const { type, typeHintConfirmed } = { ...this.config }

      if (chosenItem.Geometry === this.chosenItem?.Geometry && color === this.chosenColor?.ColorNumber) {
        return
      }

      this.$store.commit('etrack/reset')
      this.$store.commit('etrack/setConfiguration', {
        color: profileColor,
        profil: { ...chosenItem },
        type,
        typeHintConfirmed,
      })
    },

    /**
     * When the user hovers a color of a profile we want to show a preview-
     * image even if the choice (via click) hasn't happened yet.
     *
     * @param {object} profile
     * @param {string} color
     * @returns {undefined}
     */
    showPreview (profile, color) {
      const profileColor = profile.Colors.find(({ ColorNumber }) => ColorNumber === color)

      this.tempPreview = {
        image: profileColor.Picture,
        colorName: profileColor.Name,
        profileName: profile.Geometry,
      }
    },
  }
}
</script>

<style lang="scss">
  .e-track-profil--wrap {
    .preview--wrap {
      margin-right: 5%;
      position: relative;

      h2 {
        text-transform: capitalize;
      }

      // headlines shouldn't be part of the layout-flow so the preview-image
      // is vertically aligned, not the whole content
      .headings--wrap {
        position: absolute;
        left: 0;
        top: -7rem;
      }
    }
  }
</style>
