import Assembly from './views/Assembly'
import ConfectionForm from './views/ConfectionForm'
import CurtainEngine from './views/CurtainEngine'
import CurtainCalculation from './views/CurtainCalculation/Index.vue'
import DecorWidth from './views/DecorWidth'
import EndPieces from './views/EndPieces'
import Engine from './views/Engine'
import ETrack from './Index'
import Finish from './views/Finish'
import GliderChoice from './views/GliderChoice'
import Intro from './views/Intro'
import Profil from './views/Profil'
import Summary from './views/Summary/SummaryOverview'
import Transmitter from './views/Transmitter'
import TypeChoice from './views/TypeChoice'

import etrack from '../../store/etrack'
import store from '../../store'

const guard = (requirement, fallback, next) =>
  requirement ? next() : next({ name: fallback })

const routes = [
  {
    path: '/e-schiene',
    name: 'e-track',
    component: ETrack,
    redirect: {
      name: 'ETrackIntro'
    },
    children: [
      {
        path: 'start/:configToLoad?',
        name: 'ETrackIntro',
        component: Intro,
        meta: { order: -1 },
      },
      {
        path: 'bedienung',
        name: 'ETrackTypeChoice',
        component: TypeChoice,
        meta: { order: 0 },
      },
      {
        path: 'profil',
        name: 'ETrackProfil',
        component: Profil,
        meta: {
          order: 1,
          requirement: () => etrack.state.configuration.type !== null && etrack.state.configuration.typeHintConfirmed,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackTypeChoice', next)
        },
      },
      {
        path: 'montage',
        name: 'ETrackAssembly',
        component: Assembly,
        meta: {
          order: 2,
          requirement: () => etrack.state.configuration.profil !== null,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackProfil', next)
        },
      },
      {
        path: 'profilenden',
        name: 'ETrackEndPieces',
        component: EndPieces,
        meta: {
          order: 3,
          requirement: () => etrack.state.configuration.assembly !== null
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackAssembly', next)
        },
      },
      {
        path: 'vorhang-motor',
        name: 'ETrackCurtainEngine',
        component: CurtainEngine,
        meta: {
          order: 4,
          requirement: () => etrack.state.configuration.endPieces !== null,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackEndPieces', next)
        },
      },
      {
        path: 'konfektionsform',
        name: 'ETrackConfectionForm',
        component: ConfectionForm,
        meta: {
          order: 5,
          requirement: () => etrack.state.configuration.driveCurtainPosition !== null,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackCurtainEngine', next)
        },
      },
      {
        path: 'ringe-gleiter',
        name: 'ETrackGliderChoice',
        component: GliderChoice,
        meta: {
          order: 6,
          requirement: () => etrack.state.configuration.confectionForm !== null,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackCurtainEngine', next)
        },
      },
      {
        path: 'dekorationsbreite',
        name: 'ETrackDecorWidth',
        component: DecorWidth,
        meta: {
          order: 7,
          requirement: () => etrack.state.configuration.confectionForm !== null,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackConfectionForm', next)
        },
      },
      {
        path: 'motoren',
        name: 'ETrackEngine',
        component: Engine,
        meta: {
          order: 8,
          requirement: () =>
            etrack.state.configuration.decorWidth > 0 && !store.getters['etrack/typeManualActive']
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackDecorWidth', next)
        },
      },
      {
        path: 'funksender',
        name: 'ETrackTransmitter',
        component: Transmitter,
        meta: {
          order: 9,
          requirement: () =>
            etrack.state.configuration.engine !== null && !store.getters['etrack/typeManualActive']
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackEngine', next)
        },
      },
      {
        path: 'zusammenfassung',
        name: 'ETrackSummary',
        component: Summary,
        meta: {
          order: 10,
          requirement: () => store.getters['etrack/typeManualActive']
            ? etrack.state.configuration.decorWidth > 0
            : etrack.state.configuration.engine !== null
          ,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackTransmitter', next)
        },
      },
      {
        path: 'vorhang-berechnung',
        name: 'ETrackCurtainCalculation',
        component: CurtainCalculation,
        meta: {
          order: 11,
          requirement: () => etrack.state.summarySeen,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackDecorWidth', next)
        },
      },
      {
        path: 'abschluss',
        name: 'ETrackFinish',
        component: Finish,
        meta: {
          order: 12,
          requirement: () => etrack.state.curtainCalculationSeen,
        },
        beforeEnter (to, from, next) {
          guard(to.meta.requirement(), 'ETrackTransmitter', next)
        },
      },
    ],
  },
]

export default routes
