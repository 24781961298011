<template>
  <div
    v-if="sessionIsActive"
    id="sideNavigation"
  >
    <portal v-if="$store.state.ng.title" to="nav-title">
      <span>{{ $store.state.ng.title }}</span>
    </portal>
    <ul
      class="sideNavigationInnerWrap listElements"
      :class="{ active }"
      @mouseenter="handleHover(true)"
      @mousemove="handleMove"
      @mouseleave="handleHover(false)"
      @click="handleClick"
    >
      <side-navigation-item
        route="e-schiene"
        :title="$t('pageNavigation.etrack')"
      >
        <template #icon>
          <img src="/images/Elektroschiene/elogo_gelb.svg" class="etrack-icon">
        </template>
      </side-navigation-item>
      <side-navigation-item
        route="suche"
        icon="configuration"
        icon-set="ico"
        icon-style="background-size: 80% auto"
        :title="$t('general.configurator')"
        :active="isAtRoute('Suche', 'Produkte', 'Einzelteile', 'Warenkorb', 'Garnitur')"
      />
      <side-navigation-item
        route="naehassistent"
        icon="w"
        icon-set="ico"
        icon-style="background-size: 128% auto"
        :title="$t('naehassistent.pageTitle')"
      />
      <side-navigation-item
        route="aufmass-assistent"
        icon="straighten"
        :title="$t('pageNavigation.measurements')"
      />
      <side-navigation-item
        route="material-shop"
        icon="trending_up"
        :title="$t('pageNavigation.marketing')"
      />
      <side-navigation-item
        route="produktinformationen"
        icon="import_contacts"
        :title="$t('assembly.pageTitleFull')"
        custom-class="icoNew"
      >
        <div class="entryMultiline">
          <span>{{ $t('pageNavigation.assemblyInstructions') }}</span>
          <span>{{ $t('pageNavigation.productInformation') }}</span>
        </div>
      </side-navigation-item>
      <side-navigation-item
        type="popover"
        icon="picture_as_pdf"
        :title="$t('pageNavigation.pdfCatalog.title')"
        @popover-show="popoverCount += 1"
        @popover-hide="popoverCount -= 1"
      >
        <template #popover>
          <ul class="listElements">
            <side-navigation-item
              type="extern"
              route="/download/pdf/Katalog_interstil.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.interstil')"
            />
            <side-navigation-item
              type="extern"
              route="/download/pdf/Katalog SMART.ELECTRIC DE.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.smartElectric')"
            />
            <side-navigation-item
              type="extern"
              route="/download/pdf/w_plus.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.rippleFold')"
            />
            <side-navigation-item
              type="extern"
              route="/download/pdf/interstil-F1-Flaechenvorhang-2014_de.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.f1')"
            />
            <side-navigation-item
              type="extern"
              route="/download/pdf/interstil-F1-Flaechenvorhang-netto-2021.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.f1Netto')"
            />
            <side-navigation-item
              type="extern"
              route="/download/pdf/Programmergaenzung_Schwarz.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.programmAdditionBlack')"
            />
            <side-navigation-item
              type="extern"
              route="/download/pdf/Aufklapper_Konfektion_6cm DE-P2022-02-99_view.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.wGlider6')"
            />
            <side-navigation-item
              type="extern"
              route="/download/pdf/Aufklapper_Konfektion_DE-P2016-21-99_view.pdf"
              icon-set="glyph"
              icon="download-alt"
              :title="$t('pageNavigation.pdfCatalog.wGlider8')"
            />
          </ul>
        </template>
      </side-navigation-item>
      <side-navigation-item
        route="meinKonto"
        icon="person"
        :title="$t('myAccount.pageTitle')"
        :info-badge="unreadNewsCount"
      >
        <span>{{ $t('myAccount.pageTitle') }}</span>/<span>{{ $t('userSettings.pageTitle') }}</span>
      </side-navigation-item>
      <side-navigation-item
        v-if="hasRole('ADMINPANEL.ACCESS.BACKEND')"
        type="extern"
        route="#/adminpanel"
        icon="tune"
        :title="$t('pageNavigation.adminpanel')"
      />
      <side-navigation-item
        type="button"
        custom-class="logout"
        icon="power_settings_new"
        :title="$t('pageNavigation.logout')"
        @click="logout"
      />

      <li class="qr-code-item">
        <mik-qr-code />
      </li>
    </ul>
  </div>
</template>

<script>
import isTouchDevice from '@/utilities/isTouchDevice'

import MikQrCode from '@/components/MikQrCode'
import SideNavigationItem from './SideNavigationItem'

let globalExpanded = false
let lastHovered = null
let hoverHandle = null

const expansionDelay = 500 // ms
const startRoute = 'Start' // route where the sidebar should be expanded always

function pathOf (e) {
  return e.path || e.composedPath?.() || (() => {
    const path = []
    let here = e.target
    while (here && here.parentNode && here.parentNode !== here) {
      path.push(here)
      here = here.parentNode
    }
    return path
  })()
}

function pathAbove (ref, e) {
  const path = pathOf(e)
  const index = path.indexOf(ref)
  return index > -1 ? path.slice(0, index) : path
}

export default {
  name: 'side-navigation',
  components: {
    MikQrCode,
    SideNavigationItem
  },
  data () {
    return {
      expanded: globalExpanded,
      expandHandle: null,
      movedOut: isTouchDevice,
      hasStartElement: false,
      popoverCount: 0,
    }
  },
  computed: {
    hasPopover () {
      return this.popoverCount > 0
    },
    active () {
      return this.expanded || this.hasPopover || this.isAtRoute(startRoute)
    },
    /**
     * Checks how many news haven't been read by the current user.
     *
     * @returns {number}
     */
    unreadNewsCount () {
      const user = this.$store.state.session.user
      const news = this.$store.state.news.news

      return user && news.length
        ? news.filter(entry => !user.newsRead.includes(entry._id)).length
        : 0
    },

    /**
     * Checks if the user is logged in.
     *
     * @returns {boolean}
     */
    sessionIsActive () {
      return this.$store.state.session.user !== null
    }
  },
  watch: {
    expanded (value) {
      this.expandHandle = setTimeout(() => {
        globalExpanded = value
      }, 256)
    },
  },
  mounted () {
    if (isTouchDevice) {
      document.body.addEventListener('touchstart', this.handleOutsideClick)
    } else {
      document.body.addEventListener('mousemove', this.checkFirstMove)
    }

    this.expandHandle = setTimeout(() => {
      this.expanded = false
    }, 256)
  },
  beforeDestroy () {
    if (this.expandHandle) clearTimeout(this.expandHandle)
  },
  destroyed () {
    if (isTouchDevice) {
      document.body.removeEventListener('touchstart', this.handleOutsideClick)
    } else {
      document.body.removeEventListener('move', this.checkFirstMove)
    }
  },
  methods: {
    hasRole (role) {
      return this.$store.getters['session/hasRole'](role)
    },

    isAtRoute (...route) {
      return [...route].includes(this.$router.currentRoute.name)
    },

    handleHover (hovered) {
      if (!this.movedOut && !hovered) {
        this.movedOut = true
      }

      if (hovered) {
        if (!hoverHandle && this.movedOut) {
          hoverHandle = setTimeout(() => {
            this.expanded = true
            hoverHandle = null
          }, expansionDelay)
        }
      } else {
        this.resetHoverHandle()
        this.expanded = false
      }
    },

    /**
     * Resets the currently delayed expansion of the navigation.
     *
     * @returns {void}
     */
    resetHoverHandle () {
      if (hoverHandle) {
        clearTimeout(hoverHandle)
        hoverHandle = null
      }
    },

    /**
     * If the sidebar isn't expanded, the first sidebar-click gets prevented
     * for touch-devices so no route-change happens immediately.
     * Further bubbling gets prevented in general so the existing listener on
     * 'document.body' isn't affected.
     *
     * @param {Event} e Click-event occured within the sidenavigation
     * @returns {void}
     */
    handleClick (e) {
      if (isTouchDevice && !this.active) {
        e.preventDefault()
        this.expanded = true
        this.resetHoverHandle()
      }

      e.stopPropagation()
    },

    handleOutsideClick (event) {
      // Check if "outside" click is actually outside of the sidenavigation
      if (!$(event.target).closest('ul.sideNavigationInnerWrap').length) {
        this.expanded = false
      }
    },

    getItemAt (e) {
      return pathAbove(this.$el, e).find(el => el.tagName === 'LI')?.children?.[0]?.title || null
    },

    handleMove (e) {
      if (!this.hasStartElement) return
      if (hoverHandle) return

      const element = this.getItemAt(e)
      if (element !== lastHovered) {
        lastHovered = element
        this.movedOut = true
        this.expanded = element !== null
      }
    },

    checkFirstMove (e) {
      this.hasStartElement = true
      document.body.removeEventListener('mousemove', this.checkFirstMove)
      const element = this.getItemAt(e)
      if (element) {
        lastHovered = element
      } else {
        this.movedOut = true
        lastHovered = null
      }
    },

    async logout () {
      await this.$store.dispatch('session/logout')
      this.$router.push({ name: 'Login' })
    }
  }
}

</script>

<style lang="scss">
@import '@/styles/_macros';

#sideNavigation {
  width: $menuItemWidth;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;

  + .navigationBlock {
    // compensate width of #sideNavigation
    padding-left: $menuItemWidth;
    display: flex;
    flex-direction: column;
    // allow scrolling for extra long content
    overflow-y: auto;
  }

  .sideNavigationInnerWrap {
    width: 100%;
    height: 100%;
    background-color: $navbarBgColor;
    position: absolute;
    z-index: 2;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .qr-code-item {
      opacity: 0;
      display: none;
      justify-content: center;
      margin-top: auto;
      margin-bottom: 20px;
      @include transition();
    }

    @include transition();

    &:focus,
    &.active {
      @include sideNavigationInnerWrapActiveXS();

      .qr-code-item {
        opacity: 1;
        display: inline-flex;
      }
    }

    > li {
      width: 100%;
      // needed for proper positioning of .entryMobileActiveToggle
      position: relative;
      z-index: 1;

      a,
      .btn {
        padding: 0;
        border: none;
        background-color: $navbarBgColor;
        color: $colDefault;
        align-items: stretch;

        .entryIcon {
          // counter for basket, favorites
          .badge {
            position: absolute;
            top: 18px;
            right: 6px;
            z-index: 1;
          }

          .etrack-icon {
            max-width: 36px;
            margin: auto;
          }
        }

        .entryWrap {
          max-width: 100%;
          overflow: hidden;
          align-items: center;

          .entryText {
            max-width: 100%;
            padding: 0 0.5rem;
            // prevent line-break during transition
            white-space: nowrap;
            overflow: hidden;
          }
        }

        &.logout {
          border: none;
        }

        &:hover,
        &.active {
          background-color: lighten($navbarBgColor, 8%);

          .entryIcon {
            background-color: rgba($colDefault, 0.08);
          }
        }

        // prevent ugly outline
        &:focus {
          outline: none;
        }

        &.icoNew {

          .entryIcon {

            &::after {
              content: 'neu';
              // calculate fixed font-size
              font-size: ($font_size_xs / 1rem * 16) + px;
              padding: 0 0.125rem 0.125rem 0.125rem;
              background-color: rgba($menuItemNewBgColor, 0.8);
              color: $darkFontColor;
              position: absolute;
              bottom: 0.675rem;
              right: 0.25rem;
              z-index: 1;
            }
          }
        }
      }

      // trigger for activation of navigation on touch-enabled devices
      // has to be placed outside anchor
      .entryMobileActiveToggle {
        width: $menuItemWidth;
        height: $menuItemWidth;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
      }
    }

    .popover {
      width: 288px;
      max-width: 288px;
      border-radius: 0;
      background-color: lighten($navbarBgColor, 8%);

      .popover-content-custom {
        ul {
          li {
            a {
              min-height: 0;
              padding: 1rem;
              border: none;
              background-color: transparent;
              color: $lightFontColor;
              font-size: $font_size_normal;
              text-transform: none;

              .entryIcon {
                width: 24px;
                font-size: inherit;
              }

              .entryWrap {
                .entryText {
                  padding: 0 0.5rem;
                }
              }

              &:hover {
                background-color: lighten($navbarBgColor, 16%);
              }
            }
          }
        }
      }

      &.bottom {
        > .arrow {
          &::after {
            border-bottom-color: $darkFontColor;
          }
        }
      }
    }
  }

  .sideNavigationImage {
    height: 70px;
    padding: 25px 5px 26px;
  }
}

.navigationBlock {
  .navContentWrap {
    width: 100%;
    padding: 0 10px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    // needed for animation of cover for navContentWrap in garnitur list
    // animation has been broken and still is broken.
    position: relative;
    z-index: 1;

    .badge {
      &.info-badge {
        margin-left: 0.25rem;
        align-self: center;
      }
    }
  }

  .subNavigation {
    margin: 0 -10px;
  }
}

@media (min-width: $screen-lg) {
  #sideNavigation {
    .sideNavigationInnerWrap {
      &.active {
        @include sideNavigationInnerWrapActiveLG();
      }
    }
  }
}

// use hover when device has hover support
@media (hover: hover) {
  #sideNavigation {
    .sideNavigationInnerWrap {
      &.active {
        @include sideNavigationInnerWrapActiveXS();
      }
      > li {
        // hide clickable trigger, only used for navigation on touch-enabled devices
        .entryMobileActiveToggle {
          display: none;
        }
      }
    }
  }
}

@media (min-width: $screen-lg) and (hover: hover) {
  #sideNavigation {
    .sideNavigationInnerWrap {
      &.active {
        @include sideNavigationInnerWrapActiveLG();
      }
    }
  }
}

#sideNavigation .sideNavigationInnerWrap.active .entryMobileActiveToggle {
  display: none;
}
</style>
