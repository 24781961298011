
export default {
  namespaced: true,
  state: {
    references: {
      commissionId: ''
    },
    address: '',
    selectedAddressId: '',
  },
  getters: {
    order: (state) => {
      const order = (decodeURIComponent(document.cookie || '')
        .split('; ')
        .find(row => row.startsWith('order=')) || '')
        .split('=')[1]

      return JSON.parse(order)
    },
  },
  mutations: {
    setCommissionId (state, commissionId) {
      state.references.commissionId = commissionId
    },
    setAddress (state, address) {
      state.address = address
    },
    setSelectedAddressId (state, addressId) {
      state.selectedAddressId = addressId
    },
  },
  actions: {
    updateOrder(context, data) {
      context.commit('setCommissionId', data.references.commissionId)
      context.commit('address', data.address)
      context.commit('selectedAddressId', data.addressId)
    }
  },
}
