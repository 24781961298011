<template>
  <div class="salt-hint-list">
    <transition-group name="slide-fade">
      <div class="salt-hint vue" :class="' salt-hint-' + hint.type"
        v-for="hint, key in hints"
        :key="hint.id"
        ref="hint"
        @mouseenter="stopTimer(hint)"
        @mouseleave="startTimer(hint)">
        <p class="title" v-html="hint.title"></p>
        <p class="content" v-html="hint.message"></p>
        <button class="btn close" @click="hint.close(key)" v-show="!hint.hideClose">
          <i class="material-icons">close</i>
        </button>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Hint',
  data () {
    return {
      hints: [],
    }
  },
  methods: {
    getId () {
      return this.hints.length > 0
        ? this.hints[this.hints.length -1].id + 1
        : 0
    },

    /**
     * Fügt einen Hinweis hinzu.
     *
     * @param {object} hint - Das Hint-konfigurationsobjekt
     * @param {string} hint.title - Titelzeile des Hinweises
     * @param {string} hint.message - Nachrichtenzeile des Hinweises
     * @param {boolean} [hint.hideClose=false] - Gibt an ob der Schließen-Button ausgeblendet werden soll
     * @param {function} [hint.close=noop] - Callback, wenn der Hint geschlossen wurde
     * @param {number} [hint.timeout=5000] - Anzeigedauer des Hinweises (0 = immer)
     */
    add (hint) {
      const externalClose = hint.close
      hint.id = this.getId()

      hint.close = id => {
        // Setzt die definitive Höhe für die CSS transition
        externalClose?.call(this)
        this.remove(hint)
      }

      this.hints.push(hint)
      this.startTimer(hint)
    },

    /**
     * Entfernt einen Hinweis.
     *
     * @param {object} hint - Das Hint-konfigurationsobjekt
     * @param {string} hint.title - Titelzeile des Hinweises
     * @param {string} hint.message - Nachrichtenzeile des Hinweises
     * @param {boolean} [hint.hideClose=false] - Gibt an ob der Schließen-Button ausgeblendet werden soll
     * @param {function} [hint.close=noop] - Callback, wenn der Hint geschlossen wurde
     * @param {number} [hint.timeout=5000] - Anzeigedauer des Hinweises (0 = immer)
     */
    remove (hint) {
      const i = this.hints.indexOf(hint)
      if (i === -1) return
      this.hints.splice(i, 1)
    },

    /**
     * Startet einen Timer, sofern `timeout` gesetzt wurde.
     *
     * @param {object} hint - Das Hint-konfigurationsobjekt
     * @param {string} hint.title - Titelzeile des Hinweises
     * @param {string} hint.message - Nachrichtenzeile des Hinweises
     * @param {boolean} [hint.hideClose=false] - Gibt an ob der Schließen-Button ausgeblendet werden soll
     * @param {function} [hint.close=noop] - Callback, wenn der Hint geschlossen wurde
     * @param {number} [hint.timeout=5000] - Anzeigedauer des Hinweises (0 = immer)
     */
    startTimer (hint) {
      if (hint.timeout === 0) return
      hint.timeout = hint.timeout > 0
          ? hint.timeout
          : 5000

      hint.timeoutId = setTimeout(ctx => {
        ctx.remove.call(ctx, hint)
      }, hint.timeout, this)
    },

    /**
     * Stoppt einen Timer, sofern der Timer gestartet wurde.
     *
     * @param {object} hint - Das Hint-konfigurationsobjekt
     * @param {string} hint.title - Titelzeile des Hinweises
     * @param {string} hint.message - Nachrichtenzeile des Hinweises
     * @param {boolean} [hint.hideClose=false] - Gibt an ob der Schließen-Button ausgeblendet werden soll
     * @param {function} [hint.close=noop] - Callback, wenn der Hint geschlossen wurde
     * @param {number} [hint.timeout=5000] - Anzeigedauer des Hinweises (0 = immer)
     */
    stopTimer (hint) {
      if (!hint.timeoutId) return
      clearTimeout(hint.timeoutId)
    },

    /**
     * Returns currently registered/active hints.
     *
     * @returns {array}
     */
    getCurrentHints () {
      return this.hints
    },
  }
}
</script>
