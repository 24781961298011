var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",staticClass:"dot-navigation--wrap"},[(_vm.hasPrev)?_c('div',{staticClass:"nav-affix left"},[_c('span',{staticClass:"material-icons"},[_vm._v(" navigate_before ")])]):_vm._e(),_c('div',{ref:"content",staticClass:"dot-navigation--content",style:({
      transform: `translateX(${_vm.scrollOffset}px)`
    })},[_vm._l((_vm.entries),function({ label, route, isHidden },i){return _c('router-link',{key:i,staticClass:"dot-navigation--entry",class:{
        allowed: _vm.routeAllowed(route),
        'is--hidden': isHidden
      },attrs:{"to":{ name: route },"tag":"div"}},[_c('span',{staticClass:"dot"}),_vm._v(" "+_vm._s(label)+" ")])}),_c('div',{staticClass:"nav-slider",style:({
        left: `${_vm.sliderLeft}px`,
        width: `${_vm.sliderWidth}px`,
      })})],2),(_vm.hasNext)?_c('div',{staticClass:"nav-affix right"},[_c('span',{staticClass:"material-icons"},[_vm._v(" navigate_next ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }