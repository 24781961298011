import etrackStore from '@/store/etrack.js'

const formatWidening = widening =>
  widening % 1 === 0
    ? widening
    : widening.toFixed(1).replace('.', ',')

export default {
  /**
   * Each package of a curtain has a specific type based on the wall- and
   * curtain-configuration which is relevant for the calculations of the
   * preview-images. Rules:
   *
   * - Type A = End of the curtain-package is at a wall
   * - Type B = End of the curtain-package is freestanding
   *
   * @returns {object}
   */
  getConfections () {
    const { configuration } = etrackStore.state
    const { id: wall } = configuration.endPieces
    const hasWallLeft = wall === 'wal' || wall === 'ww'
    const hasWallRight = wall === 'war' || wall === 'ww'

    if (configuration.driveCurtainPosition.curtainType === 'tm') {
      // curtain is splitted, has a package at each side
      return {
        left: { isFreestanding: !hasWallLeft, isSplitted: true },
        right: { isFreestanding: !hasWallRight, isSplitted: true },
      }
    } else {
      // curtain has only one package at one side
      return configuration.driveCurtainPosition.curtainType === 'tl'
        ? {
          left: { isFreestanding: !hasWallLeft, isSplitted: false },
          right: null,
        }
        : {
          left: null,
          right: { isFreestanding: !hasWallRight, isSplitted: false }
        }
    }
  },

  /**
   * The preview-image depends on mutiple choices of the configuration-process.
   *
   * @returns {string} Path to the image for the current configuration
   */
  getPreviewImage () {
    const { configuration } = etrackStore.state

    const confection = configuration.confectionForm.id
    const split = configuration.driveCurtainPosition.curtainType
    const engine = configuration.driveCurtainPosition.enginePosition || 'mo'
    const wall = configuration.endPieces.id
    const filename = `${confection}_${engine}_${wall}_${split}.jpg`

    return `/images/Elektroschiene/CurtainCalculation/vorhaenge/${filename}`
  },

  calculations: {
    curly (freestanding = false, splitted = false) {
      const { state } = etrackStore
      const { articleConfig } = state
      const wallDistance = etrackStore.getters.wallDistance(state)
      const rodLength = parseFloat(articleConfig.RodLength.replace(',', '.'))

      return {
        sewingWidth: (splitted ? rodLength / 2 : rodLength) + 5,
        widening: freestanding ? formatWidening(2 + wallDistance) : null,
      }
    },

    pleat (freestanding = false, splitted = false) {
      const { state } = etrackStore
      const { articleConfig } = etrackStore.state

      const rodLength = parseFloat(articleConfig.RodLength.replace(',', '.'))
      const wallDistance = etrackStore.getters.wallDistance(state)
      const width = splitted ? rodLength / 2 : rodLength

      return {
        sewingWidth: width + 5,
        widening: freestanding ? formatWidening(wallDistance + 6) : null
      }
    },

    wave (freestanding = false, splitted = false) {
      const { state } = etrackStore
      const { articleConfig, configuration } = etrackStore.state
      const { addition, overhang } = configuration.curtainAddition

      const rodLength = parseFloat(articleConfig.RodLength.replace(',', '.'))
      const width = splitted ? rodLength / 2 : rodLength
      const divided = width / 8 // divide by a fixed value
      const rounded = Math.ceil(divided) | 1 // round to next odd number
      const multiplied = rounded * 8 // multiply by a fixed value
      const withAddition = multiplied * addition + 2 // multiply with chosen addition-factor, add 2
      const sewingWidth = withAddition + overhang * 2 // finally add overhang of the chosen addition twice
      const wallDistance = etrackStore.getters.wallDistance(state)

      return {
        sewingWidth,
        widening: freestanding ? formatWidening(wallDistance - overhang) : null,
        gliderAmount: rounded + 1,
      }
    },
  },
}
