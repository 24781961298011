<template>
  <default-page-layout
    :heading="$t('etrack.curtainTitle')"
    :loading="isLoading"
    class="e-track-curtain--wrap"
  >
    <image-switch
      :default="`${isTypeManual ? curtainImageBasePath : driveCurtainImageBasePath}/prev.jpg`"
      :primary="tempPreview ? tempPreview.preview : null"
      :secondary="chosenDriveCurtainPosition ? chosenDriveCurtainPosition.preview : null"
      height-aware
    />

    <template #aside>
      <div class="position-relative">
        <div
          class="row d-flex flex-wrap"
          :class="{
            'cross-underlay': options.length <= 4
          }"
        >
          <div
            v-for="(option, i) in options"
            :key="option.id"
            class="col-md-6 d-flex"
          >
            <e-track-card
              :is-chosen="chosenDriveCurtainPosition && chosenDriveCurtainPosition.id === option.id"
              :left-indicator="i % 2 === 0"
              :loading="isLoading"
              class="flex-auto"
              @mouseenter.native="tempPreview = option"
              @mouseleave.native="tempPreview = null"
              @click="setDriveCurtainPosition(option)"
            >
              <template #title>
                <div :class="`text-${option.align}`">
                  <div>
                    {{ $t(`etrack.curtainTypes.${option.curtainType}`) }}
                  </div>
                  <div v-if="!isTypeManual" class="text-subtitle">
                    {{ $t(`etrack.enginePositions.${option.enginePosition}`) }}
                  </div>
                </div>
              </template>
              <img :src="option.image" class="img-responsive preview-image">
            </e-track-card>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackEndPieces"
        :text="$t('etrack.prev')"
      />

      <e-track-btn
        to="ETrackConfectionForm"
        :disabled="chosenDriveCurtainPosition === null"
        :loading="isLoading"
        :tooltip="chosenDriveCurtainPosition === null ? $t('etrack.choiceNeeded') : null"
        :text="$t('etrack.next')"
        class="ml-auto"
      />
    </template>
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ImageSwitch from '../components/ImageSwitch.vue'

const curtainImageBasePath = '/images/Elektroschiene/Curtain'
const driveCurtainImageBasePath = '/images/Elektroschiene/DriveCurtainPositions'

export default {
  name: 'e-track-curtain',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ImageSwitch,
  },

  data () {
    return {
      curtainImageBasePath,
      driveCurtainImageBasePath,
      isLoading: false,
      tempPreview: null,
    }
  },

  computed: {
    chosenDriveCurtainPosition () {
      return this.$store.state.etrack.configuration.driveCurtainPosition
    },

    /**
     * Current configuration based on the choices of the user.
     *
     * @returns {object}
     */
    configuration () {
      return this.$store.state.etrack.configuration
    },

    isTypeManual () {
      return this.$store.getters['etrack/typeManualActive']
    },

    /**
     * The API only offers a simple list of engine-positions and curtain-types
     * combined in strings (e.g 'ml_tl' = 'Motor links, Teilung links'). So we
     * have do some manipulations which must behave differently based on the
     * current article-type (electric/manual).
     *
     * @returns {array}
     */
    options () {
      const options = this.$store.state.etrack.articleConfig?.PossibleDriveCurtainPositions || []
      const alignByEngine = { ml: 'left', mr: 'right' }
      const alignByCurtain = { tl: 'left', tr: 'right', tm: 'center' }

      // the API sends always the same options - but we need only a subset when
      // the 'manual'-mode is chosen. So we have to do some trickery here.
      if (this.isTypeManual) {
        return options
          // extract curtain-types for the view, but keep original ids for communication with the api
          .map(id => ({ id, curtainType: id.split('_')[1] }))
          // we have to remove duplicated curtain-types
          .filter((v, i, arr) => arr.findIndex(({ curtainType }) => v.curtainType === curtainType) === i)
          // ..and finally add the related preview-images
          .map(option => ({
            ...option,
            image: `${curtainImageBasePath}/${option.curtainType}.jpg`,
            preview: `${curtainImageBasePath}/prev_${option.curtainType}.jpg`,
            align: alignByCurtain[option.curtainType],
          }))
      }

      return options.map(id => {
        const [enginePosition, curtainType] = id.split('_')

        return {
          id,
          curtainType,
          enginePosition,
          image: `${driveCurtainImageBasePath}/${id}.jpg`,
          preview: `${driveCurtainImageBasePath}/prev_${id}.jpg`,
          align: alignByEngine[enginePosition],
        }
      })
    },
  },

  methods: {
    /**
     * Tells the API which drive and curtain-position was chosen and stores the
     * ID additionally in the client since the article-configuration (of the
     * API) doesn't contain the choice.
     *
     * @param {object} option
     * @returns {Promise}
     */
    async setDriveCurtainPosition (option) {
      this.isLoading = true
      const res = await this.$store.dispatch('etrack/postArticleConfig', {
        Key: 'DriveCurtainPosition',
        Value: option.id
      })

      if (res && res.ReturnCode === 'Ok') {
        this.$store.commit('etrack/setConfigEntry', { key: 'driveCurtainPosition', value: option })
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
  .e-track-curtain--wrap {
    .e-track-card--wrap {
      margin: 0 auto;

      .e-track-card {
        .card-title {
          margin: 0;
        }

        .card-content {
          padding: 0;
        }
      }

      .preview-image {
        margin: 0 auto;
      }
    }
  }
</style>
