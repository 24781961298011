import SaltModal from '@/components/Modal'
import i18n from '@/i18n.js'

export default {
  install(Vue, options) {
    Vue.prototype.Modal = {
      current: null,
      show: (options = {}, onShown) => {
        const component = Vue.extend({
          template: `
            <salt-modal ref="modal"
              :type="type"
              :title="title"
              :target="target"
              :message="message"
              :modalClass="modalClass"
              :bodyClass="bodyClass"
              :hideAfter="hideAfter"
              :hideOnEsc="hideOnEsc"
            >
              <div v-if="template" slot="body" v-html="template"></div>

              <div v-if="buttons.length > 0" slot="footer">
                <button v-for="button in buttons" type="button" class="btn btn-default" :class="button.cssClass" @click="handleClick(button.action)">
                  <i v-if="button.iconName" class="material-icons">{{button.iconName}}</i>
                  {{ button.label }}
                </button>
              </div>
            </salt-modal>
          `,
          components: {
            SaltModal,
          },
          data() {
            return {
              template: options.template || '',
              buttons: options.buttons || [],
            }
          },
          props: [
            'type',
            'target',
            'title',
            'message',
            'modalClass',
            'bodyClass',
            'hideAfter',
            'hideOnEsc',
          ],
          methods: {
            handleClick(action) {
              action(Vue.prototype.Modal.current.hide)
            },
          },
        })
        const instance = new component({
          i18n: i18n,
        })

        instance.$mount()
        document.body.appendChild(instance.$el)

        const originalProps = instance.$props

        instance.type = options.type ?? originalProps.type
        instance.title = options.title ?? originalProps.title
        instance.target = options.target ?? originalProps.target
        instance.message = options.message ?? originalProps.message
        instance.modalClass = options.modalClass ?? originalProps.modalClass
        instance.bodyClass = options.bodyClass ?? originalProps.bodyClass
        instance.hideAfter = options.hideAfter ?? originalProps.hideAfter
        instance.hideOnEsc = options.hideOnEsc ?? originalProps.hideOnEsc

        instance.$refs.modal.show()
        Vue.prototype.Modal.current = instance.$refs.modal
      },
      hide: (onHidden) => {
        if (Vue.prototype.Modal.current) {
          if (typeof Vue.prototype.Modal.current.hide === 'function') {
            Vue.prototype.Modal.current.hide(onHidden)
          }
        }
      },
    }
  },
}
