<template>
  <default-page-layout
    :heading="$t('etrack.transmitters')"
    :loading="isLoading"
    class="e-track-transmitter--wrap"
  >
    <div class="transmitter-choice d-flex">
      <e-track-card
        v-for="transmitter in availableTransmitters"
        :key="transmitter.ArticleNumber"
        :is-chosen="transmitterIsChosen(transmitter)"
        left-indicator
        :loading="isLoading"
      >
        <template #title>
          <div class="text-center">
            {{ transmitter.ArticleName }}
          </div>
        </template>
        <div class="d-flex justify-center">
          <img :src="`/images/${transmitter.Picture}`" class="img-responsive preview-image">
        </div>

        <template #footer>
          <div class="mb-6 text-subtitle text-center">
            {{ transmitter.AdditionalText2 }}
          </div>
          <div class="d-flex align-center justify-center">
            <e-track-input
              :value="transmitter.count"
              type="number"
              min="0"
              step="1"
              text
              class="amount-input mr-2"
              custom-spinner
              @input="count => setTransmitterCount(transmitter, count)"
            />

            x {{ readablePrice(transmitter.Price) }}
          </div>
        </template>
      </e-track-card>
    </div>

    <template #aside>
      <div class="position-relative">
        <div class="d-flex">
          <img
            class="logo--somfy mx-auto"
            :src="require('../assets/logo_somfy.jpg')"
          >
        </div>

        <confirm-overlay
          v-if="chosenTransmitters.length === 0"
          v-model="noTransmitterConfirmed"
        >
          {{ $t('etrack.withoutTransmitterHint') }}
        </confirm-overlay>
      </div>
    </template>

    <template #footer>
      <e-track-btn
        to="ETrackEngine"
        :text="$t('etrack.prev')"
      />

      <div class="mx-auto">
        <price-box v-if="!isLoading" />
      </div>

      <e-track-btn
        to="ETrackSummary"
        :disabled="!nextStepAllowed"
        :tooltip="nextStepAllowed ? null : $t('etrack.choiceNeeded')"
        :text="$t('etrack.next')"
        :loading="isLoading"
      />
    </template>
  </default-page-layout>
</template>

<script>
import ConfirmOverlay from '../components/ConfirmOverlay.vue'
import DefaultPageLayout from '../components/DefaultPageLayout'
import ETrackBtn from '../components/ETrackBtn'
import ETrackCard from '../components/ETrackCard'
import ETrackInput from '../components/ETrackInput'
import PriceBox from '../components/PriceBox'

import _ from 'underscore'

export default {
  name: 'e-track-transmitter',

  components: {
    ConfirmOverlay,
    DefaultPageLayout,
    ETrackBtn,
    ETrackCard,
    ETrackInput,
    PriceBox,
  },

  data () {
    return {
      isLoading: false,
      noTransmitterConfirmed: false,
    }
  },

  computed: {
    /**
     * The transmitters to choose from are a part of the previously chosen
     * engine. If a transmitter is already added (e.g. when switching back to
     * this step) we have to apply the stored amount.
     *
     * @returns {array}
     */
    availableTransmitters () {
      const engine = this.$store.state.etrack.configuration.engine
      const chosenParts = this.$store.state.etrack.articleConfig.AdditionalParts

      if (!engine) {
        return []
      }

      return engine.WirelessController.map(controller => {
        const count = chosenParts.find(({ ArticleNr }) => ArticleNr === controller.ArticleNumber)?.Count || 0
        return { ...controller, count }
      })
    },

    chosenTransmitters () {
      const chosenParts = this.$store.state.etrack.articleConfig.AdditionalParts

      return this.availableTransmitters.reduce((chosenTransmitters, transmitter) => {
        const relatedPart = chosenParts.find(({ ArticleNr }) => ArticleNr === transmitter.ArticleNumber)
        return relatedPart === undefined || parseInt(relatedPart.Count) === 0
          ? chosenTransmitters
          : [...chosenTransmitters, transmitter]
      }, [])
    },

    currency () {
      return this.$store.state.session.catalog.currency
    },

    nextStepAllowed () {
      return this.chosenTransmitters.length > 0 || this.noTransmitterConfirmed
    },
  },

  watch: {
    chosenTransmitters () {
      this.noTransmitterConfirmed = false
    }
  },

  methods: {
    /**
     * Requests the api to add a transmitter to the part-list or updates the
     * amount of an already added one.
     *
     * Additionally the current choice gets stored within the client for other
     * views like the summary since the entries of the part-list don't have a
     * type which makes it too hard to distinguish those.
     *
     * @param {object} transmitter
     * @param {number} count
     * @returns {Promise}
     */
    setTransmitterCount: _.throttle(async function (transmitter, count) {
      this.isLoading = true

      await this.$store.dispatch('etrack/putPart', {
        setArticleNo: transmitter.ArticleNumber,
        color: transmitter.Colors[0].Key,
        count,
      })

      this.$store.commit('etrack/setConfigEntry', { key: 'transmitters', value: this.chosenTransmitters })
      this.isLoading = false
    }, 700),

    /**
     * Formats the given price so it's readable by the user.
     *
     * @param {number} price
     * @returns {string}
     */
    readablePrice (price) {
      return price
        .toFixed(2)
        .replace('.', ',')
        .concat(` ${this.currency}`)
    },

    /**
     * Checks if the given transmitter is currently chosen (an item within the
     * part-list of the current article-configuration).
     *
     * @param {object} transmitter
     * @returns {boolean}
     */
    transmitterIsChosen (transmitter) {
      return this.chosenTransmitters.find(({ ArticleNumber }) =>
        ArticleNumber === transmitter.ArticleNumber
      ) !== undefined
    }
  },
}
</script>

<style lang="scss">
  .e-track-transmitter--wrap {
    .logo--somfy {
      max-width: 110px;
    }

    .main-content {
      .custom-content-grid {
        .col-content {
          flex: 0 0 70%;
          max-width: 70%;
        }
        .col-options {
          flex: 0 0 30%;
          max-width: 30%;
        }
      }
    }

    .transmitter-choice {
      .e-track-card--wrap {
        flex: 1;
        max-width: 300px;
        margin: 0 auto;

        .card-content {
          display: flex;
          flex-direction: column;

          img {
            max-height: 20vh;
            margin: auto;
          };
        }

        .card-footer {
          .amount-input {
            max-width: 80px;
          }
        }
      }
    }

    .confirm-overlay--wrap {
      border: none;
      border-left: 4px solid #ccc;

      .confirm-overlay-content {
        margin: 0 0 36px 0;
      }
    }
  }

  @media (min-width: $screen-lg) {
    .e-track-transmitter--wrap {
      .logo--somfy {
        max-width: 150px;
      }
    }
  }
</style>
