export default {
  namespaced: true,
  state: {
    showControls: JSON.parse(localStorage?.getItem('rohrbiegung.showControls') || 'false'),
  },
  getters: {
  },
  mutations: {
    showControls (state, show) {
      state.showControls = show
      localStorage?.setItem('rohrbiegung.showControls', JSON.stringify(show))
    },
  },
  actions: {
  }
}
