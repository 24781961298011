import Colors from '@/services/ColorService.js'
import http from '@/utilities/http'
import Vue from "vue";
import i18n from '@/i18n.js'

export default {
  namespaced: true,
  state: {
    productTypes: [
      {
        type: 'F1',
        name: 'Flächenvorhang F1',
        checked: false,
      },
      {
        type: 'WCollection',
        name: 'Wellenvorhang',
        checked: false,
      }, {
        type: 'Traversing',
        name: 'Innenlaufsysteme',
        checked: false,
      }, {
        type: 'CurtainRods',
        name: 'Vorhangstangen',
        checked: false,
      },
    ],
    assemblyTypes: [
      {
        type: 'WallMount',
        name: 'Wandmontage',
        checked: false,
      }, {
        type: 'CeilingMount',
        name: 'Deckenmontage',
        checked: false,
      },
    ],
    geometries: [
      {
        type: 'rund',
        name: 'rund',
        checked: false,
      }, {
        type: 'quadratisch',
        name: 'quadratisch',
        checked: false,
      }, {
        type: 'flach',
        name: 'flach',
        checked: false,
      }, {
        type: 'konvex/konkav',
        name: 'konvex/konkav',
        checked: false,
      },
    ],
    colors: Colors,
    diametersMM: [],
    diametersFlat: [],
    diametersSquare: [],
    diametersConvex: [],
    diametersOther: [],
    rodCounts:[],
  },
  actions: {
    async init ({ commit, state }) {
      if (state.initialized) {
        return
      } else {
        state.initialized = true
      }

      http.getJSON('api/article/search/diameters').then(({ Items }) => {
        for (const item of Items) {
          const diameter = { diameter: item, checked: false }

          if (/^flach*/i.test(diameter.diameter)) {
            commit('addDiameter', { field: 'diametersFlat', value: diameter })
          } else if (/^quadratisch*/i.test(diameter.diameter)) {
            commit('addDiameter', { field: 'diametersSquare', value: diameter })
          } else if (/^konvex*/i.test(diameter.diameter)) {
            commit('addDiameter', { field: 'diametersConvex', value: diameter })
          } else if (/\d*mm/.test(diameter.diameter)) {
            commit('addDiameter', { field: 'diametersMM', value: diameter })
          } else {
            commit('addDiameter', { field: 'diametersOther', value: diameter })
          }
        }
      }).catch(Vue.prototype.Hint.requestError)

      try {
        let { Items } = await http.getJSON('api/article/search/rodcounts')

        Items.map(item =>
          commit('addRodCount', {
            type: item,
            name: item + i18n.t('search.rodCountsSuffix'),
            checked: item == '1' // Standard: 1-läufig
          })
        )
      }
      catch(ex) {
        Vue.prototype.Hint.requestError(ex)
      }
    },
  },
  mutations: {
    addDiameter (state, { field, value }) {
      state[field].push(value)
    },
    addRodCount(state, value) {
      state.rodCounts.push(value)
    },
  },
  getters: {
    filters: (state) => {
      return state
    }
  }
}
