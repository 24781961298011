<template>
  <default-page-layout :heading="$t('etrack.summary')" class="e-track-summary-overview--wrap">
    <summary-row
      :title="$t('etrack.typeChoice')"
      :value="config.type.title"
      :image="config.type.image"
      route-name="ETrackTypeChoice"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.profile')"
      :value="config.profil.Geometry"
      :image="`${profileImageBasePath}/columns/${config.profil.Geometry}-aktiv.svg`"
      route-name="ETrackProfil"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.color')"
      :value="config.color.Name"
      :image="`${profileImageBasePath}/oberflaechen/${config.color.ColorNumber}.jpg`"
      route-name="ETrackProfil"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.assembly')"
      :value="assemblySummary.value"
      :image="assemblySummary.image"
      route-name="ETrackAssembly"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.endPieces')"
      :value="config.endPieces.title"
      :image="config.endPieces.image"
      route-name="ETrackEndPieces"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.curtainTitle')"
      :value="driveCurtainSummary.title"
      :image="driveCurtainSummary.image"
      route-name="ETrackCurtainEngine"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.confectionForm')"
      :value="config.confectionForm.title"
      :image="config.confectionForm.image"
      route-name="ETrackConfectionForm"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.glider')"
      :image="config.gliderImage"
      :value="articleConfig.RingColorSelected"
      route-name="ETrackGliderChoice"
      @click:image="showEntryDetails"
    />

    <summary-row
      :title="$t('etrack.decorWidth')"
      :value="`${config.decorWidth} cm`"
      route-name="ETrackDecorWidth"
    />

    <summary-row
      v-if="!isTypeManual && config.engine"
      :title="$t('etrack.engine')"
      :value="`${config.engine.ArticleName}: ${config.engine.AdditionalText2}`"
      :image="`/images/${config.engine.Picture}`"
      route-name="ETrackEngine"
      @click:image="showEntryDetails"
    />

    <summary-row
      v-if="!isTypeManual && batterySuppliesSummary"
      :title="$t('etrack.additionalOptions')"
      :value="batterySuppliesSummary"
      route-name="ETrackEngine"
      @click:image="showEntryDetails"
    />

    <summary-row
      v-if="!isTypeManual"
      :title="$t('etrack.transmitters')"
      :value="transmitterSummary()"
      route-name="ETrackTransmitter"
      @click:image="showEntryDetails"
    />

    <div
      v-if="!isTypeManual"
      class="d-flex flex-wrap align-center mt-6"
    >
      <a
        v-for="({ label, link }, i) in videos"
        :key="i"
        :href="`//${link}`"
        target="_blank"
        class="video-link d-flex align-center mr-4"
      >
        <span class="material-icons mr-2">
          play_circle_outline
        </span>
        <span class="video-label">
          {{ label }}
        </span>
      </a>
    </div>

    <template #footer>
      <e-track-btn
        :to="previousStep"
        :text="$t('etrack.prev')"
      />

      <div class="mx-auto">
        <price-box />
      </div>

      <e-track-btn
        to="ETrackCurtainCalculation"
        :text="$t('etrack.next')"
      />
    </template>

    <summary-dialog
      :image="entryToShow?.image"
      :route="entryToShow?.route"
      :title="entryToShow?.title"
      :value="entryToShow?.value"
      :values="entryToShow?.values"
      :visible="entryToShow !== null"
      @close="entryToShow = null"
    />
  </default-page-layout>
</template>

<script>
import DefaultPageLayout from '../../components/DefaultPageLayout'
import ETrackBtn from '../../components/ETrackBtn'
import PriceBox from '../../components/PriceBox'
import SummaryDialog from './SummaryDialog.vue'
import SummaryRow from './SummaryRow'

import { profileImageBasePath } from '../Profil.vue'

export default {
  name: 'e-track-summary-overview',

  components: {
    DefaultPageLayout,
    ETrackBtn,
    PriceBox,
    SummaryDialog,
    SummaryRow,
  },

  data () {
    return {
      entryToShow: null,
      additionalGliderAmount: 0,
      profileImageBasePath,
    }
  },

  computed: {
    articleConfig () {
      return this.$store.state.etrack.articleConfig
    },

    assemblySummary () {
      return this.$store.getters['etrack/assemblySummary']
    },

    config () {
      return this.$store.state.etrack.configuration
    },

    batterySuppliesSummary () {
      return this.$store.getters['etrack/batterySuppliesSummary']
    },

    driveCurtainSummary () {
      return this.$store.getters['etrack/driveCurtainSummary']
    },

    isTypeManual () {
      return this.$store.getters['etrack/typeManualActive']
    },

    measureUnit () {
      return this.$store.state.session.catalog.measureUnit
    },

    parts () {
      const { AdditionalParts, PartList } = this.$store.state.etrack.articleConfig
      return [...AdditionalParts, ...PartList]
    },

    previousStep () {
      if (this.isTypeManual) {
        return 'ETrackDecorWidth'
      }

      return this.config.engine !== null
        ? 'ETrackTransmitter'
        : 'ETrackEngine'
    },

    /**
     * There's a video for every curtain-type. An additional startup-video is
     * only available for specific engines (and therefore not in manual mode).
     *
     * @returns {array}
     */
    videos () {
      const curtainVideo = {
        label: this.$t('etrack.videos.assembly'),
        link: this.config.confectionForm.VideoManual,
      }

      return !this.isTypeManual && this.config.engine && this.config.engine.VideoManual
        ? [curtainVideo, {
          label: this.$t('etrack.videos.startup'),
          link: this.config.engine.VideoManual
        }]
        : [curtainVideo]
    },
  },

  mounted () {
    // users mustn't be able to skip the curtain-calculation
    this.$store.commit('etrack/setSummarySeen')
  },

  methods: {
    /**
     * Chosen transmitters formatted to be usable by the summary.
     *
     * @returns {array}
     */
    transmitterSummary () {
      return this.config.transmitters.length === 0
        ? this.$t('etrack.withoutTransmitter')
        : this.config.transmitters.map(({ count, ArticleName, Picture }) => ({
          image: `/images/${Picture}`,
          value: `${count}x ${ArticleName}`,
        }))
    },

    showEntryDetails ({ image, route, title, value, values = [] }) {
      this.entryToShow = { image, route, title, value, values }
    },
  },
}
</script>

<style lang="scss">
  .e-track-summary-overview--wrap {
    .main-content {
      position: relative;
    }

    .video-link {
      padding: 4px 12px;
      border: 2px solid;
      text-decoration: none;

      &:hover {
        .video-label {
          text-decoration: underline;
        }
      }
    }
  }
</style>
