import Vue from 'vue'
import Vuex from 'vuex'

import configStorage from './configStorage'
import etrack from './etrack'
import filters from './filters'
import i18n from './i18n'
import ng from './ng'
import materialShopCart from './materialShopCart'
import rohrbiegung from './rohrbiegung'
import cart from './cart'
import registrations from './registrations'
import favorites from './favorites'
import news from './news'
import session from './session'
import order from './order'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // hier die module aus den Unterordnern einfügen
    configStorage,
    etrack,
    filters,
    i18n,
    ng,
    materialShopCart,
    rohrbiegung,
    cart,
    favorites,
    registrations,
    news,
    session,
    order,
  }
})
